import Alert from 'Components/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { FormItem, H2, Input, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { getInitialAccountSettingsValues } from './getInitialValues';
import { EditBtn, FormField, SpinnerWrapper } from './styles';
import { AccountSettingsAPIResponse } from './types';

const AccountSettings = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [values, setValues] = useState<AccountSettingsAPIResponse>(() => getInitialAccountSettingsValues());
  const [localValues, setLocalValues] = useState<AccountSettingsAPIResponse>(() => getInitialAccountSettingsValues());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [time, setTime] = useState<number>(0);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.activeMerchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchAccountSettings = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/account`;
      const options = await getFetchOptions();

      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then(({ accountName, accountAddress }) => {
          setValues({
            accountName: accountName ?? '',
            accountAddress: accountAddress ?? '',
          });
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchAccountSettings();
  }, [apiBaseUri, merchantId, time]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!apiBaseUri || !merchantId) {
      return;
    }

    const url = `${apiBaseUri}/merchants/${merchantId}/settings/account`;
    const options = await getFetchOptions('POST', JSON.stringify(localValues));
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setShowModal(false);
        setBodyOverflow('auto');
        setTime((prev) => prev + 1);
      })
      .catch((e) => {
        setModalErrorMsg(e.message);
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setLocalValues(values);
    setBodyOverflow('hidden');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <LpBox>
      <Row>
        <div className="col-sm-7">
          <H2 className="mb-0">Account Settings</H2>
        </div>
        <div className="col-sm-5 text-right">
          {errorMsg.length === 0 && <EditBtn onClick={toggleModal}>Edit account settings</EditBtn>}
        </div>
      </Row>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {errorMsg.length === 0 && (
        <LpDetails>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Account name</Label>
            </div>
            <div className="col-sm-8">{values?.accountName || '-'}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Address</Label>
            </div>
            <div className="col-sm-8">{values?.accountAddress || '-'}</div>
          </Row>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit account settings"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          disableConfirmBtn={modalIsLoading}
        >
          {modalIsLoading && (
            <div className="text-center">
              <Spinner color="#0016D1" />
            </div>
          )}
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {!modalIsLoading && modalErrorMsg.length === 0 && (
            <LpDetails className="pt-2 pb-0">
              <FormItem>
                <FormField>
                  <Label>Account name</Label>
                  <Input
                    type="text"
                    name="accountName"
                    value={localValues.accountName ?? ''}
                    required
                    onChange={handleChange}
                  />
                </FormField>
              </FormItem>
              <FormItem>
                <FormField>
                  <Label>Address</Label>
                  <Input
                    type="text"
                    name="accountAddress"
                    value={localValues.accountAddress ?? ''}
                    required
                    onChange={handleChange}
                  />
                </FormField>
              </FormItem>
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default AccountSettings;
