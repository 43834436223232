export const ITEM_ROWS_OBJ = [
  {
    text: 'Order ID',
  },
  {
    text: 'Customer',
  },
  {
    text: 'Amount',
  },
  {
    text: 'Paid',
  },
  {
    text: 'Status',
  },
  {
    text: 'Date',
  },
];
