import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from 'redux/Reducers/';

import App from './App';

const store: Store<ReduxStateType, ActionType> & {
  dispatch: DispatchType;
} = createStore(reducer, applyMiddleware(thunk));

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
