import { logout } from 'APIs/identity';
import { baseUri } from 'Constants/Constants';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as SidebarLogo } from 'assets/svg/sidebar-logo.svg';
import React, { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { sideBarLinks } from './Constants';
import {
  Aside,
  Brand,
  BusinessName,
  Card,
  Collapse,
  DropdownItemBtn,
  ImgText,
  MenuLi,
  NavLinkBtn,
  ProfileDropdownMenu,
  SidebarContainer,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  UserImg,
  Wrapper,
} from './styles';

const Sidebar = () => {
  const [showAccordion, setShowAccordion] = useState<boolean>(false);

  const toggleAccordion = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowAccordion((show) => !show);
  };

  const handleSignOut = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    logout()
      .then(() => {
        window.location.href = `${baseUri}/login`;
      })
      .catch(() => {
        console.error('error to logout');
      });
  };

  return (
    <Wrapper>
      <Aside>
        <SidebarContainer>
          <SidebarHeader>
            <Brand>
              <NavLink to={`${baseUri}/merchants`}>
                <SidebarLogo />
              </NavLink>
            </Brand>
          </SidebarHeader>
          <nav>
            <SidebarMenu>
              {sideBarLinks.map((link, index) => (
                <MenuLi key={index}>
                  <NavLink className="menu-link" to={link.href} activeClassName="selected">
                    {link.label}
                  </NavLink>
                </MenuLi>
              ))}
            </SidebarMenu>
          </nav>
        </SidebarContainer>
        <SidebarFooter showAccordion={showAccordion}>
          <UserImg>
            <ImgText>A</ImgText>
          </UserImg>
          <Card>
            <NavLinkBtn aria-expanded={showAccordion} aria-controls="collapseOne" onClick={toggleAccordion}>
              <div>
                <BusinessName className="business-name">Admin</BusinessName>
              </div>
              <ArrowDownIcon className={`arrow-down ${showAccordion ? 'up' : ''}`} />
            </NavLinkBtn>
            <Collapse showAccordion={showAccordion}>
              <ProfileDropdownMenu>
                <DropdownItemBtn onClick={handleSignOut}>Sign out</DropdownItemBtn>
              </ProfileDropdownMenu>
            </Collapse>
          </Card>
        </SidebarFooter>
      </Aside>
    </Wrapper>
  );
};

export default Sidebar;
