import Alert from 'Components/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { FormItem, H2, H4, Label, LpBox, LpDetails, P, Row } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountryCurrency, divideBy100, timesBy100 } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import EditFee from './EditFee';
import ShowFee from './ShowFee';
import getFees from './getFees';
import { EditBtn, FormControl, FormField, Hr, ModalHr, SpinnerWrapper } from './styles';
import {
  CardTimeType,
  CardType,
  CountryType,
  MerchantFeeAPIResponse,
  PaymentType,
  TaxCountryType,
  defaultCardValue,
} from './types';

const CompanyFees = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [merchantFee, setMerchantFee] = useState<MerchantFeeAPIResponse | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fetchTime, setFetchTime] = useState<number>(0);

  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [calculateTaxOnFee, setCalculateTaxOnFee] = useState<boolean>(true);
  const [taxCountry, setTaxCountry] = useState<TaxCountryType>(TaxCountryType.AU);

  const [payPlanDomPerc, setPayPlanDomPerc] = useState<string>('');
  const [payPlanIntPerc, setPayPlanIntPerc] = useState<string>('');
  const [payCardDomPerc, setPayCardDomPerc] = useState<string>('');
  const [payCardIntPerc, setPayCardIntPerc] = useState<string>('');

  const [payPlanDomFix, setPayPlanDomFix] = useState<string>('');
  const [payPlanIntFix, setPayPlanIntFix] = useState<string>('');
  const [payCardDomFix, setPayCardDomFix] = useState<string>('');
  const [payCardIntFix, setPayCardIntFix] = useState<string>('');

  const [payPlanDomCards, setPayPlanDomCards] = useState<CardTimeType[]>([]);
  const [payPlanIntCards, setPayPlanIntCards] = useState<CardTimeType[]>([]);
  const [payCardDomCards, setPayCardDomCards] = useState<CardTimeType[]>([]);
  const [payCardIntCards, setPayCardIntCards] = useState<CardTimeType[]>([]);

  const { apiBaseUri, activeMerchantId } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    activeMerchantId: state.activeMerchantId,
  }));

  useEffect(() => {
    const fetchMerchantFees = async () => {
      setIsLoading(true);
      const url = `${apiBaseUri}/merchants/${activeMerchantId}/settings/fee`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((res) => {
          setMerchantFee(res);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchants fees');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchMerchantFees();
  }, [activeMerchantId, apiBaseUri, fetchTime]);

  const setCardsWithTime = (feeCards: CardType[] | undefined): CardTimeType[] => {
    if (!merchantFee || !feeCards || feeCards.length === 0) {
      return [];
    }

    const cards = JSON.parse(JSON.stringify(feeCards));
    const time = Date.now();

    return cards.map((c: CardType, index: number) => ({
      ...c,
      fixed: String(divideBy100(c.fixed)),
      perc: String(timesBy100(String(c.perc))),
      time: time + index,
    }));
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    setModalIsLoading(false);
    setModalErrorMsg('');

    setCalculateTaxOnFee(merchantFee?.calculateTaxOnFee ?? true);
    setTaxCountry(merchantFee?.taxCountry ?? TaxCountryType.AU);

    setPayPlanDomPerc(
      merchantFee?.feeStruct ? String(timesBy100(String(merchantFee.feeStruct.split.domestic.perc))) : '',
    );
    setPayPlanIntPerc(
      merchantFee?.feeStruct ? String(timesBy100(String(merchantFee.feeStruct.split.international.perc))) : '',
    );
    setPayCardDomPerc(
      merchantFee?.feeStruct ? String(timesBy100(String(merchantFee.feeStruct.full.domestic.perc))) : '',
    );
    setPayCardIntPerc(
      merchantFee?.feeStruct ? String(timesBy100(String(merchantFee.feeStruct.full.international.perc))) : '',
    );

    setPayPlanDomFix(merchantFee?.feeStruct ? String(divideBy100(merchantFee.feeStruct.split.domestic.fixed)) : '');
    setPayPlanIntFix(
      merchantFee?.feeStruct ? String(divideBy100(merchantFee.feeStruct.split.international.fixed)) : '',
    );
    setPayCardDomFix(merchantFee?.feeStruct ? String(divideBy100(merchantFee.feeStruct.full.domestic.fixed)) : '');
    setPayCardIntFix(merchantFee?.feeStruct ? String(divideBy100(merchantFee.feeStruct.full.international.fixed)) : '');

    setPayPlanDomCards(setCardsWithTime(merchantFee?.feeStruct?.split.domestic.cards));
    setPayPlanIntCards(setCardsWithTime(merchantFee?.feeStruct?.split.international.cards));
    setPayCardDomCards(setCardsWithTime(merchantFee?.feeStruct?.full.domestic.cards));
    setPayCardIntCards(setCardsWithTime(merchantFee?.feeStruct?.full.international.cards));

    setShowModal(true);
    setBodyOverflow('hidden');
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!apiBaseUri || !activeMerchantId) {
      return;
    }

    const url = `${apiBaseUri}/merchants/${activeMerchantId}/settings/fee`;
    const body: MerchantFeeAPIResponse = {
      calculateTaxOnFee,
      taxCountry,
      feeStruct: {
        split: {
          domestic: getFees(payPlanDomPerc, payPlanDomFix, payPlanDomCards),
          international: getFees(payPlanIntPerc, payPlanIntFix, payPlanIntCards),
        },
        full: {
          domestic: getFees(payCardDomPerc, payCardDomFix, payCardDomCards),
          international: getFees(payCardIntPerc, payCardIntFix, payCardIntCards),
        },
      },
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));

    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setFetchTime(Date.now());
        setShowModal(false);
        setBodyOverflow('auto');
      })
      .catch((e) => {
        setModalErrorMsg(e?.message || 'Failed to update merchant fees');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChangePercent = (e: ChangeEvent<HTMLInputElement>, payment: PaymentType, country: CountryType) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        setPayPlanDomPerc(value);
      } else {
        setPayPlanIntPerc(value);
      }
    } else {
      if (country === 'domestic') {
        setPayCardDomPerc(value);
      } else {
        setPayCardIntPerc(value);
      }
    }
  };

  const handleChangeFix = (e: ChangeEvent<HTMLInputElement>, payment: PaymentType, country: CountryType) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        setPayPlanDomFix(value);
      } else {
        setPayPlanIntFix(value);
      }
    } else {
      if (country === 'domestic') {
        setPayCardDomFix(value);
      } else {
        setPayCardIntFix(value);
      }
    }
  };

  const handleDeleteCard = (
    e: MouseEvent<HTMLButtonElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    e.preventDefault();
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = payPlanDomCards.filter((c) => c.time !== time);
        setPayPlanDomCards(cards);
      } else {
        const cards = payPlanIntCards.filter((c) => c.time !== time);
        setPayPlanIntCards(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = payCardDomCards.filter((c) => c.time !== time);
        setPayCardDomCards(cards);
      } else {
        const cards = payCardIntCards.filter((c) => c.time !== time);
        setPayCardIntCards(cards);
      }
    }
  };

  const handleAddCard = (e: MouseEvent<HTMLButtonElement>, p: PaymentType, c: CountryType): void => {
    e.preventDefault();

    const array = [{ ...defaultCardValue, time: Date.now() }];
    if (p === 'payPlan') {
      if (c === 'domestic') {
        setPayPlanDomCards((prev) => prev.concat(array));
      } else {
        setPayPlanIntCards((prev) => prev.concat(array));
      }
    } else {
      if (c === 'domestic') {
        setPayCardDomCards((prev) => prev.concat(array));
      } else {
        setPayCardIntCards((prev) => prev.concat(array));
      }
    }
  };

  const handleChangeCardFunding = (
    e: ChangeEvent<HTMLSelectElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ): void => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayPlanDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayPlanIntCards(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayCardDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayCardIntCards(cards);
      }
    }
  };

  const handleChangeCardBrand = (
    e: ChangeEvent<HTMLSelectElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ): void => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayPlanDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayPlanIntCards(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayCardDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayCardIntCards(cards);
      }
    }
  };

  const handleChangeCardPercent = (
    e: ChangeEvent<HTMLInputElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ): void => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayPlanDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayPlanIntCards(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayCardDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayCardIntCards(cards);
      }
    }
  };

  const handleChangeCardFix = (
    e: ChangeEvent<HTMLInputElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayPlanDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayPlanIntCards(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDomCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayCardDomCards(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardIntCards));
        cards.forEach((c: CardTimeType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayCardIntCards(cards);
      }
    }
  };

  return (
    <LpBox>
      <Row>
        <div className="col-sm-7">
          <H2>Merchant fees</H2>
        </div>
        <div className="col-sm-5 text-right">
          <EditBtn onClick={toggleModal}>Edit merchant fees</EditBtn>
        </div>
      </Row>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {!isLoading && errorMsg.length === 0 && merchantFee?.feeStruct && (
        <LpDetails>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Calculate tax on top of Fee</Label>
            </div>
            <div className="col-sm-8">{merchantFee.calculateTaxOnFee ? 'Yes' : 'No'}</div>
          </Row>
          <Row className="pt-2">
            <div className="col-sm-4">
              <Label>Tax Country</Label>
            </div>
            <div className="col-sm-8">{merchantFee.taxCountry}</div>
          </Row>
          <Hr />
          <H4>Pay plan</H4>
          <div className="pl-2">
            <ShowFee
              label="Domestic"
              fee={merchantFee.feeStruct.split.domestic}
              currency={CountryCurrency[merchantFee.taxCountry]}
            />
            <ShowFee
              label="International"
              fee={merchantFee.feeStruct.split.international}
              currency={CountryCurrency[merchantFee.taxCountry]}
            />
          </div>
          <Hr />
          <H4>Pay cards</H4>
          <div className="pl-2">
            <ShowFee
              label="Domestic"
              fee={merchantFee.feeStruct.full.domestic}
              currency={CountryCurrency[merchantFee.taxCountry]}
            />
            <ShowFee
              label="International"
              fee={merchantFee.feeStruct.full.international}
              currency={CountryCurrency[merchantFee.taxCountry]}
            />
          </div>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Merchant Fees"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          large
        >
          {modalIsLoading && (
            <div className="text-center">
              <Spinner color="#0016D1" />
            </div>
          )}
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {!modalIsLoading && modalErrorMsg.length === 0 && (
            <LpDetails className="pt-2 pb-0">
              <FormItem>
                <FormField>
                  <H2 className="mb-0">Tax</H2>
                  <P className="mt-1 mb-1">Tax rate : 10%</P>
                  <P className="mt-1 mb-1">
                    Calculate tax on top of Fee :
                    <input
                      type="checkbox"
                      checked={calculateTaxOnFee}
                      onChange={(e) => setCalculateTaxOnFee(e.target.checked)}
                    />
                  </P>
                  <Row className="pt-2">
                    <div className="col-sm-6">
                      <Label style={{ lineHeight: '40px' }}>Tax Country</Label>
                    </div>
                    <div className="col-sm-6">
                      <FormControl value={taxCountry} onChange={(e) => setTaxCountry(e.target.value as TaxCountryType)}>
                        <option value="AU">Australia</option>
                        <option value="NZ">New Zealand</option>
                      </FormControl>
                    </div>
                  </Row>
                  <ModalHr />
                  <H2>Pay plan</H2>
                  <EditFee
                    label="Domestic"
                    payment="payPlan"
                    country="domestic"
                    percent={payPlanDomPerc}
                    fixed={payPlanDomFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                    cards={payPlanDomCards}
                    handleDeleteCard={handleDeleteCard}
                    handleAddCard={handleAddCard}
                    handleChangeCardFunding={handleChangeCardFunding}
                    handleChangeCardBrand={handleChangeCardBrand}
                    handleChangeCardPercent={handleChangeCardPercent}
                    handleChangeCardFix={handleChangeCardFix}
                  />
                  <EditFee
                    label="International"
                    payment="payPlan"
                    country="international"
                    percent={payPlanIntPerc}
                    fixed={payPlanIntFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                    cards={payPlanIntCards}
                    handleDeleteCard={handleDeleteCard}
                    handleAddCard={handleAddCard}
                    handleChangeCardFunding={handleChangeCardFunding}
                    handleChangeCardBrand={handleChangeCardBrand}
                    handleChangeCardPercent={handleChangeCardPercent}
                    handleChangeCardFix={handleChangeCardFix}
                  />
                  <ModalHr />
                  <H2>Pay card</H2>
                  <EditFee
                    label="Domestic"
                    payment="payCard"
                    country="domestic"
                    percent={payCardDomPerc}
                    fixed={payCardDomFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                    cards={payCardDomCards}
                    handleDeleteCard={handleDeleteCard}
                    handleAddCard={handleAddCard}
                    handleChangeCardFunding={handleChangeCardFunding}
                    handleChangeCardBrand={handleChangeCardBrand}
                    handleChangeCardPercent={handleChangeCardPercent}
                    handleChangeCardFix={handleChangeCardFix}
                  />
                  <EditFee
                    label="International"
                    payment="payCard"
                    country="international"
                    percent={payCardIntPerc}
                    fixed={payCardIntFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                    cards={payCardIntCards}
                    handleDeleteCard={handleDeleteCard}
                    handleAddCard={handleAddCard}
                    handleChangeCardFunding={handleChangeCardFunding}
                    handleChangeCardBrand={handleChangeCardBrand}
                    handleChangeCardPercent={handleChangeCardPercent}
                    handleChangeCardFix={handleChangeCardFix}
                  />
                </FormField>
              </FormItem>
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default CompanyFees;
