import { Input } from 'Constants/styles';

import styled from '@emotion/styled';

export const Title = styled.div`
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 17px;
  margin-top: 35px;
  padding: 0 60px;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0 60px;
  margin-bottom: 26px;
  color: #6c7488;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 25px;
`;

export const Container = styled.div`
  padding: 0 60px 40px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const LpBoxSignUp = styled.div`
  background-color: #fff;
  padding: 38px 41px;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 4px;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  display: block;
`;

export const HeavyText = styled.span`
  font-weight: 700;
  color: #000;
`;

export const FieldBlob = styled.p`
  color: #6c7488;
  line-height: 16px;
  font-size: 12px;
  margin-top: 0 16px;
`;

export const FormItem = styled.div`
  padding-bottom: 22px;
  font-weight: 300;
  color: #6c7488;
  position: relative;
`;

export const Hr = styled.hr`
  height: 1px;
  margin: 40px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
`;

export const DashHr = styled.hr`
  height: 0;
  margin: 30px 0;
  border: 0;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
`;

export const PercentageField = styled.span`
  position: relative;
  display: block;
  width: 120px;
  &:before {
    right: 1px;
    top: 1px;
    content: '%';
    position: absolute;
    line-height: 38px;
    border-left: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 36px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const FixedField = styled.span`
  position: relative;
  display: block;
  width: 120px;
  &:before {
    left: 1px;
    top: 1px;
    content: '$';
    position: absolute;
    line-height: 38px;
    border-right: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 36px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const LimitField = styled.span`
  position: relative;
  display: block;
  &:before {
    left: 1px;
    top: 1px;
    content: '$';
    position: absolute;
    line-height: 38px;
    border-right: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 36px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const Plus = styled.div`
  margin: 0 15px;
  font-size: 16px;
  line-height: 40px;
`;

export const FieldWrapper = styled.div`
  display: flex;
`;

export const AddCardBtn = styled.button`
  width: 155px;
  height: 40px;
  background-color: var(--lp-colors-medium-blue-600);
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  padding: 9px 23px 7px;
  margin-top: 24px;
  font-size: 13px;
  &:focus {
    outline: none;
  }
`;

export const FormControl = styled.select`
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dce3eb;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
`;

export const CardWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const CardTitle = styled.div`
  position: relative;
`;

export const DeleteCardBtn = styled.button`
  background-color: #fff;
  color: #4b4eae;
  border: none;
  display: inline-block;
  width: auto;
  letter-spacing: 0.35px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ClearBtn = styled.button`
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d3d7;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.56);
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 40px 9px;
  cursor: pointer;
`;

export const SignUpBtn = styled.button`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 45px;
  width: auto;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 40px 9px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const FixedInput = styled(Input)`
  padding-left: 45px;
`;

export const Red = styled.span`
  color: red;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
`;

export const RadioInput = styled.input`
  display: none;
`;

type RadioCircleType = {
  isSelected: boolean;
};

export const RadioCircle = styled.span<RadioCircleType>`
  width: 24px;
  height: 24px;
  border: 1px solid #dce3eb;
  border-radius: 50%;
  margin-right: 5px;

  position: relative;
  &::after {
    content: '';
    width: ${({ isSelected }) => (isSelected ? '12px' : '0')};
    height: ${({ isSelected }) => (isSelected ? '12px' : '0')};
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: var(--lp-colors-medium-blue-600);
    border-radius: 50%;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  padding-top: 8px;
`;

export const SpinnerWrapper = styled.span`
  display: inline-block;
  padding-top: 10px;
  margin-left: 5px;
`;

export const ErrorMsg = styled.small`
  color: red;
  text-align: right;
  display: block;
`;
interface VInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
}

export const VInput = styled(Input)<VInputProps>`
  border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #dce3eb')};
  &:focus {
    border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #505bcc')};
  }
`;

export const MessageBar = styled.div`
  padding: 0 60px;
`;
