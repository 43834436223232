import BackComponent from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox } from 'Constants/styles';
import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import validateEmail from 'utils/validateEmail';

import CustomerDetails from './CustomerDetails';
import List from './List';
import * as s from './styles';
import { CustomerAPIResponseType, CustomerInfoInterface, PublicInfoAPIResponse } from './types';

const Customers = (): ReactElement => {
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);

  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [customerList, setCustomerList] = useState<CustomerInfoInterface[]>([]);
  const [detailsView, setDetailsView] = useState<boolean>(false);
  const [activeCustomer, setActiveCustomer] = useState<CustomerInfoInterface | null>(null);
  const [activeId, setActiveId] = useState<string>('');

  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [isViewingCustomerTransactions, setIsViewingCustomerTransactions] = useState<boolean>(false);

  const history = useHistory();
  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  useEffect(() => {
    document.title = 'Customer - Limepay';
  }, []);

  useEffect(() => {
    if (!activeId) {
      setActiveCustomer(null);
    }

    const customer = customerList.find((c) => c.customerId === activeId);
    setActiveCustomer(customer ?? null);
  }, [activeId, customerList]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setEmail(value);
    setValidEmail(validateEmail(value));
  };

  const handleSearch = async (e?: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e?.preventDefault();
    try {
      setLoading(true);
      const url = `${apiBaseUri}/customers/query?email=${encodeURIComponent(email)}`;
      const options = await getFetchOptions();
      const customerRes = await fetch(url, options);
      if (!customerRes.ok) {
        await handleApiError(customerRes);
      }
      const customerResponse = await customerRes.json();
      if (customerResponse.length === 0) {
        setCustomerList([]);
        setIsSearched(true);
        return;
      }

      const urls = customerResponse.reduce((acc: string[], cur: CustomerAPIResponseType) => {
        acc.push(`${apiBaseUri}/merchants/${cur.merchantId}/settings/public-info`);
        return acc;
      }, []);

      const promises = urls.map(async (u: string) => {
        const uOptions = await getFetchOptions();
        const uRes = await fetch(u, uOptions);
        if (!uRes.ok) {
          await handleApiError(uRes);
        }
        return uRes.json();
      });

      const publicInfo: PublicInfoAPIResponse[] = await Promise.all(promises);
      const list: CustomerInfoInterface[] = [];
      customerResponse.forEach((c: CustomerAPIResponseType, index: number) => {
        const obj = { ...c, merchantInfo: { ...publicInfo[index] } };
        list.push(obj);
      });
      setPageCount(Number(customerRes.headers.get('limepay-page-count')));
      setCustomerList(list);
      setIsSearched(true);
    } catch (e) {
      setErrorMsg(e.message || 'Failed to search customer by email');
    } finally {
      setLoading(false);
    }
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    history.push('/admin-dashboard/customers');
    setDetailsView(false);
  };

  return (
    <>
      {!detailsView && (
        <>
          <HeaderTitle title="Customers" />
          <Content>
            <Message success={false} handleClose={handleCloseMsg} description={errorMsg} />
            <LpBox>
              <s.Label>Search by email</s.Label>
              <s.Flex>
                <s.VInput
                  type="email"
                  isValid={validEmail}
                  required
                  data-testid="email"
                  value={email}
                  onChange={handleChange}
                />
                <s.Btn
                  data-testid="email-search-btn"
                  disabled={email.length === 0 || !validEmail || loading}
                  onClick={handleSearch}
                  hasMarginLeft
                >
                  Search
                  {loading && (
                    <s.BtnSpinner>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.BtnSpinner>
                  )}
                </s.Btn>
              </s.Flex>
            </LpBox>
          </Content>
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && isSearched && (
            <>
              {customerList.length > 0 && (
                <List
                  customerList={customerList}
                  activeId={activeId}
                  setDetailsView={setDetailsView}
                  setActiveId={setActiveId}
                />
              )}
              {customerList.length === 0 && <EmptyList />}

              <Pagination
                pageCount={pageCount}
                activePage={activePage}
                setActivePage={setActivePage}
                pageStartIndex={pageStartIndex}
                setPageStartIndex={setPageStartIndex}
              />
            </>
          )}
        </>
      )}
      {detailsView && activeCustomer && (
        <>
          {!isViewingCustomerTransactions && (
            <>
              <HeaderTitle title="Customer details" />
              <BackComponent text="Back to customers" handleClickBack={handleClickBack} />
            </>
          )}
          <CustomerDetails
            activeCustomer={activeCustomer}
            refreshActiveCustomer={handleSearch}
            setIsViewingCustomerTransactions={setIsViewingCustomerTransactions}
          />
        </>
      )}
    </>
  );
};

export default Customers;
