import Alert from 'Components/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fromCurrency, toCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { getInitialPaymentPlanLimitValues } from './getInitialValues';
import { EditBtn, LimitField, LimitInput, PayLimitAmount, SpinnerWrapper } from './styles';
import { PaymentPlanLimitAPIResponse } from './types';

const PaymentPlanLimit = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [paymentLimits, setPaymentLimits] = useState<PaymentPlanLimitAPIResponse>(() =>
    getInitialPaymentPlanLimitValues(),
  );

  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');

  const [localLimits, setLocalLimits] = useState<PaymentPlanLimitAPIResponse>(() => getInitialPaymentPlanLimitValues());

  const [showModal, setShowModal] = useState<boolean>(false);

  const { apiBaseUri, merchantId } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.activeMerchantId,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchMerchantFees = async () => {
      setIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/payplan`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((res) => {
          setPaymentLimits(res);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchants fees');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchMerchantFees();
  }, [apiBaseUri, merchantId]);

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalErrorMsg('');
    setLocalLimits({
      maxPayPlanAmountUnconfirmedCustomers:
        typeof paymentLimits?.maxPayPlanAmountUnconfirmedCustomers === 'number'
          ? paymentLimits.maxPayPlanAmountUnconfirmedCustomers / 100
          : 0,
      maxPayPlanAmountConfirmedCustomers:
        typeof paymentLimits?.maxPayPlanAmountConfirmedCustomers === 'number'
          ? paymentLimits.maxPayPlanAmountConfirmedCustomers / 100
          : 0,
      minPlanLimit: typeof paymentLimits?.minPlanLimit === 'number' ? paymentLimits.minPlanLimit / 100 : 0,
      currency: paymentLimits.currency,
    });
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setModalIsLoading(true);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/payplan`;
    const body = {
      maxPayPlanAmountUnconfirmedCustomers: fromCurrency(String(localLimits.maxPayPlanAmountUnconfirmedCustomers)),
      maxPayPlanAmountConfirmedCustomers: fromCurrency(String(localLimits.maxPayPlanAmountConfirmedCustomers)),
      minPlanLimit: fromCurrency(String(localLimits.minPlanLimit)),
      currency: localLimits.currency,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setModalErrorMsg('');
        setShowModal(false);
        setBodyOverflow('auto');
        setPaymentLimits(body);
      })
      .catch((e) => {
        setModalErrorMsg(e?.message || 'Failed to update merchants pay plan limits');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalLimits((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <LpBox>
      <Row>
        <div className="col-sm-7">
          <H2 className="mb-0">Payment plan limit</H2>
        </div>
        <div className="col-sm-5 text-right">
          <EditBtn onClick={toggleModal}>Edit plan limits</EditBtn>
        </div>
      </Row>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && !showModal && <Alert message={errorMsg} />}
      {!isLoading && errorMsg.length === 0 && paymentLimits && (
        <LpDetails>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Max pay plan amount unconfirmed customers</Label>
            </div>
            <PayLimitAmount className="col-sm-8">
              {toCurrency(paymentLimits.maxPayPlanAmountUnconfirmedCustomers, paymentLimits.currency)}
            </PayLimitAmount>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Max pay plan amount confirmed customers</Label>
            </div>
            <PayLimitAmount className="col-sm-8">
              {toCurrency(paymentLimits.maxPayPlanAmountConfirmedCustomers, paymentLimits.currency)}
            </PayLimitAmount>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Minimum plan limit</Label>
            </div>
            <PayLimitAmount className="col-sm-8">
              {toCurrency(paymentLimits.minPlanLimit, paymentLimits.currency)}
            </PayLimitAmount>
          </Row>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Payment plan limits"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          disableConfirmBtn={modalIsLoading}
          isLoading={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalErrorMsg.length === 0 && (
            <LpDetails className="pt-2 pb-0">
              <div className="form-item">
                <div className="form-field">
                  <div className="pt-2">
                    <Label>Max pay plan amount unconfirmed customers</Label>
                    <LimitField>
                      <LimitInput
                        type="text"
                        name="maxPayPlanAmountUnconfirmedCustomers"
                        value={localLimits?.maxPayPlanAmountUnconfirmedCustomers ?? 0}
                        required
                        onChange={handleChange}
                      />
                    </LimitField>
                  </div>
                  <div className="pt-2">
                    <Label>Max pay plan amount confirmed customers</Label>
                    <LimitField>
                      <LimitInput
                        type="text"
                        name="maxPayPlanAmountConfirmedCustomers"
                        value={localLimits?.maxPayPlanAmountConfirmedCustomers ?? 0}
                        required
                        onChange={handleChange}
                      />
                    </LimitField>
                  </div>
                  <div className="pt-2">
                    <Label>Minimum plan limit</Label>
                    <LimitField>
                      <LimitInput
                        type="text"
                        name="minPlanLimit"
                        value={localLimits?.minPlanLimit ?? 0}
                        required
                        onChange={handleChange}
                      />
                    </LimitField>
                  </div>
                </div>
              </div>
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default PaymentPlanLimit;
