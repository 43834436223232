import Alert from 'Components/Alert';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Input, P } from 'Constants/styles';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import InactiveMerchants from './InactiveMerchants';
import { SearchWrapper, SpinnerWrapper, Wrapper } from './styles';
import { MerchantAPIResponseType } from './types';

const Merchants = (): ReactElement => {
  const [merchantsList, setMerchantsList] = useState<MerchantAPIResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [businessName, setBusinessName] = useState<string>('');

  const debouncedSearch = useDebounce(businessName);

  const { apiBaseUri } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    document.title = 'Resend Email - Limepay';
  }, []);

  useEffect(() => {
    const queryMerchants = async () => {
      let url = `${apiBaseUri}/merchants/query?sort=-createdAt&page=${activePage}&isActivated=false`;
      if (debouncedSearch.length > 0) {
        url += `&businessName=${debouncedSearch}`;
      }

      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('limepay-page-count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setPageCount(pages);
          setMerchantsList(response);
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchants list');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    queryMerchants();
  }, [activePage, apiBaseUri, debouncedSearch]);

  const handleChangeBusinessName = (e: ChangeEvent<HTMLInputElement>): void => {
    setBusinessName(e.target.value);
    setActivePage(1);
  };

  return (
    <Wrapper>
      <HeaderTitle title="Inactive Merchants" />
      <SearchWrapper>
        <Input type="text" placeholder="Search business name" onChange={handleChangeBusinessName} />
      </SearchWrapper>
      <P style={{ padding: '20px 60px' }}>
        If merchant doesn't fill out the onboarding form within 2 days, the link in the email would be expired. Admin
        would be requested to send another onboarding email to merchant.
      </P>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <>
          <InactiveMerchants merchantsList={merchantsList} />
          <Pagination
            pageCount={pageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
    </Wrapper>
  );
};

export default Merchants;
