import Back from 'Components/BackComponent/BackComponent';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, ReactElement, useState } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import PaymentPlanDetail from './PaymentPlanDetail';
import * as s from './PaymentPlansList.styles';
import { ITEM_ROWS_OBJ } from './paymentPlansItemRowsObj';
import { PaymentPlanAPIResponse } from './types';

type Props = {
  paymentPlans: PaymentPlanAPIResponse[];
  setPaymentPlanDetailsView(d: boolean): void;
};

const PaymentPlansList = ({ paymentPlans, setPaymentPlanDetailsView }: Props): ReactElement => {
  const [activePaymentPlan, setActivePaymentPlan] = useState<PaymentPlanAPIResponse | null>(null);
  const [detailsView, setDetailsView] = useState<boolean>(false);

  const handleViewDetails = (e: MouseEvent<HTMLButtonElement>, paymentPlan: PaymentPlanAPIResponse): void => {
    e.preventDefault();
    setActivePaymentPlan(paymentPlan);
    setDetailsView(true);
    setPaymentPlanDetailsView(true);
  };

  const handleClickOutside = () => {
    if (!detailsView) {
      setActivePaymentPlan(null);
    }
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setActivePaymentPlan(null);
    setDetailsView(false);
    setPaymentPlanDetailsView(false);
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'item-actions-list-link', 'dots-icon']);

  return (
    <>
      {detailsView && (
        <div>
          <HeaderTitle title={`Payment Plan for ${activePaymentPlan?.payPlanId}`} />
          <Back text={'Back to Payment Plans'} handleClickBack={handleClickBack} />
        </div>
      )}
      {!detailsView && (
        <Content className="order-list-transactions payments-list">
          <LpBox>
            <s.Card>
              <s.ItemList>
                <s.Item>
                  <s.ItemRow>
                    {ITEM_ROWS_OBJ.map((row, rowIndex) => (
                      <s.ItemCol key={rowIndex} className={`item-col-header item-col-title ${row.class}`}>
                        <s.SpanText>{row.text}</s.SpanText>
                      </s.ItemCol>
                    ))}
                  </s.ItemRow>
                </s.Item>
                {paymentPlans.map((paymentPlan, orderIndex) => (
                  <s.Item key={orderIndex}>
                    <s.ItemRow>
                      <s.ItemCol className="item-col-lgr">
                        <s.Media>
                          <s.FeatureIcon>
                            <OrderIdIcon />
                          </s.FeatureIcon>
                          <s.InternalOrderId onClick={(e) => handleViewDetails(e, paymentPlan)}>
                            {paymentPlan.payPlanId}
                          </s.InternalOrderId>
                        </s.Media>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-amount">
                        <div className="item text-left mt-1">
                          <s.Price>{toCurrency(paymentPlan.planAmount, paymentPlan.currency)}</s.Price>
                        </div>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-amount">
                        <div className="item text-left mt-1">
                          <s.Price>{toCurrency(paymentPlan.paidAmount, paymentPlan.currency)}</s.Price>
                        </div>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-xsr">
                        <div className="item text-left mt-1">
                          <s.Price>{getDateFormat({ time: paymentPlan.createdAt }).date}</s.Price>
                        </div>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-xsr">
                        <div className="item text-left mt-1">
                          <s.Price>{getDateFormat({ time: paymentPlan.dueAt }).date}</s.Price>
                        </div>
                      </s.ItemCol>
                      <s.ItemCol className="fixed item-col-actions-dropdown">
                        <s.ItemActionsDropdown className="item-actions-dropdown">
                          <s.IconWrapper onClick={() => setActivePaymentPlan(paymentPlan)}>
                            <DotsIcon className="dots-icon" />
                          </s.IconWrapper>
                          <s.ItemActionsBlock
                            className={`item-actions-block ${
                              activePaymentPlan?.payPlanId === paymentPlan.payPlanId ? 'active' : ''
                            }`}
                          >
                            <s.ItemActionsList className="item-actions-list">
                              <s.ItemActionsLi>
                                <s.ItemActionsListBtn
                                  className="item-actions-list-link"
                                  onClick={(e) => handleViewDetails(e, paymentPlan)}
                                >
                                  View details
                                </s.ItemActionsListBtn>
                              </s.ItemActionsLi>
                            </s.ItemActionsList>
                          </s.ItemActionsBlock>
                        </s.ItemActionsDropdown>
                      </s.ItemCol>
                    </s.ItemRow>
                  </s.Item>
                ))}
              </s.ItemList>
            </s.Card>
          </LpBox>
        </Content>
      )}

      {detailsView && <PaymentPlanDetail paymentPlan={activePaymentPlan} />}
    </>
  );
};

export default PaymentPlansList;
