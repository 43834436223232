import { ChangeEvent } from 'react';

import styled from '@emotion/styled';

export const RadioLabel = styled.label`
  background-color: #fff;
  padding: 14px 10px 10px 28px;
  position: relative;
  display: block;
  cursor: pointer;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:hover {
      cursor: pointer;
    }
  }

  .check-mark {
    top: 10px;
    left: 0;
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #b3b8cc;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      background-color: var(--lp-colors-medium-blue-600);
      border-radius: 50%;
      display: none;
    }
  }

  input:checked ~ .check-mark {
    &:after {
      display: block;
    }
  }
`;

export const RadioText = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #555c7c;
`;

type RadioButtonsType = {
  name: string;
  value: Nullable<string | boolean | number>;
  handleChange(e: ChangeEvent<HTMLElement>): void;
};
const RadioButtons = ({ name, value, handleChange }: RadioButtonsType) => (
  <>
    <RadioLabel>
      <input type="radio" name={name} value="true" checked={value === true} onChange={handleChange} />
      <RadioText>true</RadioText>
      <span className="check-mark" />
    </RadioLabel>
    <RadioLabel>
      <input type="radio" name={name} value="false" checked={value === false} onChange={handleChange} />

      <RadioText>false</RadioText>
      <span className="check-mark" />
    </RadioLabel>
  </>
);

export default RadioButtons;
