export const ListHeaders = [
  {
    className: 'item-col-lgr',
    text: 'Business name',
  },
  {
    className: 'custom-col-xsr',
    text: 'Website',
  },
  {
    className: 'custom-col-xsr',
    text: 'Slug',
  },
];

export const TransferListHeaders = [
  {
    className: 'item-col-order',
    text: 'Amount',
  },
  {
    className: 'item-col-xl',
    text: 'Date',
  },
  {
    className: 'item-col-xl',
    text: 'Download CSV',
  },
];

export const TransactionListHeaders = [
  {
    text: 'Order Id',
  },
  {
    text: 'Amount',
  },
  {
    text: 'Type',
  },
  {
    text: 'Fee',
  },
  {
    text: 'Date',
  },
];
