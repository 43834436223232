export const ITEM_ROWS_OBJ = [
  {
    class: '',
    text: 'Merchant',
    noOverflow: false,
  },
  {
    class: 'item-col-lgr',
    text: 'Type',
    noOverflow: true,
  },
  {
    class: 'item-col-amount',
    text: 'Amount',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Date',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Status',
    noOverflow: true,
  },
];
