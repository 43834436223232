import Back from 'Components/BackComponent/BackComponent';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { Content, H2, Label, LpBox, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';
import { fromCurrency, toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import validateAmount from 'utils/validateAmount';

import MerchantProfile from './MerchantProfile';
import * as s from './TransactionDetails.styles';
import getDebtISOTime from './getDebtISOTime';
import { PlanDebtCollectionState, TransactionAPIResponseType } from './types';

type TransactionDetailsType = {
  apiBaseUri: string;
  activeOrder: TransactionAPIResponseType | null;
  handleClickBackOnDetails(): void;
  merchantId: string | string[];
  merchantName: string | string[];
  setNow(n: number): void;
};

const TransactionDetails = ({
  apiBaseUri,
  activeOrder,
  handleClickBackOnDetails,
  merchantId,
  merchantName,
  setNow,
}: TransactionDetailsType) => {
  // waive states
  const [showWaiveModal, setShowWaiveModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [validAmount, setValidAmount] = useState<boolean>(true);
  const [waiveSuccessful, setWaiveSuccessful] = useState<boolean>(false);

  //common states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  //debt states
  const [debt, setDebt] = useState<null | PlanDebtCollectionState>(null);
  const [showDebtModal, setShowDebtModal] = useState<Boolean>(false);
  const [updateDebtSuccessful, setUpdateDebtSuccessful] = useState<boolean>(false);
  const [debtDate, setDebtDate] = useState<string>('');
  const [debtTime, setDebtTime] = useState<string>('');
  const [validDate, setValidDate] = useState<boolean>(true);
  const [debtNow, setDebtNow] = useState<number>(0);
  const [loadingDebt, setLoadingDebt] = useState<boolean>(false);

  useEffect(() => {
    if (!activeOrder || !activeOrder.transactionPayType.payPlanId || !merchantId) {
      return;
    }
    const fetchDebtState = async () => {
      try {
        setLoadingDebt(true);
        const url = `${apiBaseUri}/admin/payments/plan/${merchantId}/${activeOrder.transactionPayType.payPlanId}/debt-collection-state`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        // 404 means the plan is paid
        if (res.status === 404) {
          return;
        }
        if (!res.ok) {
          await handleApiError(res);
        }
        setDebt(await res.json());
      } catch (e) {
        setErrorMsg(e.message || 'Failed to fetch debt state schedule.');
      } finally {
        setLoadingDebt(false);
      }
    };
    fetchDebtState();
  }, [activeOrder, apiBaseUri, merchantId, debtNow]);

  if (!activeOrder) {
    return <></>;
  }

  const toggleWaiveModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowWaiveModal(true);
    setBodyOverflow('hidden');
    setValidAmount(true);
    setIsLoading(false);
    setAmount('');
    setErrorMsg('');
    setWaiveSuccessful(false);
  };

  const toggleDebtStateModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowDebtModal(true);
    setBodyOverflow('hidden');
    setIsLoading(false);
    setErrorMsg('');
    setUpdateDebtSuccessful(false);
    setDebtDate('');
    setDebtTime('');
    setValidDate(true);
  };

  const handleCancelWaive = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowWaiveModal(false);
    setBodyOverflow('auto');
  };

  const handleCancelDebt = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowDebtModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmitWaive = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const url = `${apiBaseUri}/admin/payments/plan/${activeOrder.transactionPayType?.payPlanId}/waive-late-fee`;
      const body = {
        waiverAmount: fromCurrency(amount),
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      window.scrollTo({ top: 0 });
      setBodyOverflow('auto');
      setShowWaiveModal(false);
      setWaiveSuccessful(true);
      setNow(Date.now());
    } catch (e) {
      setErrorMsg(e.message || 'Failed to waive late fee');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitDebt = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e.preventDefault();
      if (!debtTime || !debtDate) {
        setValidDate(false);
        return;
      }
      setIsLoading(true);
      const url = `${apiBaseUri}/admin/payments/plan/${merchantId}/${activeOrder.transactionPayType.payPlanId}/debt-collection-state`;
      const debtCollectionScheduledAt = getDebtISOTime(debtDate, debtTime);
      const options = await getFetchOptions('POST', JSON.stringify({ debtCollectionScheduledAt }));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setValidDate(true);
      setShowDebtModal(false);

      window.scrollTo({ top: 0 });
      setBodyOverflow('auto');
      setShowDebtModal(false);
      setUpdateDebtSuccessful(true);
      setDebtNow(Date.now());
    } catch (e) {
      setErrorMsg(e.message || 'Failed to update debt schedule state');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setAmount(value);
    setValidAmount(validateAmount(value));
  };

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleCloseWaiveSuccessMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setWaiveSuccessful(false);
  };

  const handleCloseDebtSuccessMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setUpdateDebtSuccessful(false);
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    handleClickBackOnDetails();
  };

  const handleChangeDebtDate = (e: ChangeEvent<HTMLInputElement>): void => {
    setDebtDate(e.target.value);
  };

  const handleChangeDebtTime = (e: ChangeEvent<HTMLInputElement>): void => {
    setDebtTime(e.target.value);
  };

  return (
    <div>
      <HeaderTitle title="Transaction details" />
      <Back text="Back to transactions" handleClickBack={handleClickBack} />
      {waiveSuccessful && (
        <s.MessageBar>
          <Message description="Waive late fees successfully" handleClose={handleCloseWaiveSuccessMsg} />
        </s.MessageBar>
      )}
      {updateDebtSuccessful && (
        <s.MessageBar>
          <Message description="Update deb collection state successfully" handleClose={handleCloseDebtSuccessMsg} />
        </s.MessageBar>
      )}
      <MerchantProfile merchantId={merchantId} name={merchantName} />
      <Content>
        <LpBox>
          <H2>Transaction details</H2>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Transaction Type</Label>
            </div>
            <div className="col-9">{!!activeOrder.transactionPayType.payCardId ? 'Full payment' : 'Pay plan'}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>{!!activeOrder.transactionPayType.payCardId ? 'PayCard ID' : 'PayPlan ID'}</Label>
            </div>
            <div className="col-9">
              {activeOrder.transactionPayType.payCardId ?? activeOrder.transactionPayType.payPlanId}
            </div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Amount</Label>
            </div>
            <div className="col-9">{toCurrency(activeOrder.transactionAmount, activeOrder.currency)}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Currency</Label>
            </div>
            <div className="col-9">{activeOrder.currency}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Refunded amount</Label>
            </div>
            <div className="col-9">{toCurrency(activeOrder.refundedAmount, activeOrder.currency)}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Transaction status</Label>
            </div>
            <div className="col-9">{activeOrder.transactionStatus}</div>
          </Row>
          {!!activeOrder.transactionPayType.payPlanId && (
            <Row className="mt-4">
              <div className="col-3">
                <Label>Pay plan status</Label>
              </div>
              <div className="col-9">{activeOrder.payPlanState}</div>
            </Row>
          )}
          {activeOrder.payPlanScheduledInstalments.length > 0 && (
            <Row className="mt-4">
              <div className="col-3">
                <Label>Pay plan schedule</Label>
              </div>
              <div className="col-9">
                {activeOrder.payPlanScheduledInstalments.map((instalment, index) => (
                  <s.Flex key={index}>
                    <div>{toCurrency(instalment.amount, activeOrder.currency)}</div>
                    <div className="ml-2">{getDateFormat({ time: instalment.dueAt, showDaySuffix: false }).date}</div>
                  </s.Flex>
                ))}
              </div>
            </Row>
          )}
          <Row className="mt-4">
            <div className="col-3">
              <Label>Date</Label>
            </div>
            <div className="col-9">{getDateFormat({ time: activeOrder.createdAt, showDaySuffix: false }).date}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Card Brand</Label>
            </div>
            <div className="col-9">{activeOrder.paymentSourceBrand}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Last 4 digits</Label>
            </div>
            <div className="col-9">{activeOrder.paymentSourceLast4}</div>
          </Row>
          {!!activeOrder.transactionPayType.payPlanId && (
            <>
              <Row className="mt-4">
                <div className="col-3">
                  <Label>Late fees accrued</Label>
                </div>
                <div className="col-9">{toCurrency(activeOrder.payPlanLateFeesAccrued, activeOrder.currency)}</div>
              </Row>
              <Row className="mt-4">
                <div className="col-3">
                  <Label>Late fees paid</Label>
                </div>
                <div className="col-9">{toCurrency(activeOrder.payPlanLateFeesPaid, activeOrder.currency)}</div>
              </Row>
              <Row className="mt-4">
                <div className="col-12">
                  <s.WaiveBtn onClick={toggleWaiveModal}>Waive late fees</s.WaiveBtn>
                </div>
              </Row>
            </>
          )}
        </LpBox>
        {loadingDebt && (
          <s.DebtSpinner>
            <Spinner color="#0016D1" />
          </s.DebtSpinner>
        )}
        {debt && !loadingDebt && (
          <LpBox>
            <H2>Debt collection schedules</H2>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Plan Id</Label>
              </div>
              <div className="col-9">{debt._id}</div>
            </Row>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Debt collection state</Label>
              </div>
              <div className="col-9">{debt.debtCollectionState}</div>
            </Row>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Created at</Label>
              </div>
              <div className="col-9">
                {
                  getDateFormat({
                    time: debt.createdAt,
                    showDaySuffix: false,
                    showSeconds: true,
                    showFullYear: true,
                    showComma: false,
                  }).formatted
                }
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Updated at</Label>
              </div>
              <div className="col-9">
                {
                  getDateFormat({
                    time: debt.updatedAt,
                    showDaySuffix: false,
                    showSeconds: true,
                    showFullYear: true,
                    showComma: false,
                  }).formatted
                }
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Debt collection last attempted at</Label>
              </div>
              <div className="col-9">
                {debt.debtCollectionLastAttemptedAt
                  ? getDateFormat({
                      time: debt.debtCollectionLastAttemptedAt,
                      showDaySuffix: false,
                      showSeconds: true,
                      showFullYear: true,
                      showComma: false,
                    }).formatted
                  : 'N.A.'}
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-3">
                <Label>Debt collection scheduled at</Label>
              </div>
              <div className="col-9">
                {debt.debtCollectionScheduledAt
                  ? getDateFormat({
                      time: debt.debtCollectionScheduledAt,
                      showDaySuffix: false,
                      showSeconds: true,
                      showFullYear: true,
                      showComma: false,
                    }).formatted
                  : 'N.A.'}
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-12">
                <s.WaiveBtn onClick={toggleDebtStateModal}>Update debt collection state</s.WaiveBtn>
              </div>
            </Row>
          </LpBox>
        )}
        <LpBox>
          <H2>Order</H2>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Merchant Order Id</Label>
            </div>
            <div className="col-9">{activeOrder.merchantOrderId}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Internal Order Id</Label>
            </div>
            <div className="col-9">{activeOrder.merchantOrderInternalOrderId}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Order Type</Label>
            </div>
            <div className="col-9">{activeOrder.merchantOrderType}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Full name</Label>
            </div>
            <div className="col-9">{activeOrder.merchantOrderBilling?.name || activeOrder.customerFullName || '-'}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Customer id</Label>
            </div>
            <div className="col-9">{activeOrder.customerId}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Email</Label>
            </div>
            <div className="col-9">{activeOrder.customerEmail}</div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Phone number</Label>
            </div>
            <div className="col-9">
              {activeOrder.merchantOrderBilling?.phone || activeOrder.customerPhoneNumber || '-'}
            </div>
          </Row>
          <Row className="mt-4">
            <div className="col-3">
              <Label>Items</Label>
            </div>
            <div className="col-9">
              {activeOrder.merchantOrderItems.map((item, index) => (
                <s.DescriptionWrapper key={index} className="mb-2">
                  <s.Description>{item.description}</s.Description>
                  <div className="ml-4">{item.quantity}</div>
                  <div className="ml-4">{toCurrency(item.amount, item.currency)}</div>
                </s.DescriptionWrapper>
              ))}
            </div>
          </Row>
        </LpBox>
      </Content>
      {showWaiveModal && (
        <Modal
          title="Waive late fees"
          cancelBtnText="Cancel"
          confirmBtnText="Confirm and waive"
          isLoading={isLoading}
          disableConfirmBtn={isLoading || !validAmount || amount.length === 0}
          handleCancel={handleCancelWaive}
          handleSubmit={handleSubmitWaive}
        >
          <s.ModalBody>
            <Message description={errorMsg} handleClose={handleCloseErrorMsg} success={false} />
            <Label>Amount</Label>
            <s.FixedField>
              <s.FixedInput value={amount} onChange={handleChangeAmount} />
            </s.FixedField>
            <s.ErrorMsg>{!validAmount && 'Please enter a valid amount'}</s.ErrorMsg>
          </s.ModalBody>
        </Modal>
      )}
      {showDebtModal && (
        <Modal
          title="Update debt collection schedule"
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          isLoading={isLoading}
          disableConfirmBtn={isLoading}
          handleCancel={handleCancelDebt}
          handleSubmit={handleSubmitDebt}
        >
          <s.ModalBody>
            <Message description={errorMsg} handleClose={handleCloseErrorMsg} success={false} />
            <Label>Debt collection scheduled at</Label>
            <div>
              <input type="date" value={debtDate} onChange={handleChangeDebtDate} />
              <input className="ml-1" type="time" value={debtTime} onChange={handleChangeDebtTime} />
            </div>

            <s.ErrorMsg>{!validDate && 'Please enter a valid date and time'}</s.ErrorMsg>
          </s.ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default TransactionDetails;
