import Back from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { Content } from 'Constants/styles';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { SpinnerWrapper } from '../Transactions/Transactions.styles';
import PaymentPlansList from './PaymentPlansList';
import { PaymentPlanAPIResponse } from './types';

type CustomerPaymentPlansType = {
  setShowCustomerPaymentPlans(show: boolean): void;
  customerId: string;
};

const CustomerPaymentPlans = ({ setShowCustomerPaymentPlans, customerId }: CustomerPaymentPlansType): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [playmentPlans, setplaymentPlans] = useState<PaymentPlanAPIResponse[]>([]);
  const [paymentPlanDetailsView, setPaymentPlanDetailsView] = useState<boolean>(false);

  const { apiBaseUri } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri || !customerId) {
      return;
    }

    const fetchCustomerPaymentPlans = async () => {
      let url = `${apiBaseUri}/admin/reports/customer-payplans/${customerId}`;
      setIsLoading(true);
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            response: await res.json(),
          };
        })
        .then(({ response }) => {
          setplaymentPlans(response);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchCustomerPaymentPlans();
  }, [apiBaseUri, customerId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowCustomerPaymentPlans(false);
  };

  return (
    <>
      {!paymentPlanDetailsView && (
        <div>
          <HeaderTitle title={`Payment Plans for ${customerId}`} />
          <Back text={'Back to customer'} handleClickBack={handleClickBack} />
        </div>
      )}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && (
        <Content>
          <Message success={false} handleClose={handleCloseMsg} description={errorMsg} />
        </Content>
      )}
      {!isLoading && (
        <>
          {playmentPlans.length > 0 && (
            <PaymentPlansList setPaymentPlanDetailsView={setPaymentPlanDetailsView} paymentPlans={playmentPlans} />
          )}
          {playmentPlans.length === 0 && <EmptyList />}
        </>
      )}
    </>
  );
};

export default CustomerPaymentPlans;
