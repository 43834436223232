import Message from 'Components/Message';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { Content, H2, Input, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import validateEmail from 'utils/validateEmail';

import { PhoneNoWrapper } from '../CompanyDetail/styles';
import Transactions from '../Transactions/Transactions';
import * as s from './CustomerDetails.styles';
import CustomerPaymentPlans from './CustomerPaymentPlans';
import MerchantProfile from './MerchantProfile';
import { InvalidText, VInput } from './styles';
import { CustomerDetailsType, KYCApiResponse } from './types';

interface ManualKYCValues {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  dob: string;
  licenseNumber: string;
  licenseState: string;
  documentNumber: string;
  passportNumber: string;
  passportCountry: string;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface ManualKycPostBody {
  emailAddress: string;
  phoneNumber: string;
  customerIdentification: {
    name: {
      givenName: string;
      middleName: string;
      familyName: string;
    };
    dateOfBirth: string;
    address: {
      address: string;
      componentised: {
        unitNumber: string;
        streetNumber: string;
        streetName: string;
        streetType: string;
        suburb: string;
        city: string;
        postcode: string;
        state: string;
        country: string;
      };
    };
    driversLicence?: {
      number: string;
      state: string;
      documentNumber: string;
    };
    passport?: {
      number: string;
      country: string;
    };
  };
}

const partialKeys = function <T>(keys: Array<keyof T>, obj: T) {
  return keys.some((key) => Boolean(obj[key])) && !keys.every((key) => Boolean(obj[key]));
};

const CustomerDetails = ({
  activeCustomer,
  refreshActiveCustomer,
  setIsViewingCustomerTransactions,
}: CustomerDetailsType) => {
  // email states
  const [emailMsg, setEmailMsg] = useState<string>('');
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [emailBtnLoading, setEmailBtnLoading] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [email, setEmail] = useState<string>(activeCustomer.email ?? '');
  const [emailInModal, setEmailInModal] = useState<string>('');

  // phone states
  const [phoneMsg, setPhoneMsg] = useState<string>('');
  const [phoneSuccess, setPhoneSuccess] = useState<boolean>(false);
  const [phoneBtnLoading, setPhoneBtnLoading] = useState<boolean>(false);
  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);
  const [validPhone, setValidPhone] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>(activeCustomer.phoneNumber ?? '');
  const [phoneInModal, setPhoneInModal] = useState<string>('');
  const [showRemovePhoneModal, setShowRemovePhoneModal] = useState<boolean>(false);
  const [removePhoneErrorMsg, setRemovePhoneErrorMsg] = useState<string>('');
  const [removePhoneLoading, setRemovePhoneLoading] = useState<boolean>(false);
  const [removePhoneSuccessMsg, setRemovePhoneSuccessMsg] = useState<string>('');

  // kyc states
  const [loading, setLoading] = useState<boolean>(false);
  const [kycBtnLoading, setKycBtnLoading] = useState<boolean>(false);
  const [kycCounts, setKycCounts] = useState<KYCApiResponse | null>(null);
  const [kycMsg, setKycMsg] = useState<string>('');
  const [kycSuccess, setKycSuccess] = useState<boolean>(false);
  const [manualKYCModalOpen, setManualKYCModalOpen] = useState<boolean>(false);
  const [now, setNow] = useState<number>(0);
  const kycInitialValues = {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNo: '',
    dob: '',
    licenseNumber: '',
    documentNumber: '',
    licenseState: '',
    passportNumber: '',
    passportCountry: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'Australia',
  };
  const [manualKYCInitialValues, setManualKYCInitialValues] = useState<ManualKYCValues>({ ...kycInitialValues });
  const [validPhoneNo, setValidPhoneNo] = useState<boolean>(true);
  const [manualKYCSubmitLoader, setManualKYCSubmitLoader] = useState<boolean>(false);
  const [manualKYCErrorMsg, setManualKYCErrorMsg] = useState<string>('');
  const [kycSuccessMessage, setKycSuccessMessage] = useState<string>('');

  // transactions
  const [showTransactions, setShowTransactions] = useState<boolean>(false);

  // payment plans
  const [showCustomerPlans, setShowCustomerPaymentPlans] = useState<boolean>(false);

  const history = useHistory();
  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  useEffect(() => {
    setPhone(activeCustomer.phoneNumber ?? '');
    setEmail(activeCustomer.email ?? '');
  }, [activeCustomer]);

  useEffect(() => {
    if (!apiBaseUri || !activeCustomer || !activeCustomer.customerId) {
      return;
    }
    const fetchKycCounts = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/admin/customers/${activeCustomer.customerId}/kyc`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        setKycCounts(await res.json());
      } catch (e) {
        setKycMsg(e.message || 'Failed to fetch kyc counts');
        setKycSuccess(false);
      } finally {
        setLoading(false);
      }
    };
    fetchKycCounts();
  }, [apiBaseUri, activeCustomer, now]);

  const handleReset = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setLoading(true);
      setKycBtnLoading(true);
      const url = `${apiBaseUri}/admin/customers/${activeCustomer.customerId}/kyc/reset`;
      const options = await getFetchOptions('POST');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setKycSuccess(true);
      setKycMsg('Reset KYC counts successfully!');
      setNow(Date.now());
    } catch (e) {
      setLoading(false);
      setKycSuccess(false);
      setKycMsg(e.message || 'Failed to reset KYC counts');
    } finally {
      setKycBtnLoading(false);
    }
  };

  const handleCloseKycMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setKycMsg('');
  };

  const handleClosePhoneMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setPhoneMsg('');
  };

  const handleCloseEmailMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setEmailMsg('');
  };

  const handleCancelPhone = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowPhoneModal(false);
  };

  const handleCancelEmail = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowEmailModal(false);
  };

  const handleSubmitPhone = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      setPhoneBtnLoading(true);
      const url = `${apiBaseUri}/admin/customers/${activeCustomer.customerId}/phone`;
      const body = {
        phoneNumber: phoneInModal,
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setPhoneMsg('Phone number updated successfully!');
      setPhoneSuccess(true);
      setShowPhoneModal(false);
      setBodyOverflow('auto');
      setPhone(phoneInModal);
      refreshActiveCustomer();
    } catch (e) {
      setPhoneMsg(e.message || 'Failed to update phone number.');
      setPhoneSuccess(false);
    } finally {
      setPhoneBtnLoading(false);
    }
  };

  const handleSubmitEmail = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      setEmailBtnLoading(true);
      const url = `${apiBaseUri}/admin/customers/${activeCustomer.customerId}/email`;
      const body = {
        emailAddress: emailInModal,
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setEmailMsg('Email updated successfully!');
      setEmailSuccess(true);
      setShowEmailModal(false);
      setBodyOverflow('auto');
      setEmail(emailInModal);
    } catch (e) {
      setEmailMsg(e.message || 'Failed to update email.');
      setEmailSuccess(false);
    } finally {
      setEmailBtnLoading(false);
    }
  };

  const handleEditPhone = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setValidPhone(true);
    setPhoneBtnLoading(false);
    setShowPhoneModal(true);
    setPhoneMsg('');
    setPhoneInModal(phone);
    setBodyOverflow('hidden');
  };

  const handleEditEmail = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setValidEmail(true);
    setEmailBtnLoading(false);
    setShowEmailModal(true);
    setEmailMsg('');
    setEmailInModal(email);
    setBodyOverflow('hidden');
  };

  const handleChangePhone = (value: string = ''): void => {
    const isValid = isPossiblePhoneNumber(value);
    setValidPhone(isValid);
    setPhoneInModal(value);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setEmailInModal(value);
    setValidEmail(validateEmail(value));
  };

  const handleViewTransactions = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const params = `customerId=${activeCustomer.customerId}&merchantId=${
      activeCustomer.merchantId
    }&merchantName=${encodeURIComponent(activeCustomer.merchantInfo.businessName)}`;
    history.push(`/admin-dashboard/customers?${params}`);
    setIsViewingCustomerTransactions(true);
    setShowTransactions(true);
  };

  const handleViewPaymentPlans = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsViewingCustomerTransactions(true);
    setShowCustomerPaymentPlans(true);
  };

  const handleManualKYCModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setManualKYCModalOpen(!manualKYCModalOpen);
    setManualKYCInitialValues({ ...kycInitialValues });
    setValidPhoneNo(true);
    setManualKYCErrorMsg('');
  };

  const handleKYCChangeValue = (key: keyof ManualKYCValues, value: string): void => {
    const values = { ...manualKYCInitialValues };
    values[key] = value;

    if (key === 'phoneNo') {
      const phoneNo = parsePhoneNumber(value ?? '');
      if (!phoneNo || !phoneNo.country) {
        setValidPhoneNo(false);
      } else {
        setValidPhoneNo(true);
      }
    }

    setManualKYCInitialValues(values);
  };

  const kycSubmitHandler = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      if (!apiBaseUri) {
        return;
      }
      setManualKYCSubmitLoader(true);
      setManualKYCErrorMsg('');

      const phoneNo = parsePhoneNumber(manualKYCInitialValues.phoneNo ?? '');
      if (!phoneNo || !phoneNo.country) {
        setValidPhoneNo(false);
        return;
      }

      if (partialKeys(['licenseNumber', 'licenseState', 'documentNumber'], manualKYCInitialValues)) {
        setManualKYCErrorMsg('You must enter license number, document number and state for driving license');
        setManualKYCSubmitLoader(false);
        return;
      }

      if (
        (manualKYCInitialValues.passportCountry && !manualKYCInitialValues.passportNumber) ||
        (!manualKYCInitialValues.passportCountry && manualKYCInitialValues.passportNumber)
      ) {
        setManualKYCErrorMsg('You must enter both number and country for passport');
        setManualKYCSubmitLoader(false);
        return;
      }

      let address = '';

      if (manualKYCInitialValues.unitNumber && manualKYCInitialValues.unitNumber.trim()) {
        address = `${manualKYCInitialValues.unitNumber.trim()}, `;
      }

      address = `${address}${manualKYCInitialValues.streetNumber.trim()}, ${manualKYCInitialValues.streetName.trim()}`;

      if (manualKYCInitialValues.streetType && manualKYCInitialValues.streetType.trim()) {
        address = `${address}, ${manualKYCInitialValues.streetType.trim()}`;
      }

      address = `${address}, ${manualKYCInitialValues.suburb.trim()}`;

      if (manualKYCInitialValues.city && manualKYCInitialValues.city.trim()) {
        address = `${address}, ${manualKYCInitialValues.city.trim()}`;
      }

      if (manualKYCInitialValues.state && manualKYCInitialValues.state.trim()) {
        address = `${address}, ${manualKYCInitialValues.state.trim()}`;
      }

      if (manualKYCInitialValues.postalCode && manualKYCInitialValues.postalCode.trim()) {
        address = `${address}, ${manualKYCInitialValues.postalCode.trim()}`;
      }

      address = `${address}, ${manualKYCInitialValues.country.trim()}`;

      const url = `${apiBaseUri}/admin/customers/kyc/manual`;
      const body: ManualKycPostBody = {
        emailAddress: manualKYCInitialValues.email,
        phoneNumber: manualKYCInitialValues.phoneNo,
        customerIdentification: {
          name: {
            givenName: manualKYCInitialValues.firstName,
            middleName: manualKYCInitialValues.middleName,
            familyName: manualKYCInitialValues.lastName,
          },
          dateOfBirth: manualKYCInitialValues.dob,
          address: {
            address,
            componentised: {
              unitNumber: manualKYCInitialValues.unitNumber,
              streetNumber: manualKYCInitialValues.streetNumber,
              streetName: manualKYCInitialValues.streetName,
              streetType: manualKYCInitialValues.streetType,
              suburb: manualKYCInitialValues.suburb,
              city: manualKYCInitialValues.city,
              postcode: manualKYCInitialValues.postalCode,
              state: manualKYCInitialValues.state,
              country: manualKYCInitialValues.country,
            },
          },
        },
      };

      if (manualKYCInitialValues.passportNumber && manualKYCInitialValues.passportCountry) {
        body.customerIdentification.passport = {
          number: manualKYCInitialValues.passportNumber,
          country: manualKYCInitialValues.passportCountry,
        };
      }

      if (
        manualKYCInitialValues.licenseNumber &&
        manualKYCInitialValues.licenseState &&
        manualKYCInitialValues.documentNumber
      ) {
        body.customerIdentification.driversLicence = {
          number: manualKYCInitialValues.licenseNumber,
          state: manualKYCInitialValues.licenseState,
          documentNumber: manualKYCInitialValues.documentNumber,
        };
      }

      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setManualKYCModalOpen(!manualKYCModalOpen);
      setManualKYCInitialValues({ ...kycInitialValues });
      setValidPhoneNo(true);
      setManualKYCErrorMsg('');
      setKycSuccessMessage('Manual KYC completed successfully.');
    } catch (e) {
      setManualKYCErrorMsg(e.message || 'Failed to complete Manual KYC.');
    } finally {
      setManualKYCSubmitLoader(false);
    }
  };

  const handleCloseManualKYCMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setManualKYCErrorMsg('');
  };

  const handleCloseKycSuccessMessage = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setKycSuccessMessage('');
  };

  const handleShowRemovePhone = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('hidden');
    setShowRemovePhoneModal(true);
    setRemovePhoneLoading(false);
  };

  const handleCancelRemovePhone = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowRemovePhoneModal(false);
    setRemovePhoneLoading(false);
  };

  const handleCloseRemovePhoneMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setRemovePhoneSuccessMsg('');
  };

  const handleCloseRemovePhoneErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setRemovePhoneErrorMsg('');
  };

  const handleSubmitRemovePhone = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setRemovePhoneLoading(true);
      const url = `${apiBaseUri}/admin/customers/${activeCustomer.customerId}/phone`;
      const options = await getFetchOptions('DELETE');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setRemovePhoneSuccessMsg('Delete phone number successfully!');
      setShowRemovePhoneModal(false);
      refreshActiveCustomer();
      setBodyOverflow('auto');
    } catch (e) {
      setRemovePhoneErrorMsg(e.message || 'Failed to delete phone number');
    } finally {
      setRemovePhoneLoading(false);
    }
  };

  if (showTransactions) {
    return (
      <Transactions
        showingCustomerId={activeCustomer.customerId}
        setShowTransactions={() => {
          setShowTransactions(false);
          setIsViewingCustomerTransactions(false);
        }}
      />
    );
  }

  if (showCustomerPlans) {
    return (
      <CustomerPaymentPlans
        customerId={activeCustomer.customerId}
        setShowCustomerPaymentPlans={() => {
          setShowCustomerPaymentPlans(false);
          setIsViewingCustomerTransactions(false);
        }}
      />
    );
  }

  return (
    <div>
      <MerchantProfile merchantId={activeCustomer.merchantId} name={activeCustomer.merchantInfo.businessName} />
      <Content>
        {phoneSuccess && <Message success description={phoneMsg} handleClose={handleClosePhoneMsg} />}
        {emailSuccess && <Message success description={emailMsg} handleClose={handleCloseEmailMsg} />}
        {!!removePhoneSuccessMsg && (
          <Message success description={removePhoneSuccessMsg} handleClose={handleCloseRemovePhoneMsg} />
        )}
        <LpBox>
          <H2>Customer details</H2>
          <Row className="mt-4">
            <Label className="col-4">
              <s.Strong>Customer ID</s.Strong>
            </Label>
            <div className="col-8">
              <s.Strong>{activeCustomer.customerId}</s.Strong>
            </div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Stripe customer ID</Label>
            <div className="col-8">{activeCustomer.stripeCustomerId}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Customer account ID</Label>
            <div className="col-8">{activeCustomer.customerAccountId}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">First name</Label>
            <div className="col-8">{activeCustomer.firstName || '-'}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Last name</Label>
            <div className="col-8">{activeCustomer.lastName || '-'}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Is email verified</Label>
            <div className="col-8">{String(activeCustomer.isEmailVerified)}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Is verified</Label>
            <div className="col-8">{String(activeCustomer.isVerified)}</div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Phone number</Label>
            <div className="col-8">
              {phone || 'Not set'}
              <s.EditLink onClick={handleEditPhone}>Edit</s.EditLink>
            </div>
          </Row>
          <Row className="mt-4">
            <Label className="col-4">Email</Label>
            <div className="col-8">
              {email || 'Not set'}
              <s.EditLink onClick={handleEditEmail}>Edit</s.EditLink>
            </div>
          </Row>
          <Row className="mt-4">
            <div className="col-12">
              <s.InlineBtn data-testid="view-transactions" onClick={handleViewTransactions}>
                View Transactions
              </s.InlineBtn>
              <s.InlineBtn data-testid="view-payment-plans" onClick={handleViewPaymentPlans} className="ml-2">
                View Payment Plans
              </s.InlineBtn>
              <s.InlineBtn data-testid="view-remove-phone" onClick={handleShowRemovePhone} className="ml-2">
                Remove phone number
              </s.InlineBtn>
            </div>
          </Row>
        </LpBox>
      </Content>
      <Content>
        <Message description={kycMsg} success={kycSuccess} handleClose={handleCloseKycMsg} />
        <Message description={kycSuccessMessage} success handleClose={handleCloseKycSuccessMessage} />
        <LpBox>
          <H2>KYC counts</H2>
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && (
            <div>
              <Row className="mt-4">
                <Label className="col-4">Count without document</Label>
                <div className="col-8">{kycCounts?.countWithoutDocument ?? 'Not set'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-4">Count with driver licence</Label>
                <div className="col-8">{kycCounts?.countWithDriversLicence ?? 'Not set'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-4">Count with passport</Label>
                <div className="col-8">{kycCounts?.countWithPassport ?? 'Not set'}</div>
              </Row>
            </div>
          )}
          <Row className="mt-4 mb-4">
            <div className="col-12">
              <s.InlineBtn
                data-testid="kyc-reset-btn"
                disabled={kycBtnLoading || kycCounts?.kycLevel === null}
                onClick={handleReset}
                className="mt-2"
              >
                Reset
                {kycBtnLoading && (
                  <s.BtnSpinner>
                    <Spinner width="20px" height="20px" borderWidth="2px" />
                  </s.BtnSpinner>
                )}
              </s.InlineBtn>
              <s.InlineBtn data-testid="manual-kyc" onClick={handleManualKYCModal} className="ml-2">
                Manual KYC
              </s.InlineBtn>
            </div>
          </Row>
        </LpBox>
      </Content>
      {showPhoneModal && (
        <Modal
          title="Edit Phone number"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancelPhone}
          handleSubmit={handleSubmitPhone}
          disableConfirmBtn={phoneBtnLoading || !validPhone || phoneInModal.length === 0}
          isLoading={phoneBtnLoading}
        >
          {!phoneSuccess && (
            <Message className="mt-2 mb-0" success={false} description={phoneMsg} handleClose={handleClosePhoneMsg} />
          )}
          <s.InputWrapper valid={validPhone}>
            <PhoneInput international defaultCountry="AU" value={phoneInModal} onChange={handleChangePhone} />
          </s.InputWrapper>
        </Modal>
      )}
      {showEmailModal && (
        <Modal
          title="Edit email"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancelEmail}
          handleSubmit={handleSubmitEmail}
          disableConfirmBtn={emailBtnLoading || !validEmail}
          isLoading={emailBtnLoading}
        >
          {!emailSuccess && (
            <Message className="mt-2 mb-0" success={false} description={emailMsg} handleClose={handleCloseEmailMsg} />
          )}
          <s.InputWrapper>
            <VInput
              type="email"
              isValid={validEmail}
              required
              data-testid="email"
              value={emailInModal}
              onChange={handleChangeEmail}
            />
          </s.InputWrapper>
        </Modal>
      )}
      {showRemovePhoneModal && (
        <Modal
          title="Remove phone number"
          cancelBtnText="Cancel"
          confirmBtnText="Delete"
          handleCancel={handleCancelRemovePhone}
          handleSubmit={handleSubmitRemovePhone}
          disableConfirmBtn={removePhoneLoading}
          isLoading={removePhoneLoading}
          confirmBtnColour="darkRed"
        >
          {removePhoneErrorMsg && (
            <Message
              className="mt-2 mb-0"
              success={false}
              description={removePhoneErrorMsg}
              handleClose={handleCloseRemovePhoneErrorMsg}
            />
          )}
          <div style={{ margin: '20px 0', color: 'red' }}>Are you sure to delete the phone number?</div>
        </Modal>
      )}

      {manualKYCModalOpen && (
        <Modal
          large={false}
          title="Manual KYC"
          cancelBtnText="Cancel"
          confirmBtnText="Complete KYC"
          handleCancel={handleManualKYCModal}
          handleSubmit={kycSubmitHandler}
          disableConfirmBtn={!validPhoneNo}
          isLoading={manualKYCSubmitLoader}
        >
          {manualKYCErrorMsg && (
            <Message
              className="mt-2 mb-0"
              success={false}
              description={manualKYCErrorMsg}
              handleClose={handleCloseManualKYCMsg}
            />
          )}
          <LpDetails className="pt-2 pb-0">
            <div className="form-item">
              <div className="form-field">
                <div className="pt-2">
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={manualKYCInitialValues.firstName}
                    onChange={(e) => handleKYCChangeValue('firstName', e.target.value)}
                  />
                </div>
                <div className="pt-2">
                  <Label>Middle Name</Label>
                  <Input
                    type="text"
                    name="middleName"
                    value={manualKYCInitialValues.middleName}
                    onChange={(e) => handleKYCChangeValue('middleName', e.target.value)}
                  />
                </div>
                <div className="pt-2">
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={manualKYCInitialValues.lastName}
                    onChange={(e) => handleKYCChangeValue('lastName', e.target.value)}
                    required
                  />
                </div>
                <div className="pt-2">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={manualKYCInitialValues.email}
                    onChange={(e) => handleKYCChangeValue('email', e.target.value)}
                    required
                  />
                </div>
                <div className="pt-2">
                  <Label>Date of Birth</Label>
                  <Input
                    type="date"
                    name="dob"
                    value={manualKYCInitialValues.dob}
                    onChange={(e) => handleKYCChangeValue('dob', e.target.value)}
                    required
                  />
                </div>
                <div className="pt-2 pb-3">
                  <Label>Phone number</Label>
                  <PhoneNoWrapper>
                    <PhoneInput
                      defaultCountry="AU"
                      value={manualKYCInitialValues.phoneNo}
                      onChange={(e) => handleKYCChangeValue('phoneNo', (e || '') as string)}
                    />
                  </PhoneNoWrapper>
                  {!validPhoneNo && <InvalidText>Please select country and input correct phone number</InvalidText>}
                </div>

                <div className="pt-2">
                  <Label>Drivers license</Label>

                  <Input
                    type="text"
                    name="licenseNumber"
                    placeholder="License Number"
                    value={manualKYCInitialValues.licenseNumber}
                    onChange={(e) => handleKYCChangeValue('licenseNumber', e.target.value)}
                  />
                  <Input
                    type="text"
                    name="documentNumber"
                    placeholder="Document Number"
                    value={manualKYCInitialValues.documentNumber}
                    onChange={(e) => handleKYCChangeValue('documentNumber', e.target.value)}
                  />
                  <Input
                    type="text"
                    name="licenseState"
                    placeholder="License State"
                    className="mt-1"
                    value={manualKYCInitialValues.licenseState}
                    onChange={(e) => handleKYCChangeValue('licenseState', e.target.value)}
                  />
                </div>

                <div className="pt-4">
                  <Label>Passport</Label>

                  <Input
                    type="text"
                    name="passportNumber"
                    placeholder="Passport Number"
                    value={manualKYCInitialValues.passportNumber}
                    onChange={(e) => handleKYCChangeValue('passportNumber', e.target.value)}
                  />
                  <Input
                    type="text"
                    name="passportCountry"
                    placeholder="Passport Country"
                    className="mt-1"
                    value={manualKYCInitialValues.passportCountry}
                    onChange={(e) => handleKYCChangeValue('passportCountry', e.target.value)}
                  />
                </div>

                <div className="pt-4">
                  <Label>Address</Label>

                  <Row className="mt-0">
                    <div className="col-sm-3">
                      <Input
                        type="text"
                        name="unitNumber"
                        placeholder="Unit #"
                        value={manualKYCInitialValues.unitNumber}
                        onChange={(e) => handleKYCChangeValue('unitNumber', e.target.value)}
                      />
                    </div>
                    <div className="col-sm-3">
                      <Input
                        type="text"
                        name="streetNumber"
                        placeholder="Street #"
                        value={manualKYCInitialValues.streetNumber}
                        onChange={(e) => handleKYCChangeValue('streetNumber', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <Input
                        type="text"
                        name="streetName"
                        placeholder="Street Name"
                        value={manualKYCInitialValues.streetName}
                        onChange={(e) => handleKYCChangeValue('streetName', e.target.value)}
                        required
                      />
                    </div>
                  </Row>

                  <Row className="mt-2">
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="streetType"
                        placeholder="Street Type"
                        value={manualKYCInitialValues.streetType}
                        onChange={(e) => handleKYCChangeValue('streetType', e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="suburb"
                        placeholder="Suburb"
                        value={manualKYCInitialValues.suburb}
                        onChange={(e) => handleKYCChangeValue('suburb', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={manualKYCInitialValues.city}
                        onChange={(e) => handleKYCChangeValue('city', e.target.value)}
                        required
                      />
                    </div>
                  </Row>

                  <Row className="mt-2 mb-4">
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={manualKYCInitialValues.state}
                        onChange={(e) => handleKYCChangeValue('state', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="postalCode"
                        placeholder="Post Code"
                        value={manualKYCInitialValues.postalCode}
                        onChange={(e) => handleKYCChangeValue('postalCode', e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={manualKYCInitialValues.country}
                        onChange={(e) => handleKYCChangeValue('country', e.target.value)}
                        required
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </LpDetails>
        </Modal>
      )}
    </div>
  );
};

export default CustomerDetails;
