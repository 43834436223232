import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { MouseEvent, useState } from 'react';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ListHeaders } from './Constants';
import * as s from './List.styles';
import { ListType } from './types';

const List = ({ customerList, activeId, setDetailsView, setActiveId }: ListType) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleShowDetails = (e: MouseEvent<HTMLButtonElement>, id: string): void => {
    e.preventDefault();
    setDetailsView(true);
    setActiveId(id);
  };

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, id: string): void => {
    e.preventDefault();
    setActiveId(id);
    setShowDropdown(true);
  };

  const handleClickOutside = () => {
    setActiveId('');
    setShowDropdown(false);
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'dots-icon', 'link-btn']);

  return (
    <Content>
      <LpBox>
        <s.Card>
          <s.ItemList>
            <s.Item>
              <s.ItemRow>
                {ListHeaders.map((header, index) => (
                  <s.ItemCol key={index} className={`item-col-header ${header.className}`}>
                    <span>{header.text}</span>
                  </s.ItemCol>
                ))}
              </s.ItemRow>
            </s.Item>
            {customerList.map((customer) => (
              <s.Item className="active-list" key={customer.customerId}>
                <s.ItemRow>
                  <s.ItemCol className="item-col-lgr">
                    <s.Media>
                      <s.FeatureIcon>
                        <UserIcon />
                      </s.FeatureIcon>
                      <s.OrderId>
                        <s.OrderIdBtn onClick={(e) => handleShowDetails(e, customer.customerId)}>
                          {customer.firstName || customer.lastName
                            ? `${customer.firstName} ${customer.lastName}`
                            : 'Guest'}
                        </s.OrderIdBtn>
                      </s.OrderId>
                    </s.Media>
                  </s.ItemCol>
                  <s.ItemCol className="custom-col-xsr">
                    <s.Item className="text-left mt-1 email">{customer.email}</s.Item>
                  </s.ItemCol>
                  <s.ItemCol className="custom-col-lgr">
                    <s.Item className="text-left mt-1">
                      {customer.merchantInfo.businessName || customer.merchantInfo.brandName}
                    </s.Item>
                  </s.ItemCol>

                  <s.ItemCol className="fixed" onClick={(e) => toggleDropdown(e, customer.customerId)}>
                    <DotsIcon className="dots-icon" />

                    <s.ItemActionsBlock
                      active={activeId === customer.customerId && showDropdown}
                      className="item-actions-block"
                    >
                      <s.DetailsLinkBtn className="link-btn" onClick={(e) => handleShowDetails(e, customer.customerId)}>
                        View details
                      </s.DetailsLinkBtn>
                    </s.ItemActionsBlock>
                  </s.ItemCol>
                </s.ItemRow>
              </s.Item>
            ))}
          </s.ItemList>
        </s.Card>
      </LpBox>
    </Content>
  );
};

export default List;
