import { Input } from 'Constants/styles';

import styled from '@emotion/styled';

export const EditBtn = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--lp-colors-medium-blue-600);
  font-size: 15px;
  font-weight: 300;
  padding: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: var(--lp-colors-medium-blue-800);
  }
  &:focus {
    outline: none;
  }
`;

export const BlueBtn = styled.button`
  padding: 0 20px;
  margin: 15px;
  background-color: var(--lp-colors-medium-blue-800);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-800);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 56%);
  width: auto;
  letter-spacing: 0.35px;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  height: 40px;

  &:disabled {
    opacity: 0.5;
  }
  &:first-of-type {
    margin-left: 0;
  }
`;

export const SpinnerWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBrandSpan = styled.span`
  margin-left: 5px;
`;

export const Hr = styled.hr`
  border-top: 1px dotted #e7e7e7;
`;

export const LimitField = styled.div`
  position: relative;
  &:before {
    content: '$';
    left: 1px;
    top: 1px;
    border: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
    line-height: 38px;
    background: #f8f9fb;
    width: 35px;
    text-align: center;
  }
`;

export const LimitInput = styled(Input)`
  padding-left: 43px;
`;

export const FormField = styled.div`
  position: relative;
`;

export const ModalHr = styled.hr`
  height: 0;
  margin: 16px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const PercentageField = styled.span`
  position: relative;
  display: block;
  &:before {
    right: 1px;
    top: 1px;
    content: '%';
    position: absolute;
    line-height: 38px;
    border-left: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 35px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const FixedField = styled.span`
  position: relative;
  display: block;
  &:before {
    left: 1px;
    top: 1px;
    content: '$';
    position: absolute;
    line-height: 38px;
    border-right: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 35px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FixedInput = styled(Input)`
  padding-left: 45px;
`;

export const CardWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const CardNumber = styled.div`
  position: relative;
  margin-left: 15px;
`;

export const DeleteCardBtn = styled.button`
  background-color: var(--lp-colors-medium-blue-800);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-800);
  border-radius: 4px;
  display: inline-block;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 8px;
  cursor: pointer;
  margin-left: 15px;
  &:focus {
    outline: none;
  }
`;

export const Red = styled.span`
  color: red;
`;

export const FormControl = styled.select`
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dce3eb;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
`;

export const AddCardBtn = styled.button`
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: var(--lp-colors-medium-blue-600);
  padding: 0;
  margin-left: 15px;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const InfoLinkUl = styled.ul`
  margin: 0;
  .company-link {
    text-decoration: underline;
    color: #000;
    &:hover {
      text-decoration: underline;
      color: var(--lp-colors-medium-blue-800);
    }
  }
`;

export const InfoLinkLi = styled.li`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PayLimitAmount = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneNoWrapper = styled.div`
  .PhoneInputInput {
    padding: 14px 14px 8px;
    outline: none;
    background: #fff;
    border: 1px solid #dce3eb;
    border-radius: 3px;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 30%);
  }
`;

export const InvalidText = styled.div`
  font-size: 14px;
  color: red;
  position: absolute;
  right: 0;
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FeatureIcon = styled.div`
  border: 1px solid #989fb2;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  .user-icon {
    width: 15px;
    height: 15px;
  }
`;

export const RowP = styled.p`
  padding-left: 15px;
  color: #6c7488;
  line-height: 24px;
  margin-top: 14px;
`;

export const EditFormRow = styled.div`
  display: flex;
  input {
    max-height: 40px;
  }
`;

export const EditLabel = styled.label`
  width: 250px;
  cursor: default;
  display: flex;
  align-items: center;
  font-weight: 300;
  color: #6c7488;
`;
