import './OrderFilter.scss';

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import { MouseEvent, ReactElement, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import { filterStatusArray, filterTypeArray } from './FilterConstants';

type Props = {
  statusOptions: boolean[];
  typeOptions: boolean[];
  handleStatusOptionClick(e: MouseEvent<HTMLElement>, index: number): void;
  handleTypeOptionClick(e: MouseEvent<HTMLElement>, index: number): void;
};

const OrderFilter = ({
  statusOptions,
  typeOptions,
  handleStatusOptionClick,
  handleTypeOptionClick,
}: Props): ReactElement => {
  const [showStatusOptions, setShowStatusOptions] = useState<boolean>(false);
  const [showTypeOptions, setShowTypeOptions] = useState<boolean>(false);

  const statusRef = useRef<HTMLUListElement>(null);
  const typeRef = useRef<HTMLUListElement>(null);

  const handleClickOutsideStatusBtn = () => {
    setShowStatusOptions(false);
  };

  const handleClickOutsideTypeBtn = () => {
    setShowTypeOptions(false);
  };

  useClickOutside(statusRef, handleClickOutsideStatusBtn, ['outer-btn-status', 'tclick-status', 'arrow-down-status']);
  useClickOutside(typeRef, handleClickOutsideTypeBtn, ['outer-btn-type', 'tclick-type', 'arrow-down-type']);

  const handleStatusClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowStatusOptions((prev) => !prev);
  };

  const handleTypeClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowTypeOptions((prev) => !prev);
  };

  return (
    <div className="lp-nav lp-filers transactions-order-filter">
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <li className="nav-item">Filter by:</li>
        <li className="nav-item">
          <button className="outer-btn out-btn-type" onClick={handleTypeClick}>
            <div className="payout-schedule tclick tclick-type">Type</div>
            <ArrowDown className={`arrow-down arrow-down-type ${showTypeOptions ? 'up' : ''}`} />
          </button>
          {showTypeOptions && (
            <ul className="payout-schedule-menu" ref={typeRef}>
              {filterTypeArray.map((filter, index) => (
                <li key={filter.text}>
                  <label className="lp-option" onClick={(event) => handleTypeOptionClick(event, index)}>
                    {filter.text}
                    <input type="checkbox" name={filter.inputName} value="1" />
                    <span className={`check-mark ${typeOptions[index] ? 'checked' : ''}`} />
                  </label>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li className="nav-item">
          <button className="outer-btn out-btn-status" onClick={handleStatusClick}>
            <div className="payout-schedule tclick tclick-status">Status</div>
            <ArrowDown className={`arrow-down arrow-down-status ${showStatusOptions ? 'up' : ''}`} />
          </button>
          {showStatusOptions && (
            <ul className="payout-schedule-menu" ref={statusRef}>
              {filterStatusArray.map((filter, index) => (
                <li key={filter.text}>
                  <label className="lp-option" onClick={(event) => handleStatusOptionClick(event, index)}>
                    {filter.text}
                    <input type="checkbox" name={filter.inputName} value="1" />
                    <span className={`check-mark ${statusOptions[index] ? 'checked' : ''}`} />
                  </label>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default OrderFilter;
