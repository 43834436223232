export const ListHeaders = [
  {
    className: 'item-col-lgr',
    text: 'Business name',
  },
  {
    className: 'custom-col-xsr',
    text: 'Website',
  },
  {
    className: 'custom-col-xsr',
    text: 'Slug',
  },
  {
    className: 'custom-col-xsr',
    text: 'Max pay plan amount unconfirmed customers',
  },
  {
    className: 'item-col-amount',
    text: 'Max pay plan amount confirmed customers',
  },
];

export const filterStatusArray = [
  {
    text: 'All',
  },
  {
    text: 'Active',
  },
  {
    text: 'Inactive',
  },
];
