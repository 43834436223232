import BackComponent from 'Components/BackComponent/BackComponent';
import HeaderTile from 'Components/HeaderTitle/HeaderTitle';
import { Content } from 'Constants/styles';
import React, { MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setMerchantDetails } from 'redux/Actions';
import setBodyOverflow from 'utils/setBodyOverflow';

import AccountSettings from './AccountSettings';
import CompanyFees from './CompanyFees';
import CompanyInfo from './CompanyInfo';
import GeneralSettings from './GeneralSettings';
import PaymentPlanLimit from './PaymentPlanLimit';
import PublicInformation from './PublicInformation';

const CompanyDetail = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatch(setMerchantDetails(false, '', '', null, ''));
  };

  useEffect(() => {
    setBodyOverflow('auto');
  }, []);

  return (
    <>
      <HeaderTile title="Merchant Details" />
      <BackComponent text="Back to merchants" handleClickBack={handleClickBack} />
      <Content className="merchant-details-content">
        <CompanyInfo />
        <CompanyFees />
        <PaymentPlanLimit />
        <AccountSettings />
        <PublicInformation />
        <GeneralSettings />
      </Content>
    </>
  );
};

export default CompanyDetail;
