import styled from '@emotion/styled';

export const LpBox = styled.div`
  background-color: #fff;
  padding: 31px 29px 28px 29px;
  border: 1px #e7e7e7 solid;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.5);
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const LpDetails = styled.div`
  color: #000;
  font-weight: 500;
`;

export const Content = styled.article`
  padding: 18px 60px 25px 60px;
  display: block;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;

export const H2 = styled.h2`
  margin-bottom: 8px;
  margin-top: 0;
  font-family: 'Acumin Regular', Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000;
`;

export const H3 = styled.h3`
  margin: 0;
  font-weight: 500;
  font-family: 'Acumin Regular', Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #354052;
`;

export const H4 = styled.h4`
  font-family: inherit;
  font-weight: 500;
  color: inherit;
  font-size: 21px;
  line-height: 24px;
  margin: 0;
`;

export const H5 = styled.h5`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #3a3e46;
`;

export const P = styled.p`
  color: #6c7488;
  line-height: 24px;
  margin-top: 14px;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  cursor: default;
  display: inline-block;
  font-weight: 300;
  color: #6c7488;
`;

export const FormItem = styled.div`
  padding-bottom: 15px;
  font-weight: 300;
  color: #6c7488;
  position: relative;
`;

export const Input = styled.input`
  padding: 13px 17px 8px;
  outline: none;
  background-color: #fff;
  border: 1px solid #dce3eb;
  border-radius: 3px;
  width: 100%;
  color: #000;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  box-sizing: border-box;

  &:focus {
    border: 1px #505bcc solid;
    box-shadow: none;
  }
`;
