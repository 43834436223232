import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import React, { FormEvent, MouseEvent, ReactElement, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { CancelBtn, ConfirmBtn, ModalInner, SpinnerWrapper, Wrapper } from './Modal.styles';

export type ModalProps = {
  title: string;
  children?: ReactNode;
  cancelBtnText: string;
  confirmBtnText: string;
  disableConfirmBtn?: boolean;
  isLoading?: boolean;
  confirmBtnColour?: string;
  handleCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  large?: boolean;
  disableFooter?: boolean;
};

const modalRoot = document.getElementById('modal-root');

const Modal = ({
  title,
  children,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Update',
  disableConfirmBtn,
  isLoading = false,
  confirmBtnColour = '',
  handleCancel,
  handleSubmit,
  large = false,
  disableFooter = false,
}: ModalProps): ReactElement => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    if (!modalRoot) {
      return;
    }

    modalRoot.appendChild(container);
    return () => {
      modalRoot.removeChild(container);
    };
  }, [container]);

  const element = (
    <Wrapper>
      <div className="modal fade" tabIndex={-1} role="dialog" aria-modal="true">
        <div className={`modal-dialog modal-dialog-centered ${large ? 'large' : ''}`} role="document">
          <ModalInner>
            <div className="modal-content">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <button className="back" onClick={handleCancel}>
                    <ArrowLeftIcon className="arrow-left" />
                  </button>
                  <h5 className="modal-title title-lg">{title}</h5>
                  <button className="close" onClick={handleCancel}>
                    <CloseIcon className="close-icon" />
                  </button>
                </div>
                <div className="modal-body modal-body-reset">
                  <h3 className="mobile-title">{title}</h3>
                  {children}
                </div>
                {!disableFooter && (
                  <div className="modal-footer modal-reverse-grid">
                    <CancelBtn onClick={handleCancel}>{cancelBtnText}</CancelBtn>
                    <ConfirmBtn
                      confirmBtnColour={confirmBtnColour}
                      type="submit"
                      disabled={disableConfirmBtn || isLoading}
                    >
                      {confirmBtnText}
                      {isLoading && (
                        <SpinnerWrapper>
                          <Spinner width="20px" height="20px" borderWidth="2px" />
                        </SpinnerWrapper>
                      )}
                    </ConfirmBtn>
                  </div>
                )}
              </form>
            </div>
          </ModalInner>
        </div>
      </div>
    </Wrapper>
  );

  return createPortal(element, container);
};

export default Modal;
