import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 0 60px 25px 60px;

  .pagination {
    display: flex;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 16px;
    list-style: none;
    border-radius: 4px;

    .page-item {
      &:first-of-type {
        .page-link {
          margin-left: 0;
        }
      }

      &.active {
        .page-link {
          color: #000;
          border: 1px solid #e7e7e7;
          padding: 14px 19px 11px 19px;
        }
      }

      .page-link {
        cursor: pointer;
        padding: 15px 20px 12px;
        color: #6c7488;
        border: 0;
        border-radius: 50%;
        font-size: 16px;
        line-height: 20px;
        margin-right: 5px;
        letter-spacing: 0;
        display: block;
        background-color: #fff;
        &:focus {
          outline: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .pagination {
      padding-left: 20px;
    }
  }
`;
