import Back from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import { OrderSearchType } from 'Components/OrderSearch/OrderSearchOptions';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content } from 'Constants/styles';
import qs from 'query-string';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fromCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import OrderSearch, { SearchOptionType } from '../OrderSearch/OrderSearch';
import { OrderSearchOptions } from '../OrderSearch/OrderSearchOptions';
import { filterStatusArray, filterTypeArray } from './FilterConstants';
import MerchantProfile from './MerchantProfile';
import OrderFilter from './OrderFilter';
import OrderList from './OrderList';
import TransactionDetails from './TransactionDetails';
import { ItemsSearchWrapper, SpinnerWrapper } from './Transactions.styles';
import { TransactionAPIResponseType } from './types';

type SearchOptionObjType = {
  [key: string]: string;
};
const SearchOptionObj: SearchOptionObjType = {
  [OrderSearchType.order]: 'internalOrderId',
  [OrderSearchType.email]: 'customerEmail',
  [OrderSearchType.amount]: 'amount',
};

type TransactionsType = {
  setShowTransactions(show: boolean): void;
  showingCustomerId?: string;
};

const Transactions = ({ setShowTransactions, showingCustomerId }: TransactionsType): ReactElement => {
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [orders, setOrders] = useState<TransactionAPIResponseType[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<TransactionAPIResponseType[]>([]);
  const [statusOptions, setStatusOptions] = useState<boolean[]>(new Array(filterStatusArray.length).fill(false));
  const [typeOptions, setTypeOptions] = useState<boolean[]>(new Array(filterTypeArray.length).fill(false));
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');
  const [detailsView, setDetailsView] = useState<boolean>(false);
  const [activeOrder, setActiveOrder] = useState<TransactionAPIResponseType | null>(null);
  const [activeTransactionId, setActiveTransactionId] = useState<string>('');
  const [now, setNow] = useState<number>(0);

  const { merchantId, merchantName } = qs.parse(window.location.search);
  const debouncedSearch = useDebounce(selectedSearch, 500);
  const history = useHistory();

  const { apiBaseUri } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (activeTransactionId === '') {
      setActiveOrder(null);
    }

    const order = filteredOrders.find((o) => o.transactionId === activeTransactionId);
    setActiveOrder(order ?? null);
  }, [activeTransactionId, filteredOrders]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId || debouncedSearch !== selectedSearch) {
      return;
    }

    const fetchMerchantTransactions = async () => {
      let url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?sort=-createdAt&page=${activePage}`;
      if (debouncedSearch.length > 0) {
        let tempSearch: number | string = debouncedSearch;
        if (selectedSearchOption.value === OrderSearchType.amount) {
          tempSearch = fromCurrency(tempSearch.replace(',', ''));
        }
        url += `&${SearchOptionObj[selectedSearchOption.value]}=${encodeURIComponent(tempSearch)}`;
      }
      if (showingCustomerId) {
        url += `&customerId=${showingCustomerId}`;
      }
      setIsLoading(true);
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('Limepay-Page-Count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          const filteredResponse = response.filter(
            (r: TransactionAPIResponseType) => !!r.transactionPayType.payPlanId || !!r.transactionPayType.payCardId,
          );
          setOrders(filteredResponse);
          setPageCount(pages);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchMerchantTransactions();
  }, [
    apiBaseUri,
    merchantId,
    activePage,
    debouncedSearch,
    selectedSearchOption,
    now,
    selectedSearch,
    showingCustomerId,
  ]);

  useEffect(() => {
    if (orders.length === 0) {
      return;
    }
    let tempOrders = orders.map((o) => ({ ...o }));

    /* filter type options only when one of them is checked */
    const typeCheckedLength = typeOptions.filter((option) => option).length;
    if (typeCheckedLength === 1) {
      if (typeOptions[0]) {
        tempOrders = orders.filter((order) => !!order.transactionPayType.payCardId);
      }
      if (typeOptions[1]) {
        tempOrders = orders.filter((order) => !!order.transactionPayType.payPlanId);
      }
    }

    const statusCheckedLength = statusOptions.filter((option) => option).length;
    if (statusCheckedLength > 0 && statusCheckedLength < statusOptions.length) {
      const status: string[] = [];
      statusOptions.forEach((option, index) => {
        if (option) {
          status.push(filterStatusArray[index].value);
        }
      });
      tempOrders = tempOrders.filter((order) => status.includes(order.transactionStatus.toLowerCase()));
    }

    setFilteredOrders(tempOrders);
  }, [typeOptions, statusOptions, orders]);

  const handleStatusOptionClick = (e: MouseEvent<HTMLElement>, index: number): void => {
    e.preventDefault();
    setStatusOptions((prev: boolean[]): boolean[] => [...prev.slice(0, index), !prev[index], ...prev.slice(index + 1)]);
  };

  const handleTypeOptionClick = (e: MouseEvent<HTMLElement>, index: number): void => {
    e.preventDefault();
    setTypeOptions((prev) => [...prev.slice(0, index), !prev[index], ...prev.slice(index + 1)]);
  };

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    setShowPagination(orders.length === filteredOrders.length);
  }, [orders, filteredOrders]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (!showingCustomerId) {
      history.push('/admin-dashboard/transactions');
    }
    setShowTransactions(false);
  };

  const handleClickBackOnDetails = (): void => {
    setDetailsView(false);
    setActiveOrder(null);
    setActiveTransactionId('');
  };

  return (
    <>
      {!detailsView && (
        <>
          <ItemsSearchWrapper>
            <div>
              <HeaderTitle title={showingCustomerId ? `Transactions for ${showingCustomerId}` : 'Transactions'} />
              <Back
                text={showingCustomerId ? 'Back to customer' : 'Back to merchants list'}
                handleClickBack={handleClickBack}
              />
            </div>
            {!showingCustomerId && (
              <OrderSearch
                selectedSearchOption={selectedSearchOption}
                setSelectedSearchOption={setSelectedSearchOption}
                selectedSearch={selectedSearch}
                handleSearch={handleSearch}
              />
            )}
          </ItemsSearchWrapper>
          <MerchantProfile merchantId={merchantId ?? ''} name={merchantName ?? ''} />
          <OrderFilter
            statusOptions={statusOptions}
            typeOptions={typeOptions}
            handleStatusOptionClick={handleStatusOptionClick}
            handleTypeOptionClick={handleTypeOptionClick}
          />
          {isLoading && (
            <SpinnerWrapper>
              <Spinner color="#0016D1" />
            </SpinnerWrapper>
          )}
          {errorMsg.length > 0 && (
            <Content>
              <Message success={false} handleClose={handleCloseMsg} description={errorMsg} />
            </Content>
          )}
          {!isLoading && (
            <>
              {filteredOrders.length > 0 && (
                <OrderList
                  orders={filteredOrders}
                  setDetailsView={setDetailsView}
                  setActiveTransactionId={setActiveTransactionId}
                />
              )}
              {filteredOrders.length === 0 && <EmptyList />}
              {showPagination && (
                <Pagination
                  pageCount={pageCount}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageStartIndex={pageStartIndex}
                  setPageStartIndex={setPageStartIndex}
                />
              )}
            </>
          )}
        </>
      )}
      {detailsView && (
        <TransactionDetails
          apiBaseUri={apiBaseUri}
          activeOrder={activeOrder}
          handleClickBackOnDetails={handleClickBackOnDetails}
          merchantId={merchantId ?? ''}
          merchantName={merchantName ?? ''}
          setNow={setNow}
        />
      )}
    </>
  );
};

export default Transactions;
