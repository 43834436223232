import Message from 'Components/Message/';
import Spinner from 'Components/Spinner/Spinner';
import { TransactionAPIResponseType } from 'Components/Transactions/types';
import { Content, H2, Label, LpBox, Row } from 'Constants/styles';
import { MouseEvent, useEffect, useState } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import styled from '@emotion/styled';

const SpinnerWrapper = styled.div`
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type TransactionDetailsType = {
  apiBaseUri: string;
  transactionId: string;
  merchantId: string;
};

const TransactionDetails = ({ apiBaseUri, transactionId, merchantId }: TransactionDetailsType) => {
  const [transaction, setTransaction] = useState<TransactionAPIResponseType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    if (!apiBaseUri || !merchantId || !transactionId) {
      return;
    }

    const fetchDetails = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?sort=-createdAt&transactionIds=${transactionId}`;
        const options = await getFetchOptions();

        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json = await res.json();
        setTransaction(json?.[0] || null);
      } catch (e) {
        setErrorMsg(e.message || 'Failed to get transaction details');
      } finally {
        setLoading(false);
      }
    };
    fetchDetails();
  }, [apiBaseUri, merchantId, transactionId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <Content>
      <LpBox>
        <H2>Transaction Details</H2>
        {loading && (
          <SpinnerWrapper>
            <Spinner color="#0016D1" />
          </SpinnerWrapper>
        )}
        {errorMsg.length > 0 && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
        {!loading && (
          <div>
            <Row className="mt-3">
              <Label className="col-3">Transaction ID</Label>
              <div className="col-9">{transactionId}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Amount</Label>
              <div className="col-9">
                {transaction ? toCurrency(transaction.transactionAmount, transaction.currency) : '-'}
              </div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Currency</Label>
              <div className="col-9">{transaction?.currency}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Refunded Amount</Label>
              <div className="col-9">
                {transaction ? toCurrency(transaction.refundedAmount, transaction.currency) : '-'}
              </div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Date</Label>
              <div className="col-9">{getDateFormat({ time: transaction?.createdAt ?? '' }).formatted}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Transaction status</Label>
              <div className="col-9">{transaction?.transactionStatus}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Payout status</Label>
              <div className="col-9">{transaction?.payoutStatus}</div>
            </Row>
          </div>
        )}
      </LpBox>
    </Content>
  );
};

export default TransactionDetails;
