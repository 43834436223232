import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding-top: 3px;
  margin-left: 6px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: var(--lp-colors-medium-blue-600);
  .logo {
    width: 142px;
    position: absolute;
    left: 100px;
    top: 50px;
  }
  .error-bar {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    background-color: rgb(254, 240, 244);
    color: rgb(141, 46, 73);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.38px;
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    max-width: 1600px;
    padding: 90px 100px 80px;
    width: 100%;
    height: 100%;
  }
  .login-form {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    max-width: 550px;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .form-header {
    display: flex;
    flex-direction: column;
    min-height: 136px;
    border-bottom: 1px solid rgb(218, 227, 237);
  }
  .form-title {
    margin: 0;
    padding: 30px;
    display: block;
    color: rgb(85, 91, 125);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.38px;
  }
  .input-wrapper {
    width: 100%;
    position: relative;
  }
  .label {
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgb(218, 227, 237);
    box-sizing: border-box;
  }
  .icon-wrapper {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 70px;
  }
  .email-icon,
  .password-icon {
    width: 22px;
    height: 22px;
  }
  .input {
    appearance: none;
    background: rgba(0, 0, 0, 0);
    height: 100%;
    width: 100%;
    padding: 0 20px 0 8px;
    border: none;
    color: rgb(34, 34, 48);
    font-size: 18px;

    &:focus {
      outline: none;
    }
  }
  .input-submit {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
  .small {
    font-size: 80%;
  }
  .forget-password {
    color: #0a173a;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.19px;
    background-color: transparent;
    text-decoration: underline;
  }
  .btn {
    margin-top: 30px;
    width: 100%;
    height: 64px;
    background: var(--lp-colors-medium-blue-600);
    border: 1px solid var(--lp-colors-medium-blue-600);
    border-radius: 7px;
    color: #fff;
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;
