import { Label, Row } from 'Constants/styles';
import React from 'react';
import { Currency, timesBy100, toCurrency } from 'utils/currency';

import { CardBrandSpan } from './styles';
import { FeeCountryType } from './types';

type ShowFeeProps = {
  label: string;
  fee: FeeCountryType;
  currency: Currency;
};

const ShowFee = ({ label, fee, currency }: ShowFeeProps) => (
  <>
    <Row className="pt-2">
      <div className="col-sm-4">
        <Label>{label}</Label>
      </div>
      <div className="col-sm-8">
        {timesBy100(String(fee.perc))}% + {toCurrency(fee.fixed, currency)}
      </div>
    </Row>
    {fee.cards?.map((card, index) => (
      <Row className="pt-1" key={index}>
        <div className="col-sm-4"></div>
        <div className="col-sm-4">
          <Label>
            {card.cardFunding}
            {card.cardBrand && <CardBrandSpan>- {card.cardBrand}</CardBrandSpan>}
          </Label>
        </div>
        <div className="col-sm-4">
          {timesBy100(String(card.perc))}% + {toCurrency(card.fixed, currency)}
        </div>
      </Row>
    ))}
  </>
);

export default ShowFee;
