import { baseUri } from 'Constants/Constants';

export const sideBarLinks = [
  {
    label: 'Merchants',
    href: `${baseUri}/merchants`,
  },
  {
    label: 'Onboard Merchant',
    href: `${baseUri}/onboard-merchant`,
  },
  {
    label: 'Resend Onboard Merchant Email',
    href: `${baseUri}/resend-email`,
  },
  {
    label: 'Transactions',
    href: `${baseUri}/transactions`,
  },
  {
    label: 'Overdue Plans',
    href: `${baseUri}/overdue`,
  },
  {
    label: 'Disputes',
    href: `${baseUri}/disputes`,
  },
  {
    label: 'OTP Resets',
    href: `${baseUri}/otp-resets`,
  },
  {
    label: 'Customers',
    href: `${baseUri}/customers`,
  },
  {
    label: 'Payouts',
    href: `${baseUri}/payouts`,
  },
];
