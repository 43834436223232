import { Content, H2, LpBox } from 'Constants/styles';
import { ReactComponent as ArrowDownRightIcon } from 'assets/svg/arrow-down-right.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { readString } from 'react-papaparse';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { TransferListHeaders } from './Constants';
import * as s from './List.styles';
import { ListType, PayoutAPIResponse } from './types';

const List = ({ orders, apiBaseUri, merchantId, setDetailsView, setActiveOrder }: ListType) => {
  const [activeOrderId, setActiveOrderId] = useState<string>('');
  const [downloadedData, setDownloadedData] = useState<any>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const handleClickOutside = () => {
    setActiveOrderId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'payout-link-btn', 'dots-icon']);

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, orderId: string): void => {
    e.preventDefault();
    setActiveOrderId(orderId);
  };

  const handleClickDownload = async (e: MouseEvent<HTMLButtonElement>, payoutId: string): Promise<void> => {
    e.preventDefault();
    if (!apiBaseUri || !merchantId || !payoutId) {
      return;
    }

    const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/csv/${payoutId}`;
    const options = await getFetchOptions();

    setDownloadedData(null);
    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.text();
      })
      .then((res) => {
        readString(res, {
          worker: true,
          complete: ({ data }) => {
            const noneEmptyData = data.filter((d) => Array.isArray(d) && d.length > 1);
            setDownloadedData(noneEmptyData);
            setDownloadReady(true);
          },
        });
      })
      .catch(() => {
        console.error('Failed to download');
      });
  };

  useEffect(() => {
    if (csvRef?.current && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const handleViewDetailsBtn = (e: MouseEvent<HTMLButtonElement>, order: PayoutAPIResponse): void => {
    e.preventDefault();
    setDetailsView(true);
    setActiveOrder(order);
  };

  return (
    <Content>
      <LpBox>
        <H2 className="section-title-text">Payouts</H2>
        <s.Card>
          <s.ItemList>
            <s.Item className="item-list-header">
              <s.ItemRow className="item-row">
                {TransferListHeaders.map((header, index) => (
                  <s.ItemCol key={index} className={`item-col-header ${header.className}`}>
                    <span>{header.text}</span>
                  </s.ItemCol>
                ))}
              </s.ItemRow>
            </s.Item>
            {orders.map((order) => (
              <s.Item key={order.payoutId} className="active-item">
                <s.ItemRow>
                  <s.ItemCol className="item-col-sales item-col-order">
                    <s.Media>
                      <s.FeatureIcon>
                        <ArrowDownRightIcon className="arrow-down-right" />
                      </s.FeatureIcon>
                      <div className="order-id pt-2 pt-md-2">
                        <s.OrderIdBtn onClick={(e) => handleViewDetailsBtn(e, order)}>
                          {typeof order.amount === 'number' && order.currency
                            ? toCurrency(order.amount, order.currency)
                            : '-'}
                        </s.OrderIdBtn>
                      </div>
                    </s.Media>
                  </s.ItemCol>
                  <s.ItemCol className="item-col-sales item-col-xl">
                    <s.Item className="text-left mt-1">
                      <div className="price pl-m">
                        {
                          getDateFormat({
                            time: order.arrivalDate,
                            showDaySuffix: false,
                            showFullYear: true,
                            showShortMonth: false,
                            showComma: false,
                          }).date
                        }
                      </div>
                    </s.Item>
                  </s.ItemCol>
                  <s.ItemCol className="item-col-sales item-col-xl">
                    <s.Item className="text-left mt-1">
                      <s.DownloadBtn onClick={(e) => handleClickDownload(e, order.payoutId)}>Download</s.DownloadBtn>
                    </s.Item>
                  </s.ItemCol>
                  <s.ItemCol
                    className="fixed item-col-actions-dropdown"
                    onClick={(e) => toggleDropdown(e, order.payoutId)}
                  >
                    <DotsIcon className="dots-icon" />

                    <s.ItemActionsBlock className="item-actions-block" active={activeOrderId === order.payoutId}>
                      <s.LinkBtn className="payout-link-btn" onClick={(e) => handleViewDetailsBtn(e, order)}>
                        View details
                      </s.LinkBtn>
                      <s.LinkBtn className="payout-link-btn" onClick={(e) => handleClickDownload(e, order.payoutId)}>
                        Download CSV
                      </s.LinkBtn>
                    </s.ItemActionsBlock>
                  </s.ItemCol>
                </s.ItemRow>
              </s.Item>
            ))}
          </s.ItemList>
        </s.Card>
      </LpBox>
      {downloadedData ? (
        <CSVLink ref={csvRef} data={downloadedData} target="_self" filename={Date.now() + '.csv'} />
      ) : (
        <></>
      )}
    </Content>
  );
};

export default List;
