import styled from '@emotion/styled';

export const CancelBtn = styled.button`
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 13px 38px 10px 38px;
  background-color: #fff;
  border: 1px solid #d1d3d7;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  width: auto;
  letter-spacing: 0.35px;
  margin-right: 4px;
  @media (max-width: 768px) {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
`;

type ConfirmBtnProps = {
  confirmBtnColour: string | null;
};

export const ConfirmBtn = styled.button<ConfirmBtnProps>`
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  width: auto;
  letter-spacing: 0.35px;
  padding: 13px 38px 10px 38px;
  color: #fff;
  background-color: ${(props) => props.confirmBtnColour || 'var(--lp-colors-medium-blue-600)'};
  border: ${(props) => `1px solid ${props.confirmBtnColour || 'var(--lp-colors-medium-blue-600)'}`};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalInner = styled.div`
  width: 100%;
  margin: 40px auto;
`;

export const Wrapper = styled.div`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: block;
    width: 100%;
    height: 100%;
    overflow: scroll;
    outline: 0;
  }
  .fade {
    background: rgba(255, 255, 255, 0.85);
    transition: opacity 0.15s linear;
  }
  .modal-dialog {
    max-width: 571px;
    position: relative;
    width: auto;
    margin: 8px;
    pointer-events: none;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    &.large {
      max-width: 700px;
    }
  }
  .modal-dialog-centered {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 16px);
  }
  .modal-content {
    border-radius: 3px;
    -webkit-box-shadow: 0 10.5px 35px 25px rgba(0, 0, 0, 0.07);
    box-shadow: 0 10.5px 35px 25px rgba(0, 0, 0, 0.07);
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
  }
  .modal-header {
    color: #3a3e46;
    border-bottom: 0;
    padding: 35px 43px 0 43px;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .modal-title {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    color: #3a3e46;
    font-family: 'Acumin Regular', Arial, sans-serif;
  }
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 16px;
    &.modal-body-reset {
      padding: 0 43px 5px;
    }
  }
  .modal-footer {
    padding: 26px 45px 26px;
    background-color: #f9f9f9;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .btn {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: 400;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-reset {
    padding: 13px 38px 10px 38px;
    background-color: #fff;
    border: 1px solid #d1d3d7;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    width: auto;
    letter-spacing: 0.35px;
    &.blue {
      padding: 13px 40px 10px 40px;
      background-color: var(--lp-colors-medium-blue-600);
      color: #fff;
      border: 1px solid var(--lp-colors-medium-blue-600);
      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled {
        opacity: 0.65;
        cursor: default;
      }
    }
  }
  .close {
    background-color: #9ea7ac;
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: none;
    display: none;
  }
  .close-icon {
    width: 12px;
    height: 12px;
    margin-top: 5px;
    path {
      fill: #fff;
    }
  }
  .back {
    display: none;
  }
  .arrow-left {
    width: 20px;
    height: 20px;
  }
  .mobile-title {
    display: none;
    font-family: 'Acumin Regular', Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #354052;
    margin-top: 0;
    margin-bottom: 8px;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    max-width: 100vw;
    bottom: 0;
    .fade {
      background-color: #fff;
      transition: opacity 0.15s linear;
    }
    .modal-content {
      border: 0;
      box-shadow: none;
    }
    .modal-header {
      border-bottom: 1px solid #e7e7e7;
      padding: 19px 30px 19px 20px;
      height: 64px;
      background-color: #fff;
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      justify-content: flex-start;
    }
    .back {
      background-color: transparent;
      display: block;
      margin-right: 14px;
      outline: none;
      border: none;
    }
    .modal-title {
      font-size: 19px;
      line-height: 28px;
      color: #354052;
    }
    .modal-dialog {
      padding: 80px 0 160px;
      align-items: flex-start;
    }
    .modal-body.modal-body-reset {
      padding: 0 20px;
      max-width: 100vw;
    }
    .custom-reset-btn {
      display: none !important;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      padding: 25px 30px;
    }
    .mobile-title {
      display: block;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      margin: 28px auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - 56px);
    }
  }
  @media (min-width: 1200px) {
    .close {
      display: inline-block;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  padding-top: 2px;
`;
