import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  isLoggedIn: boolean;
  component: any;
}

const PrivateRoute = ({ isLoggedIn, component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/admin-dashboard/login', state: { from: props.location } }} />
      )
    }
  />
);

export default PrivateRoute;
