export enum OrderSearchType {
  order = 'order',
  email = 'email',
  amount = 'amount',
}

export const OrderSearchOptions = [
  {
    text: 'Amount',
    value: 'amount',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Order ID',
    value: 'order',
  },
];
