import styled from '@emotion/styled';

export const Content = styled.article`
  padding: 30px 60px 0;
  display: block;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;
