import BackComponent from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { Content, H2, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { readString } from 'react-papaparse';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { TransactionListHeaders } from './Constants';
import * as s from './PayoutDetails.styles';
import { PayoutDetailsType, TransactionAPIResponse } from './types';

const PayoutDetails = ({ activeOrder, apiBaseUri, merchantId, setDetailsView }: PayoutDetailsType): ReactElement => {
  const [transactions, setTransactions] = useState<TransactionAPIResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [downloadedData, setDownloadedData] = useState<any>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const csvRef = useRef<any>();

  useEffect(() => {
    if (!apiBaseUri || !merchantId || !activeOrder || !activeOrder.payoutId) {
      return;
    }

    const fetchPayout = async () => {
      try {
        setIsLoading(true);
        const options = await getFetchOptions();
        const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/${activeOrder.payoutId}`;
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const response = await res.json();
        setTransactions(response.transactionSummaries);
      } catch (e) {
        setErrorMsg(e.message || 'Failed to fetch payout');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPayout();
  }, [apiBaseUri, merchantId, activeOrder]);

  useEffect(() => {
    if (csvRef?.current && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setDetailsView(false);
  };

  const handleClickDownload = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (!activeOrder) {
      return;
    }

    const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/csv/${activeOrder.payoutId}`;
    const options = await getFetchOptions();

    setDownloadedData(null);
    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.text();
      })
      .then((res) => {
        readString(res, {
          worker: true,
          complete: ({ data }) => {
            const noneEmptyData = data.filter((d) => Array.isArray(d) && d.length > 1);
            setDownloadedData(noneEmptyData);
            setDownloadReady(true);
          },
        });
      })
      .catch(() => {
        console.error('Failed to download');
      });
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <>
      <HeaderTitle title={`Payment details for #${activeOrder.payoutId}`} />
      <BackComponent text="Back to payout" handleClickBack={handleClickBack} />
      <Content>
        <LpBox>
          <H2 className="mb-4">Payout</H2>
          <LpDetails>
            <Row className="pt-3">
              <div className="col-3">
                <Label>Date paid</Label>
              </div>
              <div className="col-9">
                {
                  getDateFormat({
                    time: activeOrder.createdAt,
                    showDaySuffix: false,
                    showFullYear: true,
                    showShortMonth: false,
                    showComma: false,
                  }).date
                }
              </div>
            </Row>
            <Row className="pt-3">
              <div className="col-3">
                <Label>Arrival date</Label>
              </div>
              <div className="col-9">
                {
                  getDateFormat({
                    time: activeOrder.arrivalDate,
                    showDaySuffix: false,
                    showFullYear: true,
                    showShortMonth: false,
                    showComma: false,
                  }).date
                }
              </div>
            </Row>
            <Row className="pt-3">
              <div className="col-3">
                <Label>Amount</Label>
              </div>
              <div className="col-6">
                {typeof activeOrder.amount === 'number' && activeOrder.currency
                  ? toCurrency(activeOrder.amount, activeOrder.currency)
                  : '-'}
              </div>
              <div className="col-3 text-right">
                <s.DownloadBtn onClick={handleClickDownload}>Download CSV</s.DownloadBtn>
              </div>
            </Row>
          </LpDetails>
        </LpBox>

        <LpBox>
          <H2 className="mb-4">Transactions</H2>
          <s.Card>
            <s.ItemList>
              <s.Item className="item-list-header">
                <s.ItemRow>
                  {TransactionListHeaders.map((header, index) => (
                    <s.ItemCol className="item-col-header item-col-title" key={index}>
                      <span>{header.text}</span>
                    </s.ItemCol>
                  ))}
                </s.ItemRow>
              </s.Item>
              {isLoading && (
                <s.SpinnerWrapper>
                  <Spinner color="#0016D1" />
                </s.SpinnerWrapper>
              )}
              {!isLoading && errorMsg.length > 0 && (
                <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />
              )}
              {!isLoading && transactions.length === 0 && <EmptyList />}
              {!isLoading &&
                transactions.length > 0 &&
                transactions.map((t, index) => (
                  <s.Item className="active-item" key={index}>
                    <s.ItemRow>
                      <s.ItemCol>
                        <s.Media>
                          <s.FeatureIcon>
                            <OrderIdIcon />
                          </s.FeatureIcon>
                          <s.OrderId>
                            <s.Price>{t.orderId}</s.Price>
                          </s.OrderId>
                        </s.Media>
                      </s.ItemCol>
                      <s.ItemCol>
                        <s.Item className="mt-1">
                          <s.Price className="price">
                            {typeof t.amount === 'number' ? toCurrency(t.amount, t.currency) : '-'}
                          </s.Price>
                        </s.Item>
                      </s.ItemCol>
                      <s.ItemCol>
                        <s.Item className="mt-1">
                          <s.Price>{t.transactionType}</s.Price>
                        </s.Item>
                      </s.ItemCol>
                      <s.ItemCol>
                        <s.Price>{typeof t.fee === 'number' ? toCurrency(t.fee, t.currency) : '-'}</s.Price>
                      </s.ItemCol>
                      <s.ItemCol>
                        <s.Item className="mt-1">
                          <s.Price>
                            {
                              getDateFormat({
                                time: t.paymentDate,
                                showDaySuffix: false,
                                showFullYear: true,
                                showShortMonth: false,
                                showComma: false,
                              }).date
                            }
                          </s.Price>
                        </s.Item>
                      </s.ItemCol>
                    </s.ItemRow>
                  </s.Item>
                ))}
            </s.ItemList>
          </s.Card>
        </LpBox>
      </Content>
      {downloadedData ? (
        <CSVLink ref={csvRef} data={downloadedData} target="_self" filename={Date.now() + '.csv'} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PayoutDetails;
