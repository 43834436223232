import styled from '@emotion/styled';

export const ResetBtn = styled.button`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 150px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 0 9px;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const InlineBtn = styled(ResetBtn)`
  display: inline-flex;
  width: auto;
  min-width: 150px;
  padding: 12px 10px 9px;
`;

export const BtnSpinner = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  padding-top: 1px;
`;

export const EditLink = styled.button`
  background-color: #fff;
  color: #4b4eae;
  border: none;
  display: inline-block;
  width: auto;
  letter-spacing: 0.35px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 15px;
  text-decoration: underline;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 129px;
`;

export const InputWrapper = styled.div<{ valid?: boolean }>`
  margin-top: 15px;
  margin-bottom: 15px;
  .PhoneInputInput {
    padding: 17px 17px 12px;
    max-height: 44px;
    outline: none;
    background: #fff;
    border: ${({ valid }) => (valid ? '1px solid #dce3eb' : '1px solid red')};
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  }
  .invalid-phone-no {
    .PhoneInputInput {
      border: 1px solid red;
    }
  }
  .PhoneInputCountry {
    background: #f8f9fb;
    width: 85px;
    margin: 0;
    padding: 0 12px;
    border: 1px solid #dce3eb;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .PhoneInputCountrySelectArrow {
    width: 6px;
    height: 6px;
    padding-left: 0px;
    position: absolute;
    right: 21px;
    opacity: 1;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }
`;

export const Strong = styled.strong`
  font-weight: 600;
  font-family: AcuminProMedium;
`;
