import { AccountSettingsAPIResponse, PaymentPlanLimitAPIResponse, PublicInfoAPIResponse } from './types';

export const getInitialPublicInfoValues = (): PublicInfoAPIResponse => ({
  businessName: '',
  brandName: '',
  website: '',
  cardStatementName: '',
  supportSite: '',
  contactEmail: '',
  supportEmail: '',
  phoneNo: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  state: '',
});

export const getInitialPaymentPlanLimitValues = (): PaymentPlanLimitAPIResponse => ({
  maxPayPlanAmountUnconfirmedCustomers: 0,
  maxPayPlanAmountConfirmedCustomers: 0,
  minPlanLimit: 0,
  currency: 'AUD',
});

export const getInitialAccountSettingsValues = (): AccountSettingsAPIResponse => ({
  accountName: '',
  accountAddress: '',
});
