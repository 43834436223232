import Message from 'Components/Message';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import RadioButtons from './RadioButtons';
import { EditBtn, EditFormRow, EditLabel, SpinnerWrapper } from './styles';
import { GeneralSettingsAPIResponse } from './types';

const GeneralSettings = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
  const [values, setValues] = useState<GeneralSettingsAPIResponse | null>(null);
  const [localValues, setLocalValues] = useState<GeneralSettingsAPIResponse | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [time, setTime] = useState<number>(0);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.activeMerchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchSettings = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/merchant-general-settings`;
      const options = await getFetchOptions();

      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          setValues(response);
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchSettings();
  }, [apiBaseUri, merchantId, time]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!apiBaseUri || !merchantId) {
      return;
    }

    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/merchant-general-settings`;
      const options = await getFetchOptions('POST', JSON.stringify(localValues));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setShowModal(false);
      setBodyOverflow('auto');
      setTime(Date.now());
    } catch (e) {
      setModalErrorMsg(e.message);
      setShowErrorMsg(true);
    } finally {
      setModalIsLoading(false);
    }
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setLocalValues(values);
    setBodyOverflow('hidden');
    setModalIsLoading(false);
    setModalErrorMsg('');
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowErrorMsg(false);
  };

  const handleCloseModalMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setModalErrorMsg('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { type, name, value } = e.target;

    if (type === 'radio') {
      const result = value === 'false' ? false : true;
      setLocalValues((prev) => ({ ...prev, [name]: result }));
    } else if (type === 'text') {
      const result = value.length > 0 ? Number(value) : null;
      setLocalValues((prev) => ({ ...prev, [name]: result }));
    }
  };

  return (
    <LpBox>
      <Row>
        <div className="col-sm-7">
          <H2 className="mb-0">General Settings</H2>
        </div>
        <div className="col-sm-5 text-right">
          {errorMsg.length === 0 && <EditBtn onClick={toggleModal}>Edit general settings</EditBtn>}
        </div>
      </Row>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {!isLoading && errorMsg.length > 0 && showErrorMsg && (
        <Message className="mt-2 mb-0" description={errorMsg} success={false} handleClose={handleCloseMsg} />
      )}
      {!isLoading && values && (
        <LpDetails>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Merchant id</Label>
            </div>
            <div className="col-sm-8">{values.merchantId}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Hide limepay logo in terms page</Label>
            </div>
            <div className="col-sm-8">{String(values.hideLimepayLogoInTermsPage)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Hide internal order id from customer</Label>
            </div>
            <div className="col-sm-8">{String(values.hideInternalOrderIdFromCustomer)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Perform KYC</Label>
            </div>
            <div className="col-sm-8">{String(values.performKyc)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Is B2B</Label>
            </div>
            <div className="col-sm-8">{String(values.isB2B)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Always request 3DS</Label>
            </div>
            <div className="col-sm-8">{String(values.alwaysRequest3DS)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Manual Verification enabled</Label>
            </div>
            <div className="col-sm-8">{String(values.sourceVerificationEnabled)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Display customer saved payments in checkout</Label>
            </div>
            <div className="col-sm-8">{String(values.displayCustomerSavedPaymentMethodsInCheckout)}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>KYC limit without document</Label>
            </div>
            <div className="col-sm-8">{values.kycLimitWithoutDocument}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>KYC limit with driver licence</Label>
            </div>
            <div className="col-sm-8">{values.kycLimitWithDriversLicence}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>KYC limit with passport</Label>
            </div>
            <div className="col-sm-8">{values.kycLimitWithPassport}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>KYC limit customer links</Label>
            </div>
            <div className="col-sm-8">{values.kycLimitCustomerLinks}</div>
          </Row>

          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Limit unconfirmed allowed active pay plans</Label>
            </div>
            <div className="col-sm-8">{values.limitUnconfirmedAllowedActivePayPlans}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Limit confirmed allowed active pay plans</Label>
            </div>
            <div className="col-sm-8">{values.limitConfirmedAllowedActivePayPlans}</div>
          </Row>
          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Pay deferral checkout max limit override</Label>
            </div>
            <div className="col-sm-8">{values.payDeferralCheckoutMaxLimitOverride ?? 'Not set'}</div>
          </Row>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit general settings"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          disableConfirmBtn={modalIsLoading}
          isLoading={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && (
            <Message
              className="mt-2 mb-0"
              success={false}
              description={modalErrorMsg}
              handleClose={handleCloseModalMsg}
            />
          )}
          <LpDetails className="pt-2 pb-0">
            <EditFormRow className="mt-2">
              <EditLabel>Hide limepay logo in terms page</EditLabel>
              <RadioButtons
                name="hideLimepayLogoInTermsPage"
                value={localValues?.hideLimepayLogoInTermsPage}
                handleChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Hide internal order id from customer</EditLabel>
              <RadioButtons
                name="hideInternalOrderIdFromCustomer"
                value={localValues?.hideInternalOrderIdFromCustomer}
                handleChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Perform KYC</EditLabel>
              <RadioButtons name="performKyc" value={localValues?.performKyc} handleChange={handleChange} />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Is B2B</EditLabel>
              <RadioButtons name="isB2B" value={localValues?.isB2B} handleChange={handleChange} />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Always request 3DS</EditLabel>
              <RadioButtons name="alwaysRequest3DS" value={localValues?.alwaysRequest3DS} handleChange={handleChange} />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Enable Manual Verification</EditLabel>
              <RadioButtons
                name="sourceVerificationEnabled"
                value={localValues?.sourceVerificationEnabled}
                handleChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow>
              <EditLabel>Display customer saved payments in checkout</EditLabel>
              <RadioButtons
                name="displayCustomerSavedPaymentMethodsInCheckout"
                value={localValues?.displayCustomerSavedPaymentMethodsInCheckout}
                handleChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>KYC limit without document</EditLabel>
              <Input
                name="kycLimitWithoutDocument"
                value={localValues?.kycLimitWithoutDocument ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>KYC limit with driver licence</EditLabel>
              <Input
                name="kycLimitWithDriversLicence"
                value={localValues?.kycLimitWithDriversLicence ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>KYC limit with passport</EditLabel>
              <Input
                name="kycLimitWithPassport"
                value={localValues?.kycLimitWithPassport ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>KYC limit customer links</EditLabel>
              <Input
                name="kycLimitCustomerLinks"
                value={localValues?.kycLimitCustomerLinks ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>

            <EditFormRow className="mt-2">
              <EditLabel>Limit unconfirmed allowed active pay plans</EditLabel>
              <Input
                name="limitUnconfirmedAllowedActivePayPlans"
                value={localValues?.limitUnconfirmedAllowedActivePayPlans ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>Limit confirmed allowed active pay plans</EditLabel>
              <Input
                name="limitConfirmedAllowedActivePayPlans"
                value={localValues?.limitConfirmedAllowedActivePayPlans ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
            <EditFormRow className="mt-2">
              <EditLabel>Pay deferral checkout max limit override</EditLabel>
              <Input
                name="payDeferralCheckoutMaxLimitOverride"
                value={localValues?.payDeferralCheckoutMaxLimitOverride ?? ''}
                onChange={handleChange}
              />
            </EditFormRow>
          </LpDetails>
        </Modal>
      )}
    </LpBox>
  );
};

export default GeneralSettings;
