import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, ReactElement, useState } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import * as s from './OrderList.styles';
import { ITEM_ROWS_OBJ } from './itemRowsObj';
import { TransactionAPIResponseType } from './types';

type Props = {
  orders: TransactionAPIResponseType[];
  setDetailsView(d: boolean): void;
  setActiveTransactionId(id: string): void;
};

const OrderList = ({ orders, setDetailsView, setActiveTransactionId }: Props): ReactElement => {
  const [activeOrderId, setActiveOrderId] = useState<string>('');

  const handleClickOutside = () => {
    setActiveOrderId('');
  };

  const handleViewDetails = (e: MouseEvent<HTMLButtonElement>, id: string): void => {
    e.preventDefault();
    setDetailsView(true);
    setActiveTransactionId(id);
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'item-actions-list-link', 'dots-icon']);

  return (
    <Content className="order-list-transactions payments-list">
      <LpBox>
        <s.Card>
          <s.ItemList>
            <s.Item>
              <s.ItemRow>
                {ITEM_ROWS_OBJ.map((row, rowIndex) => (
                  <s.ItemCol key={rowIndex} className={`item-col-header item-col-title ${row.class}`}>
                    <s.SpanText>{row.text}</s.SpanText>
                  </s.ItemCol>
                ))}
              </s.ItemRow>
            </s.Item>
            {orders.map((order, orderIndex) => (
              <s.Item key={orderIndex}>
                <s.ItemRow>
                  <s.ItemCol className="item-col-order">
                    <s.Media>
                      <s.FeatureIcon>
                        <OrderIdIcon />
                      </s.FeatureIcon>
                      <s.InternalOrderId onClick={(e) => handleViewDetails(e, order.transactionId)}>
                        {order.merchantOrderInternalOrderId || order.merchantOrderId}
                      </s.InternalOrderId>
                    </s.Media>
                  </s.ItemCol>
                  <s.ItemCol className="item-col-lgr">
                    <div className="item text-left mt-1">
                      <s.Price>{!!order.transactionPayType.payPlanId ? 'Split payment' : 'Full payment'}</s.Price>
                    </div>
                  </s.ItemCol>
                  <s.ItemCol className="item-col-amount">
                    <div className="item text-left mt-1">
                      <s.Price>
                        {typeof order.transactionAmount === 'number'
                          ? toCurrency(order.transactionAmount, order.currency)
                          : '-'}
                      </s.Price>
                    </div>
                  </s.ItemCol>
                  <s.ItemCol className="item-col-xsr">
                    <div className="item text-left mt-1">
                      <s.Price>{getDateFormat({ time: order.createdAt }).date}</s.Price>
                    </div>
                  </s.ItemCol>
                  <s.ItemCol className=" item-col-xsr item-col-status">
                    <s.Item className="text-left mt-1">
                      {!!order.transactionPayType.payPlanId && (
                        <s.Price className={`${order.payPlanState?.toLowerCase()}`}>{order.payPlanState}</s.Price>
                      )}
                      {!order.transactionPayType.payPlanId && (
                        <s.Price
                          className={`${
                            order.transactionStatus === 'partially refunded'
                              ? 'partial'
                              : order.transactionStatus === 'refunded'
                              ? 'refunded'
                              : 'paid'
                          } `}
                        >
                          {order.transactionStatus}
                        </s.Price>
                      )}
                    </s.Item>
                  </s.ItemCol>
                  <s.ItemCol className="fixed item-col-actions-dropdown">
                    <s.ItemActionsDropdown className="item-actions-dropdown">
                      <s.IconWrapper onClick={() => setActiveOrderId(order.merchantOrderId)}>
                        <DotsIcon className="dots-icon" />
                      </s.IconWrapper>
                      <s.ItemActionsBlock
                        className={`item-actions-block ${activeOrderId === order.merchantOrderId ? 'active' : ''}`}
                      >
                        <s.ItemActionsList className="item-actions-list">
                          <s.ItemActionsLi>
                            <s.ItemActionsListBtn
                              className="item-actions-list-link"
                              onClick={(e) => handleViewDetails(e, order.transactionId)}
                            >
                              View details
                            </s.ItemActionsListBtn>
                          </s.ItemActionsLi>
                        </s.ItemActionsList>
                      </s.ItemActionsBlock>
                    </s.ItemActionsDropdown>
                  </s.ItemCol>
                </s.ItemRow>
              </s.Item>
            ))}
          </s.ItemList>
        </s.Card>
      </LpBox>
    </Content>
  );
};

export default OrderList;
