import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { TransactionAPIResponseType } from 'Components/Transactions/types';
import { H2, Label, LpBox, Row } from 'Constants/styles';
import { MouseEvent, useEffect, useState } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import * as s from './DisputeDetails.styles';
import MerchantProfile from './MerchantProfile';
import { DisputeDetailsType } from './types';

const DisputeDetails = ({ apiBaseUri, merchantId, txId }: DisputeDetailsType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [payment, setPayment] = useState<TransactionAPIResponseType | null>(null);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchPayment = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?transactionIds=${txId}`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json = await res.json();
        setPayment(json?.[0] || null);
        setErrorMsg('');
        setShowError(false);
      } catch (e) {
        setErrorMsg(e.message || 'Failed to fetch dispute plan');
        setShowError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPayment();
  }, [apiBaseUri, merchantId, txId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowError(false);
  };

  return (
    <>
      <MerchantProfile apiBaseUri={apiBaseUri} merchantId={merchantId} />
      <s.Content>
        <LpBox>
          <H2>Transaction details</H2>
          {showError && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && errorMsg.length === 0 && (
            <>
              <Row className="mt-4">
                <Label className="col-3">Transaction Id</Label>
                <div className="col-9">{payment?.transactionId}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Transaction type</Label>
                <div className="col-9">
                  {payment?.transactionPayType.payCardId && 'Full payment'}
                  {payment?.transactionPayType.payPlanId && 'Split payment'}
                </div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Transaction amount</Label>
                <div className="col-9">{payment ? toCurrency(payment.transactionAmount, payment.currency) : '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Currency</Label>
                <div className="col-9">{payment?.currency}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Transaction status</Label>
                <div className="col-9">{payment?.transactionStatus}</div>
              </Row>
              {payment?.transactionPayType.payPlanId && (
                <Row className="mt-4">
                  <Label className="col-3">Pay plan state</Label>
                  <div className="col-9">{payment?.payPlanState}</div>
                </Row>
              )}
              <Row className="mt-4">
                <Label className="col-3">Payment source brand</Label>
                <div className="col-9">{payment?.paymentSourceBrand ?? '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Payment source last4</Label>
                <div className="col-9">{payment?.paymentSourceLast4 ?? '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Platform fee</Label>
                <div className="col-9">{payment ? toCurrency(payment.platformFee, payment.currency) : '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Refunded amount</Label>
                <div className="col-9">{payment ? toCurrency(payment.refundedAmount, payment.currency) : '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Late fees paid</Label>
                <div className="col-9">{payment ? toCurrency(payment.payPlanLateFeesPaid, payment.currency) : '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Late fees accrued</Label>
                <div className="col-9">
                  {payment ? toCurrency(payment.payPlanLateFeesAccrued, payment.currency) : '-'}
                </div>
              </Row>
              {payment?.transactionPayType.payPlanId && (
                <Row className="mt-4">
                  <Label className="col-3">Initial payment amount</Label>
                  <div className="col-9">
                    {payment ? toCurrency(payment.payPlanInitialPaymentAmount, payment.currency) : '-'}
                  </div>
                </Row>
              )}
              {payment?.transactionPayType.payPlanId && (
                <Row className="mt-4">
                  <Label className="col-3">Scheduled instalments</Label>
                  <div className="col-9">
                    {payment.payPlanScheduledInstalments.map((instalment, index) => (
                      <div key={index}>
                        <span>{toCurrency(instalment.amount, payment.currency)}</span>
                        <span className="ml-2">
                          {
                            getDateFormat({
                              time: instalment.dueAt,
                              showComma: false,
                              showFullYear: true,
                              showDaySuffix: false,
                            }).date
                          }
                        </span>
                      </div>
                    ))}
                  </div>
                </Row>
              )}
              <Row className="mt-4">
                <Label className="col-3">Billing address</Label>
                <div className="col-9">
                  <div>{payment?.merchantOrderBilling?.address.line1}</div>
                  <div>{payment?.merchantOrderBilling?.address.line2}</div>
                  <span className="mr-1">{payment?.merchantOrderBilling?.address.city}</span>
                  <span className="mr-1">{payment?.merchantOrderBilling?.address.state}</span>
                  <span className="mr-1">{payment?.merchantOrderBilling?.address.postalCode}</span>
                </div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Shipping address</Label>
                <div className="col-9">
                  <div>{payment?.merchantOrderShipping?.address.line1}</div>
                  <div>{payment?.merchantOrderShipping?.address.line2}</div>
                  <span className="mr-1">{payment?.merchantOrderShipping?.address.city}</span>
                  <span className="mr-1">{payment?.merchantOrderShipping?.address.state}</span>
                  <span className="mr-1">{payment?.merchantOrderShipping?.address.postalCode}</span>
                </div>
              </Row>
            </>
          )}
        </LpBox>
        <LpBox>
          <H2>Customer details</H2>
          {showError && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && errorMsg.length === 0 && (
            <>
              <Row className="mt-4">
                <Label className="col-3">Customer Id</Label>
                <div className="col-9">{payment?.customerId}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Full name</Label>
                <div className="col-9">{payment?.merchantOrderBilling?.name || payment?.customerFullName || '-'}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Email</Label>
                <div className="col-9">{payment?.customerEmail}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Phone number</Label>
                <div className="col-9">{payment?.customerPhoneNumber}</div>
              </Row>
            </>
          )}
        </LpBox>
        <LpBox>
          <H2>Payout details</H2>
          {showError && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && errorMsg.length === 0 && (
            <>
              <Row className="mt-4">
                <Label className="col-3">Payout Id</Label>
                <div className="col-9">{payment?.payoutId}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Payout status</Label>
                <div className="col-9">{payment?.payoutStatus}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Payout arrived at</Label>
                <div className="col-9">
                  {
                    getDateFormat({
                      time: payment?.payoutArrivalAt ?? '',
                      showFullYear: true,
                      showComma: false,
                      showDaySuffix: false,
                    }).formatted
                  }
                </div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Payout amount</Label>
                <div className="col-9">{payment ? toCurrency(payment.payoutAmount, payment.currency) : '-'}</div>
              </Row>
            </>
          )}
        </LpBox>
      </s.Content>
    </>
  );
};

export default DisputeDetails;
