import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
`;

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 0;
  line-height: 22.4px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 0 8px;
  border-top: 1px solid #e6e6eaee;
  &:first-of-type,
  &:nth-of-type(2) {
    border: 0;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 100%;
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
`;

export const FeatureIcon = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  min-width: 36px;
`;

export const InternalOrderId = styled.button`
  font-size: 16px;
  font-weight: bold;
  color: var(--lp-colors-medium-blue-600);
  font-size: 13px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const SpanText = styled.span`
  font-size: 15px;
  color: #6c7488;
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 10px 10px 0;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: auto;
  min-width: 0;
  height: 100%;
  box-sizing: border-box;
  &.item-col-header {
    padding: 4px 0;
    height: 31px;
  }
  &.item-col-title {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    flex-basis: 0;
    flex-grow: 3;
  }
  &.item-col-cxl {
    max-width: 180px;
  }
  &.item-col-lgr {
    max-width: 250px;
  }
  &.fixed {
    position: absolute;
    right: 0;
    width: 30px;
  }
  &.item-col-actions-dropdown {
    padding-right: 0;
    width: 15px;
  }
  @media (max-width: 1400px) {
    padding-right: 0;
  }
`;

export const Price = styled.div`
  margin-bottom: 0;
  font-family: 'Acumin Regular';
  font-size: 13px;
  line-height: 20px;
  color: #354052;
  &.paid,
  &.created,
  &.active,
  &.completed {
    background-color: rgba(39, 203, 204, 0.2);
    color: #017f7f;
    padding: 6px 15px 1px 15px;
    text-align: center;
    text-transform: uppercase;
  }
  &.partial {
    background-color: rgba(39, 203, 204, 0.2);
    color: #017f7f;
    padding: 6px 15px 1px 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
  }
  &.refunded,
  &.indefault {
    background-color: rgba(237, 132, 65, 0.2);
    color: #ed8441;
    padding: 6px 15px 1px 15px;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const ItemActionsDropdown = styled.div`
  position: relative;
  display: inline-block;
  font-size: 17.6px;
  top: 5px;
`;

export const ItemActionsBlock = styled.div`
  display: none;
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 24px;
  padding: 15px 20px;
  border: 1px #e7e7e7 solid;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  text-align: left;
  z-index: 1;
  &.active {
    display: block;
    min-width: 238px;
    max-width: 450px;
  }
`;

export const ItemActionsList = styled.ul`
  list-style: none;
  white-space: nowrap;
  padding: 0;
`;

export const ItemActionsLi = styled.li`
  padding: 3px 0;
  margin: 0;
  &:first-of-type {
    border: 0;
  }
`;

export const ItemActionsListBtn = styled.button`
  color: #6c7488;
  display: block;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  background-color: transparent;
  cursor: pointer;
  border: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
