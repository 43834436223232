export type CardType = {
  cardFunding: string;
  cardBrand: string;
  perc: string;
  fixed: string;
  time: number;
};

export type ApiRequestedCardType = {
  cardFunding: string;
  cardBrand: string;
  perc: number;
  fixed: number;
};

export const defaultCardValue = {
  cardFunding: 'credit',
  cardBrand: '',
  perc: '',
  fixed: '',
  time: 0,
};

export enum ProfileType {
  standard = '',
  MarketNowZeroFees = 'realpayZeroFees',
  MarketNowNewFees = 'realpayNewFees',
}

export type PaymentType = 'payPlan' | 'payCard';
export type CountryType = 'domestic' | 'international';

export type PayPlanLimitType = {
  maxPayPlanAmountUnconfirmedCustomers: number;
  maxPayPlanAmountConfirmedCustomers: number;
  minPlanLimit: number;
};

export enum TaxCountryType {
  AU = 'AU',
  NZ = 'NZ',
}

export enum CalculateTaxOnFeeType {
  TRUE = 'true',
  FALSE = 'false',
}

export enum PaymentProfileEnum {
  abacusWorldpayAu = 'abacusWorldpayAu',
  abacusWorldpayNz = 'abacusWorldpayNz',
  abacusWorldpayStripeCompositeAu = 'abacusWorldpayStripeCompositeAu',
  abacusWorldpayStripeCompositeNz = 'abacusWorldpayStripeCompositeNz',
  stripeConnectAuAbacus = 'stripeConnectAuAbacus',
  stripeConnectNzAbacus = 'stripeConnectNzAbacus',
  abacusCheckout = 'abacusCheckout',
  abacusCheckoutStripeComposite = 'abacusCheckoutStripeComposite',
}
