import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
`;

export const ItemList = styled.div`
  margin-bottom: 0;
  line-height: 22.4px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 0 8px;
  border-top: 1px solid #e6e6eaee;
  &:first-of-type,
  &:nth-of-type(2) {
    border: 0;
  }
  &.active-item {
    padding: 20px 0;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 100%;
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 10px 10px 0;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: auto;
  height: 31px;
  box-sizing: border-box;
  width: 250px;
  overflow-wrap: anywhere;
  &.fixed {
    width: 30px;
    position: absolute;
    right: 0;
    padding: 0;
  }
  .header-title {
    color: #6c7488;
  }
`;

export const Price = styled.div`
  margin-bottom: 0;
  font-size: 13px;
  line-height: 20px;
  color: #354052;
`;

export const ItemActionsDropdown = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 18px;
  top: 3px;
  width: 100%;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const ItemActionsListLink = styled.button`
  color: #6c7488;
  display: block;
  font-size: 15px;
  line-height: 24px;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  border: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const ItemActionsBlock = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 24px;
  padding: 15px 20px;
  border: 1px #e7e7e7 solid;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  text-align: left;
  z-index: 1;
  min-width: 200px;
`;

export const OrderIdBtn = styled.button`
  color: var(--lp-colors-medium-blue-600);
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
`;

export const FeatureIcon = styled.div`
  border: 1px solid #989fb2;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemsSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
`;
