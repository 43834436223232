import BackComponent from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message/';
import OrderSearch, { SearchOptionType } from 'Components/OrderSearch/OrderSearch';
import { OrderSearchOptions } from 'Components/OrderSearch/OrderSearchOptions';
import { OrderSearchType } from 'Components/OrderSearch/OrderSearchOptions';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox } from 'Constants/styles';
import qs from 'query-string';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fromCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import CustomerDetails from './CustomerDetails';
import MerchantProfile from './MerchantProfile';
import OrderList from './OrderList';
import TransactionDetails from './TransactionDetails';
import TransactionHistory from './TransactionHistory';
import * as s from './styles';
import { APIResponseType } from './types';

const SearchOptionObj = {
  [OrderSearchType.order]: 'internalOrderId',
  [OrderSearchType.email]: 'email',
  [OrderSearchType.amount]: 'amount',
};

const OverduePaymentPlans = (): ReactElement => {
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [orders, setOrders] = useState<APIResponseType[]>([]);
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [activePlan, setActivePlan] = useState<APIResponseType | null>(null);
  const [showAllPlans, setShowAllPlans] = useState<boolean>(true);

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { apiBaseUri } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
  }));

  /** merchantId is from url parameter
   *  activeMerchantId is from overdue request payload
   */
  const { merchantId, merchantName } = qs.parse(window.location.search);

  useEffect(() => {
    setShowAllPlans(!merchantId || !merchantName);
  }, [merchantId, merchantName]);

  useEffect(() => {
    if (!apiBaseUri || debouncedSearch !== selectedSearch) {
      return;
    }

    const fetchOverduePlans = async () => {
      setLoading(true);
      let url = `${apiBaseUri}/admin/reports/overdue-payments?sort=-createdAt&page=${activePage}`;
      if (!showAllPlans) {
        url += `&merchantId=${merchantId}`;
      }
      if (debouncedSearch.length > 0) {
        let tempSearch: number | string = debouncedSearch;
        if (selectedSearchOption.value === OrderSearchType.amount) {
          tempSearch = fromCurrency(tempSearch.replace(',', ''));
        }
        url += `&${SearchOptionObj[selectedSearchOption.value as OrderSearchType]}=${encodeURIComponent(tempSearch)}`;
      }
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('limepay-page-count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setErrorMsg('');
          setPageCount(pages);
          setOrders(response);
        })
        .catch((error) => {
          setErrorMsg(error.message || 'Failed to fetch overdue plans');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchOverduePlans();
  }, [
    apiBaseUri,
    activePage,
    merchantId,
    merchantName,
    debouncedSearch,
    selectedSearchOption.value,
    showAllPlans,
    selectedSearch,
  ]);

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
  };

  const handleCloseMessage = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowDetails(false);
    setActivePlan(null);
  };

  return (
    <div>
      {!showDetails && (
        <>
          <s.HeaderWrapper>
            <HeaderTitle title="Overdue Payment Plans" />
            <OrderSearch
              selectedSearchOption={selectedSearchOption}
              setSelectedSearchOption={setSelectedSearchOption}
              selectedSearch={selectedSearch}
              handleSearch={handleSearch}
            />
          </s.HeaderWrapper>
          {!showAllPlans && <MerchantProfile merchantId={merchantId as string} name={merchantName as string} />}
          {loading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {!loading && (
            <Content className={`${showAllPlans ? 'mt-4' : ''}`}>
              <LpBox>
                {errorMsg.length > 0 && (
                  <Message success={false} description={errorMsg} handleClose={handleCloseMessage} />
                )}
                {orders.length > 0 && (
                  <OrderList orders={orders} setShowDetails={setShowDetails} setActivePlan={setActivePlan} />
                )}
                {orders.length === 0 && <EmptyList />}
              </LpBox>
            </Content>
          )}
          {!loading && (
            <Pagination
              pageCount={pageCount}
              activePage={activePage}
              setActivePage={setActivePage}
              pageStartIndex={pageStartIndex}
              setPageStartIndex={setPageStartIndex}
            />
          )}
        </>
      )}
      {showDetails && (
        <div>
          <HeaderTitle title="Overdue Payment Plans Details" />
          <BackComponent text="Back to overdue payment plans" handleClickBack={handleClickBack} />
          <MerchantProfile
            apiBaseUri={apiBaseUri}
            merchantId={(merchantId as string) || activePlan?.merchantId || ''}
            name={merchantName as string}
          />
          <CustomerDetails apiBaseUri={apiBaseUri} customerId={activePlan?.customerId ?? ''} />
          <TransactionDetails
            apiBaseUri={apiBaseUri}
            transactionId={activePlan?.transactionId ?? ''}
            merchantId={(merchantId as string) || activePlan?.merchantId || ''}
          />
          <TransactionHistory
            ledgerEntries={activePlan?.ledger?.entries ?? []}
            chargesEntries={activePlan?.charges?.entries ?? []}
            currency={activePlan?.currency ?? 'AUD'}
          />
        </div>
      )}
    </div>
  );
};

export default OverduePaymentPlans;
