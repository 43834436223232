import styled from '@emotion/styled';

export const OrderIdBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--lp-colors-medium-blue-600);
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const SpinnerWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchWrapper = styled.div`
  width: 220px;
  position: absolute;
  top: 30px;
  right: 60px;
`;

export const ResendBtn = styled.button`
  padding: 0 20px;
  margin: 0;
  background-color: var(--lp-colors-medium-blue-800);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-800);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 56%);
  letter-spacing: 0.35px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  user-select: none;
  height: 40px;
  min-width: 205px;

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
`;

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  line-height: 22px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const Item = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6eaee;
  padding: 8px 0;

  &:first-child,
  &:nth-child(2) {
    border: 0;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 100%;
  position: relative;
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 0px;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: 0;
  min-width: 0;

  &.item-col-lgr {
    max-width: 250px;
  }
  &.item-col-header span {
    font-size: 15px;
    color: #6c7488;
  }
  &.custom-col-xsr {
    max-width: 335px;

    .item {
      width: 100%;
    }
  }
`;

export const OrderId = styled.div`
  font-size: 13px;
  padding-top: 10px;
  font-weight: bold;
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FeatureIcon = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;
