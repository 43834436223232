import { Input } from 'Constants/styles';
import React, { ChangeEvent, ReactElement } from 'react';

import { FieldWrapper, FixedField, FixedInput, PercentageField, Plus } from './styles';
import { CountryType, PaymentType } from './types';

type PercentageAndFixedType = {
  payment: PaymentType;
  country: CountryType;
  percent: string;
  fixed: string;
  handleChangePercent(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType): void;
  handleChangeFix(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType): void;
};

const PercentageAndFixed = ({
  payment,
  country,
  percent,
  fixed,
  handleChangePercent,
  handleChangeFix,
}: PercentageAndFixedType): ReactElement => (
  <FieldWrapper>
    <PercentageField>
      <Input
        type="text"
        required
        data-testid={`${payment}-${country}-percent`}
        value={percent}
        onChange={(e) => handleChangePercent(e, payment, country)}
      />
    </PercentageField>
    <Plus>+</Plus>
    <FixedField>
      <FixedInput
        type="text"
        required
        data-testid={`${payment}-${country}-fixed`}
        value={fixed}
        onChange={(e) => handleChangeFix(e, payment, country)}
      />
    </FixedField>
  </FieldWrapper>
);

export default PercentageAndFixed;
