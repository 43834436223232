import { CardType, ProfileType } from './types';

export const getCardValueByProfile = (profile: ProfileType): CardType[] => {
  const time = Date.now();
  switch (profile) {
    case ProfileType.standard:
      return [{ cardFunding: 'credit', cardBrand: 'American Express', perc: '1.9', fixed: '0.3', time }];

    case ProfileType.MarketNowZeroFees:
      return [];

    case ProfileType.MarketNowNewFees:
      return [
        { cardFunding: 'debit', cardBrand: 'MasterCard', perc: '1', fixed: '0', time },
        { cardFunding: 'credit', cardBrand: 'MasterCard', perc: '1.44', fixed: '0', time: time + 1 },
        { cardFunding: 'debit', cardBrand: 'Visa', perc: '0.79', fixed: '0', time: time + 2 },
        { cardFunding: 'credit', cardBrand: 'Visa', perc: '1.11', fixed: '0', time: time + 3 },
        { cardFunding: 'credit', cardBrand: 'American Express', perc: '1.88', fixed: '0', time: time + 4 },
        { cardFunding: 'debit', cardBrand: 'American Express', perc: '1.88', fixed: '0', time: time + 5 },
      ];
  }
};

export const getPayPlanLimitByProfile = (profile: ProfileType) => {
  if (profile === ProfileType.standard) {
    return {
      maxPayPlanAmountUnconfirmedCustomers: 1200,
      maxPayPlanAmountConfirmedCustomers: 1200,
      minPlanLimit: 5,
    };
  } else {
    return {
      maxPayPlanAmountUnconfirmedCustomers: 1200,
      maxPayPlanAmountConfirmedCustomers: 1200,
      minPlanLimit: 5,
    };
  }
};
