import { firebaseApp } from './firebase';

export const login = async (tenantId: string, email: string, password: string) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.setPersistence('session');
  return firebaseAuth.signInWithEmailAndPassword(email, password);
};

export const logout = async () => {
  const firebaseAuth = firebaseApp.auth();
  return firebaseAuth.signOut();
};
