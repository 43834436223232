export const ListHeaders = [
  {
    text: 'Order ID',
  },
  {
    text: 'Customer email',
  },
  {
    text: 'Type',
  },
  {
    text: 'Amount',
  },
  {
    text: 'Time',
  },
];
