import styled from '@emotion/styled';

export const TitleText = styled.h1`
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  color: #354052;
  @media (max-width: 768px) {
    font-size: 21px;
  }
`;

export const Wrapper = styled.header`
  &.lp-dashboard-top {
    padding: 33px 60px 12px;
    position: relative;

    .title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
      margin: 0;
      color: #354052;
    }
  }
`;
