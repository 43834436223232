import { H5, Input, Label } from 'Constants/styles';
import React, { ChangeEvent, MouseEvent, ReactElement } from 'react';

import {
  AddCardBtn,
  CardNumber,
  CardWrapper,
  DeleteCardBtn,
  FieldWrapper,
  FixedField,
  FixedInput,
  FormControl,
  PercentageField,
  Red,
} from './styles';
import { CardTimeType, CountryType, PaymentType } from './types';

type EditFeeProps = {
  label: string;
  payment: PaymentType;
  country: CountryType;
  percent: string;
  fixed: string;
  handleChangePercent(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType): void;
  handleChangeFix(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType): void;
  cards: CardTimeType[];
  handleDeleteCard(e: MouseEvent<HTMLButtonElement>, p: PaymentType, c: CountryType, t: number): void;
  handleAddCard(e: MouseEvent<HTMLButtonElement>, p: PaymentType, c: CountryType): void;
  handleChangeCardFunding(e: ChangeEvent<HTMLSelectElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardBrand(e: ChangeEvent<HTMLSelectElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardPercent(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardFix(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType, t: number): void;
};

const EditFee = ({
  label,
  payment,
  country,
  percent,
  fixed,
  handleChangePercent,
  handleChangeFix,
  cards,
  handleDeleteCard,
  handleAddCard,
  handleChangeCardFunding,
  handleChangeCardBrand,
  handleChangeCardPercent,
  handleChangeCardFix,
}: EditFeeProps): ReactElement => {
  return (
    <>
      <H5 className="mt-3">{label}</H5>
      <FieldWrapper>
        <div className="col-6">
          <Label>Percentage (%)</Label>
          <PercentageField>
            <Input type="text" value={percent} onChange={(e) => handleChangePercent(e, payment, country)} />
          </PercentageField>
        </div>
        <div className="col-6">
          <Label>Fixed ($)</Label>
          <FixedField>
            <FixedInput type="text" value={fixed} onChange={(e) => handleChangeFix(e, payment, country)} />
          </FixedField>
        </div>
      </FieldWrapper>
      {cards.map((card, index) => (
        <CardWrapper key={card.time}>
          <CardNumber>
            <span>Card {index + 1}</span>
            <DeleteCardBtn onClick={(e) => handleDeleteCard(e, payment, country, card.time)}>Delete card</DeleteCardBtn>
          </CardNumber>
          <FieldWrapper className="mt-2">
            <div className="col-3">
              <Label>
                Funding (<Red>*</Red>)
              </Label>
              <FormControl
                value={card.cardFunding}
                onChange={(e) => handleChangeCardFunding(e, payment, country, card.time)}
              >
                <option value="">-</option>
                <option value="credit">Credit</option>
                <option value="debit">Debit</option>
              </FormControl>
            </div>
            <div className="col-3">
              <Label>Branding</Label>
              <FormControl
                value={card.cardBrand}
                onChange={(e) => handleChangeCardBrand(e, payment, country, card.time)}
              >
                <option value="">-</option>
                <option value="MasterCard">MasterCard</option>
                <option value="Visa">Visa</option>
                <option value="American Express">American Express</option>
              </FormControl>
            </div>
            <div className="col-3">
              <Label>
                percent(<Red>*</Red>)
              </Label>
              <PercentageField>
                <Input
                  type="text"
                  style={{ paddingLeft: '10px' }}
                  value={card.perc}
                  onChange={(e) => handleChangeCardPercent(e, payment, country, card.time)}
                />
              </PercentageField>
            </div>
            <div className="col-3">
              <Label>
                fixed(<Red>*</Red>)
              </Label>
              <FixedField>
                <FixedInput
                  type="text"
                  value={card.fixed}
                  onChange={(e) => handleChangeCardFix(e, payment, country, card.time)}
                />
              </FixedField>
            </div>
          </FieldWrapper>
        </CardWrapper>
      ))}
      <AddCardBtn onClick={(e) => handleAddCard(e, payment, country)}>Add a card</AddCardBtn>
    </>
  );
};

export default EditFee;
