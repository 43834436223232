import { login } from 'APIs/identity';
import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as EmailIcon } from 'assets/svg/email.svg';
import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { ReactComponent as PasswordIcon } from 'assets/svg/password.svg';
import { Location } from 'history';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions';

import * as s from './styles';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);

  const tenantId = useSelector((state: ReduxStateType) => state.tenantId);
  const location: Location<{ from?: Location } | null | undefined> = useLocation();

  useEffect(() => {
    document.title = 'Sign in - Limepay';
    setLoginPendingCB(true);
  }, [setLoginPendingCB]);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    login(tenantId, email, password)
      .then(() => {
        setHasError(false);
        let href = '/admin-dashboard/merchants';
        if (location.state?.from?.pathname) {
          href = location.state.from.pathname + location.state.from.search;
        }
        window.location.href = href;
      })
      .catch((error) => {
        setErrorMessage(error?.message || 'Login failed!');
        setHasError(true);
        setIsLoading(false);
      });
  };

  return (
    <s.Wrapper>
      <LogoIcon className="logo" />
      <div className="content">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-header">
            <h3 className="form-title">Log in to your Limepay account</h3>
            {hasError && <div className="error-bar">{errorMessage}</div>}
          </div>
          <div className="input-wrapper">
            <label className="label" htmlFor="email">
              <div className="icon-wrapper">
                <EmailIcon className="email-icon" />
              </div>
              <input
                className="input"
                type="email"
                id="email"
                autoFocus
                name="email"
                value={email}
                placeholder="EMAIL ADDRESS"
                onChange={handleChangeEmail}
                autoComplete="username"
              />
            </label>
          </div>
          <div className="input-wrapper">
            <label className="label" htmlFor="password">
              <div className="icon-wrapper">
                <PasswordIcon className="password-icon" />
              </div>
              <input
                className="input"
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="PASSWORD"
                onChange={handleChangePassword}
                autoComplete="current-password"
              />
            </label>
          </div>
          <div className="input-submit">
            <small className="small">
              <button className="btn" type="submit" disabled={email.length === 0 || password.length === 0 || isLoading}>
                Log in
                {isLoading && (
                  <s.SpinnerWrapper>
                    <Spinner />
                  </s.SpinnerWrapper>
                )}
              </button>
            </small>
          </div>
        </form>
      </div>
    </s.Wrapper>
  );
};

export default Login;
