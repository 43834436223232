import styled from '@emotion/styled';

export const ItemsSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
`;

export const SpinnerWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
