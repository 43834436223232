import { MerchantAPIResponseType } from 'Components/Companies/types';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ListHeaders } from './Constants';
import Transactions from './Transactions';
import * as s from './styles';

const MerchantsList = () => {
  /**
   * businessName is for input while searchName is for search
   * so when businessName is changed, it will not send requests
   */
  const [businessName, setBusinessName] = useState<string>('');
  const [searchName, setSearchName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const [merchantList, setMerchantList] = useState<MerchantAPIResponseType[]>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [activeMerchantId, setActiveMerchantId] = useState<string>('');
  const [now, setNow] = useState<number>(0);
  const [showTransactions, setShowTransactions] = useState<boolean>(false);

  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);
  const history = useHistory();

  useEffect(() => {
    document.title = 'Transactions - Limepay';
  }, []);

  useEffect(() => {
    if (!apiBaseUri || !searchName) {
      return;
    }

    const search = async () => {
      try {
        setLoading(true);

        const url = `${apiBaseUri}/merchants/query?sort=-createdAt&page=${activePage}&businessName=${searchName}`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        setPageCount(Number(res.headers.get('limepay-page-count')));
        setMerchantList(await res.json());
        setShowList(true);
      } catch (e) {
        setErrorMsg(e.message || 'Failed to search business name.');
      } finally {
        setLoading(false);
      }
    };
    search();
  }, [activePage, apiBaseUri, searchName, now]);

  const handleChangeBusinessName = (e: ChangeEvent<HTMLInputElement>): void => {
    setBusinessName(e.target.value);
  };

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNow(Date.now());
    setSearchName(encodeURIComponent(businessName));
  };

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, merchantId: string): void => {
    e.preventDefault();
    setActiveMerchantId(merchantId);
  };

  const handleClickOutside = () => {
    setActiveMerchantId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'dots-icon', 'link-btn']);

  const handleShowDetails = (e: MouseEvent<HTMLButtonElement>, merchant: MerchantAPIResponseType): void => {
    e.preventDefault();
    const params = `merchantId=${merchant.merchantId}&merchantName=${encodeURIComponent(merchant.businessName)}`;
    history.push(`/admin-dashboard/transactions?${params}`);
    setShowTransactions(true);
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setErrorMsg('');
  };

  if (showTransactions) {
    return <Transactions setShowTransactions={setShowTransactions} />;
  }

  return (
    <>
      <HeaderTitle title="Merchants List" />
      <Content>
        <LpBox>
          <s.Flex>
            <s.SearchWrapper className={`${businessName.length > 0 ? 'has-input' : ''}`}>
              <s.SearchLabel className="search-label">Business name</s.SearchLabel>
              <s.Input
                className="search-input"
                type="text"
                value={businessName}
                autoComplete="off"
                onChange={handleChangeBusinessName}
              />
            </s.SearchWrapper>
            <s.SearchBtn
              data-testid="search-business-btn"
              disabled={loading || businessName.length === 0}
              onClick={handleSearch}
            >
              Search
              {loading && (
                <s.BtnSpinner>
                  <Spinner width="20px" height="20px" borderWidth="2px" />
                </s.BtnSpinner>
              )}
            </s.SearchBtn>
          </s.Flex>
        </LpBox>
      </Content>

      {loading && (
        <s.SpinnerWrapper>
          <Spinner color="#0016D1" />
        </s.SpinnerWrapper>
      )}
      {errorMsg.length > 0 && (
        <s.MessageBar>
          <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />
        </s.MessageBar>
      )}

      {showList && (
        <>
          <Content>
            <LpBox>
              <s.Card>
                <s.ItemList>
                  <s.Item className="item-list-header">
                    <s.ItemRow>
                      {ListHeaders.map((header, index) => (
                        <s.ItemCol key={index} className={`item-col-header ${header.className}`}>
                          <span>{header.text}</span>
                        </s.ItemCol>
                      ))}
                    </s.ItemRow>
                  </s.Item>
                  {merchantList.length === 0 && <EmptyList />}
                  {merchantList.length > 0 &&
                    merchantList.map((merchant) => (
                      <s.Item className="active-item" key={merchant.merchantId}>
                        <s.ItemRow>
                          <s.ItemCol className="item-col-lgr">
                            <s.Media>
                              <s.FeatureIcon>
                                <UserIcon />
                              </s.FeatureIcon>
                              <s.OrderId className="order-id">
                                <s.OrderIdBtn
                                  data-testid={merchant.businessName}
                                  onClick={(e) => handleShowDetails(e, merchant)}
                                >
                                  {merchant.businessName}
                                </s.OrderIdBtn>
                              </s.OrderId>
                            </s.Media>
                          </s.ItemCol>
                          <s.ItemCol className="custom-col-xsr">
                            <s.Item className="text-left mt-1 pl-m">
                              <s.Media>
                                <div className="custom-email-truncate">
                                  <a
                                    href={
                                      merchant.website?.includes('http')
                                        ? merchant.website
                                        : `https://${merchant.website}`
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {merchant.website}
                                  </a>
                                </div>
                              </s.Media>
                            </s.Item>
                          </s.ItemCol>
                          <s.ItemCol>
                            <s.Item className="text-left mt-1 pl-m slug">{merchant.slug}</s.Item>
                          </s.ItemCol>

                          <s.ItemCol className="fixed" onClick={(e) => toggleDropdown(e, merchant.merchantId)}>
                            <DotsIcon className="dots-icon" />
                            <s.ItemActionsBlock active={activeMerchantId === merchant.merchantId}>
                              <s.DetailsLinkBtn className="link-btn" onClick={(e) => handleShowDetails(e, merchant)}>
                                List transactions
                              </s.DetailsLinkBtn>
                            </s.ItemActionsBlock>
                          </s.ItemCol>
                        </s.ItemRow>
                      </s.Item>
                    ))}
                </s.ItemList>
              </s.Card>
            </LpBox>
          </Content>
          <Pagination
            pageCount={pageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
    </>
  );
};

export default MerchantsList;
