import './OrderSearch.scss';

import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/ico-search.svg';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import { OrderSearchOptions } from './OrderSearchOptions';

export type SearchOptionType = {
  text: string;
  value: string;
};

type Props = {
  selectedSearchOption: SearchOptionType;
  setSelectedSearchOption(option: SearchOptionType): void;
  selectedSearch: string;
  handleSearch(search: string): void;
  ignore?: string[];
};

const OrderSearch = ({
  selectedSearchOption,
  setSelectedSearchOption,
  selectedSearch,
  handleSearch,
  ignore = [],
}: Props) => {
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(OrderSearchOptions);

  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (ignore.length === 0) {
      return;
    }
    const available = OrderSearchOptions.filter((option) => !ignore.includes(option.value));
    setFilteredOptions(available);
  }, [ignore]);

  const toggleSearchOptions = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowSearchOptions((prev) => !prev);
  };

  const onChangeDropdown = (e: ChangeEvent<HTMLInputElement>): void => {
    const index = filteredOptions.findIndex((option) => option.value === e.target.value) ?? 0;
    setSelectedSearchOption(filteredOptions[index]);
    handleSearch('');
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    handleSearch(e.target.value);
  };

  const handleClickOutside = () => {
    setShowSearchOptions(false);
  };

  useClickOutside(ref, handleClickOutside, ['tclick', 'option-text', 'arrow-down']);

  return (
    <div className="form-inline form-search order-search">
      <div className="input-group">
        <input
          className="lpSubmitInput form-control boxed rounded-s searchBox"
          type="text"
          name="searchQuery"
          placeholder="Search"
          value={selectedSearch}
          onChange={onChangeSearch}
        />
        <span className="input-group-btn">
          <SearchIcon />
        </span>
        <div className="nav-item down-search">
          <button className="payout-schedule tclick" onClick={toggleSearchOptions}>
            <div className="option-text">{selectedSearchOption.text}</div>
            <ArrowDownIcon className={`arrow-down ${showSearchOptions ? 'up' : ''}`} />
          </button>
          {showSearchOptions && (
            <ul className="payout-schedule-menu" ref={ref}>
              {filteredOptions.map((option) => (
                <li key={option.value}>
                  <label className="lp-radio">
                    <span className="cno">{option.text}</span>
                    <input
                      type="radio"
                      name="searchType"
                      value={option.value}
                      onChange={onChangeDropdown}
                      checked={selectedSearchOption.value === option.value}
                    />
                    <span className="check-mark" />
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSearch;
