import styled from '@emotion/styled';

export const TableHr = styled.hr`
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px solid #e0e1ea;
  margin: 27px 0;
`;

export const TableRow = styled.div`
  display: flex;
  padding: 0 20px;
  position: relative;
`;

type TableTextType = {
  width?: string;
  left?: string;
  align?: string;
  color?: string;
  weight?: number;
};

export const TableText = styled.div<TableTextType>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${(props) => props.color ?? '#6c7488'};
  width: ${(props) => props.width ?? null};
  margin-left: ${(props) => props.left ?? '0'};
  text-align: ${(props) => props.align ?? 'left'};
  font-weight: ${(props) => props.weight ?? 400};
  strong {
    font-weight: 700;
  }
`;
