import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  display: flex;
  .order-search.form-search {
    padding-top: 33px;
    margin-left: auto;
    padding-right: 60px;
  }
`;

export const NoMerchantWrapper = styled.div`
  padding: 20px 60px;
  .link {
    color: var(--lp-colors-medium-blue-600);
    margin: 0 5px;
    &:active {
      color: var(--lp-colors-medium-blue-600);
    }
  }
`;

export const SpinnerWrapper = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
