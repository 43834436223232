import { divideBy100, fromCurrency } from 'utils/currency';

import { ApiRequestedCardType, CardType } from './types';

const getFees = (percent: string, fixed: string, cards: CardType[]) => {
  const tempCards: ApiRequestedCardType[] = [];
  cards.forEach((c) => {
    const obj: ApiRequestedCardType = {
      cardBrand: c.cardBrand,
      cardFunding: c.cardFunding,
      perc: divideBy100(Number.parseFloat(c.perc)),
      fixed: fromCurrency(c.fixed),
    };
    tempCards.push({ ...obj });
  });

  return {
    perc: divideBy100(Number.parseFloat(percent)),
    fixed: fromCurrency(fixed),
    cards: tempCards,
  };
};

export default getFees;
