export const ITEM_ROWS_OBJ = [
  {
    class: 'item-col-lgr',
    text: 'Pay Plan ID',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Plan Amount',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Paid Amount',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Created Date',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Due Date',
    noOverflow: false,
  },
];

export const CHARGE_ENTRIES_ITEM_ROWS_OBJ = [
  {
    class: 'icon-status',
    text: '',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Charged Amount',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Charge Type',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Instalment Number',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Late Fee Charge',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Created At',
    noOverflow: false,
  },
];

export const LEDGER_ENTRIES_ITEM_ROWS_OBJ = [
  {
    class: 'item-col-amount',
    text: 'Amount',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Entry Type',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Reason',
    noOverflow: false,
  },
  {
    class: 'item-col-xsr',
    text: 'Entry Time',
    noOverflow: false,
  },
];
