import * as Actions from 'redux/Constants/actionTypes';

const initialState: ReduxStateType = {
  tenantId: '',
  apiBaseUri: '',
  isLoggedIn: null,
  isLoginPending: false,
  initials: '',
  showMerchantDetailsView: false,
  activeMerchantId: '',
  businessName: '',
  stripeAccountId: '',
  marketPlaceId: '',
  brandName: '',
};

const reducer = (state: ReduxStateType = initialState, { type, payload }: ActionType): ReduxStateType => {
  switch (type) {
    case Actions.UPDATE_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
      };

    case Actions.SET_LOGIN_PENDING:
      return {
        ...state,
        isLoginPending: payload.isLoginPending,
      };

    case Actions.SET_CONFIG:
      return {
        ...state,
        apiBaseUri: payload.apiBaseUri,
        tenantId: payload.tenantId,
        marketPlaceId: payload.marketPlaceId,
      };

    case Actions.SET_MERCHANT_DETAILS:
      return {
        ...state,
        showMerchantDetailsView: payload.showMerchantDetailsView,
        activeMerchantId: payload.activeMerchantId,
        businessName: payload.businessName,
        stripeAccountId: payload.stripeAccountId,
        brandName: payload.brandName,
      };

    default:
      return state;
  }
};

export default reducer;
