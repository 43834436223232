export const ListHeaders = [
  {
    text: 'Customer name',
    className: 'item-col-lgr',
  },
  {
    text: 'Customer email',
    className: 'custom-col-xsr',
  },
  {
    text: 'Merchant name',
    className: 'custom-col-lgr',
  },
];
