import './EmptyList.scss';

import React, { ReactElement } from 'react';

const EmptyList = (): ReactElement => (
  <div className="lp-notice empty-payments-list">
    <h2 className="title pb-1">No results found</h2>
  </div>
);

export default EmptyList;
