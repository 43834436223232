import styled from '@emotion/styled';

export const LinkBtn = styled.button`
  padding: 2px 0;
  background-color: transparent;
  border: none;
  color: #6c7488;
  display: block;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const DownloadBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--lp-colors-medium-blue-600);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const OrderIdBtn = styled.button`
  color: var(--lp-colors-medium-blue-600);
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
`;

export const ItemList = styled.div`
  list-style: none;
  margin: 0;
  line-height: 22px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  &.active-item {
    border-top: 1px solid #e6eaee;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 100%;
  position: relative;
`;

export const DetailsLinkBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: #6c7488;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FeatureIcon = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

export const OrderId = styled.div`
  font-size: 13px;
  padding-top: 10px;
  font-weight: bold;
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 0px;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: 0;
  min-width: 0;

  &.item-col-title {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    flex-basis: 0;
    flex-grow: 3;
  }
  &.item-col-lgr {
    max-width: 250px;
  }
  &.item-col-header {
    font-size: 15px;
    color: #6c7488;
  }
  &.custom-col-xsr {
    max-width: 400px;
  }
  &.fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    position: absolute;
    right: 0;
    padding-right: 0;
    width: 30px;
    height: 56px;
    cursor: pointer;
    justify-content: center;
  }
`;

export const ItemActionsBlock = styled.div<{ active: boolean }>`
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 24px;
  width: 160px;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  transition: all 0.15s ease-in-out;
  z-index: 1;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;
