import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const SpinnerWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchWrapper = styled.div`
  width: 220px;
  position: absolute;
  top: 30px;
  right: 60px;
`;

export const OrderIdBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--lp-colors-medium-blue-600);
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const DetailsLinkBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: #6c7488;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;
