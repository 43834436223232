import Alert from 'Components/Alert';
import Spinner from 'Components/Spinner/Spinner';
import { H3, LpBox, LpDetails, Row } from 'Constants/styles';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { BlueBtn, FeatureIcon, InfoLinkLi, InfoLinkUl, Media, RowP } from './styles';

const CompanyInfo = (): ReactElement => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(false);
  const [isSuspendLoading, setIsSuspendLoading] = useState<boolean>(false);
  const [checkoutErrorMsg, setCheckoutErrorMsg] = useState<string>('');
  const [suspendErrorMsg, setSuspendErrorMsg] = useState<string>('');
  const [checkoutEnabled, setCheckoutEnabled] = useState<boolean | null>(null);
  const [accountSuspended, setAccountSuspended] = useState<boolean | null>(null);

  const { merchantId, apiBaseUri, businessName, stripeAccountId, brandName } = useSelector((state: ReduxStateType) => ({
    merchantId: state.activeMerchantId,
    apiBaseUri: state.apiBaseUri,
    businessName: state.businessName,
    stripeAccountId: state.stripeAccountId,
    brandName: state.brandName,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchStatus = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/status`;
      const options = await getFetchOptions();

      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then(({ isCheckoutEnabled, isSuspended }) => {
          setCheckoutEnabled(isCheckoutEnabled);
          setAccountSuspended(isSuspended);
        })
        .catch((e) => {
          setErrorMsg(e.message);
        });
    };

    fetchStatus();
  }, [apiBaseUri, merchantId]);

  const handleAuthStatus = async (e: MouseEvent<HTMLButtonElement>, type: 'checkout' | 'suspend'): Promise<void> => {
    e.preventDefault();

    if (!apiBaseUri || !merchantId) {
      return;
    }

    const confirmed = window.confirm('Are you sure you want to continue?');
    if (!confirmed) {
      return;
    }

    let body = {};
    switch (type) {
      case 'checkout':
        setIsCheckoutLoading(true);
        setCheckoutErrorMsg('');
        body = {
          isCheckoutEnabled: !checkoutEnabled,
        };
        break;

      case 'suspend':
        setIsSuspendLoading(true);
        setSuspendErrorMsg('');
        body = {
          isSuspended: !accountSuspended,
        };
        break;
    }

    const url = `${apiBaseUri}/merchants/${merchantId}/auth`;
    const options = await getFetchOptions('POST', JSON.stringify(body));

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        switch (type) {
          case 'checkout':
            setCheckoutEnabled((prev) => !prev);
            break;

          case 'suspend':
            setAccountSuspended((prev) => !prev);
            break;
        }
      })
      .catch((e) => {
        switch (type) {
          case 'checkout':
            setCheckoutErrorMsg(e.message || 'Failed to toggle checkout enabled');
            break;

          case 'suspend':
            setSuspendErrorMsg(e.message || 'Failed to toggle account suspended');
            break;
        }
      })
      .finally(() => {
        setIsSuspendLoading(false);
        setIsCheckoutLoading(false);
      });
  };

  return (
    <LpBox>
      {checkoutErrorMsg.length > 0 && <Alert message={checkoutErrorMsg} />}
      {suspendErrorMsg.length > 0 && <Alert message={suspendErrorMsg} />}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      <LpDetails>
        <Row>
          <div className="col-sm-8">
            <Media>
              <FeatureIcon className="feature-icon media-icon wicons">
                <UserIcon className="user-icon" />
              </FeatureIcon>
              <H3 className="pt-2 pb-0">{!!brandName ? brandName : businessName}</H3>
            </Media>
          </div>
        </Row>
        <Row>
          <RowP>#{merchantId}</RowP>
        </Row>
        <Media className="py-2">
          <div className="col-sm-6">
            <Row>
              {checkoutEnabled !== null && (
                <BlueBtn onClick={(e) => handleAuthStatus(e, 'checkout')} disabled={isCheckoutLoading}>
                  {checkoutEnabled ? 'Block Checkout' : 'Allow Checkout'}
                  {isCheckoutLoading && <Spinner height="20px" width="20px" />}
                </BlueBtn>
              )}
              {accountSuspended !== null && (
                <BlueBtn onClick={(e) => handleAuthStatus(e, 'suspend')} disabled={isSuspendLoading}>
                  {accountSuspended ? 'Resume Account' : 'Suspend Account'}
                  {isSuspendLoading && <Spinner height="20px" width="20px" />}
                </BlueBtn>
              )}
            </Row>
          </div>

          <div className="col-sm-6">
            <InfoLinkUl>
              <InfoLinkLi>
                <NavLink
                  className="company-link"
                  to={`/admin-dashboard/transactions?merchantId=${merchantId}&merchantName=${businessName}`}
                >
                  List transactions
                </NavLink>
              </InfoLinkLi>
              <InfoLinkLi>
                <NavLink
                  className="company-link"
                  to={`/admin-dashboard/overdue?merchantId=${merchantId}&merchantName=${businessName}`}
                >
                  Overdue plans
                </NavLink>
              </InfoLinkLi>
              <InfoLinkLi>
                <a
                  className="company-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://dashboard.stripe.com/connect/accounts/${stripeAccountId}`}
                >
                  Go to Stripe account
                </a>
              </InfoLinkLi>
            </InfoLinkUl>
          </div>
        </Media>
      </LpDetails>
    </LpBox>
  );
};

export default CompanyInfo;
