import Message from 'Components/Message/';
import Spinner from 'Components/Spinner/Spinner';
import { Content, H2, Label, LpBox, Row } from 'Constants/styles';
import { MouseEvent, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import styled from '@emotion/styled';

import { CustomerAPIResponse } from './types';

const SpinnerWrapper = styled.div`
  height: 207px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type CustomerDetailsType = {
  apiBaseUri: string;
  customerId: string;
};

const CustomerDetails = ({ apiBaseUri, customerId }: CustomerDetailsType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [customer, setCustomer] = useState<CustomerAPIResponse | null>(null);

  useEffect(() => {
    if (!apiBaseUri || !customerId) {
      return;
    }

    const fetchDetails = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/customers/${customerId}`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const response = await res.json();
        setCustomer(response);
      } catch (e) {
        setErrorMsg(e.message || 'Failed to fetch customer details');
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [apiBaseUri, customerId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <Content>
      <LpBox>
        <H2>Customer details</H2>
        {loading && (
          <SpinnerWrapper>
            <Spinner color="#0016D1" />
          </SpinnerWrapper>
        )}
        {errorMsg.length > 0 && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
        {!loading && (
          <>
            <Row className="mt-3">
              <Label className="col-3">Customer ID</Label>
              <div className="col-9">{customerId}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Email</Label>
              <div className="col-9">{customer?.email}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Phone</Label>
              <div className="col-9">{customer?.phoneNumber}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">First name</Label>
              <div className="col-9">{customer?.firstName}</div>
            </Row>
            <Row className="mt-3">
              <Label className="col-3">Last name</Label>
              <div className="col-9">{customer?.lastName}</div>
            </Row>
          </>
        )}
      </LpBox>
    </Content>
  );
};

export default CustomerDetails;
