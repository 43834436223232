export const filterStatusArray = [
  {
    text: 'Paid',
    inputName: 'filterStatusPaid_menu',
    value: 'paid',
  },
  {
    text: 'Pending',
    inputName: 'filterStatusFailed_menu',
    value: 'pending',
  },
  {
    text: 'Disputed',
    inputName: 'filterStatusDisputed_menu',
    value: 'disputed',
  },
  {
    text: 'Partially refunded',
    inputName: 'filterStatusPartiallyRefunded_menu',
    value: 'partially refunded',
  },
  {
    text: 'Fully Refunded',
    inputName: 'filterStatusRefunded_menu',
    value: 'refunded',
  },
  {
    text: 'Failed',
    inputName: 'filterStatusFailed_menu',
    value: 'failed',
  },
];

export const filterTypeArray = [
  {
    text: 'Full Payment',
    inputName: 'filterTypeFull_menu',
  },
  {
    text: 'Payment Plan',
    inputName: 'filterTypeSplit_menu',
  },
];
