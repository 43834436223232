import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { LpBox, Row } from 'Constants/styles';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import validateEmail from 'utils/validateEmail';

import { Btn, BtnSpinner, Flex, FormItem, Label, SpinnerWrapper, VInput } from './styles';
import { EmailAPIResponse } from './types';

const EmailReset = () => {
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [config, setConfig] = useState<EmailAPIResponse | null>(null);

  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  const getVerification = async (isReset: boolean, emailAddress: string) => {
    try {
      !isReset && setSearchLoading(true);
      setLoading(true);
      const url = `${apiBaseUri}/admin/email/${encodeURIComponent(emailAddress)}`;
      const options = await getFetchOptions();
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const json = await res.json();
      setConfig(json);
      setSuccess(true);
      setMsg(isReset ? 'Reset email verification successfully.' : '');
    } catch (e) {
      setSuccess(false);
      setMsg(e.message || 'Failed to search by email');
    } finally {
      !isReset && setSearchLoading(false);
      setLoading(false);
    }
  };

  const handleSearch = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    getVerification(false, email);
  };

  const handleReset = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setResetLoading(true);
      const url = `${apiBaseUri}/admin/email/${encodeURIComponent(config?.emailAddress ?? '')}/reset`;
      const options = await getFetchOptions('POST');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      await getVerification(true, config?.emailAddress ?? '');
    } catch (e) {
      setMsg(e.message || 'Failed to reset email verification');
    } finally {
      setResetLoading(false);
    }
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setMsg('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setEmail(value);
    setValid(validateEmail(value));
  };

  return (
    <div>
      <Message success={success} description={msg} handleClose={handleCloseMsg} />
      <LpBox>
        <FormItem>
          <Label>Search by email</Label>
          <Flex>
            <VInput type="email" isValid={valid} required data-testid="email" value={email} onChange={handleChange} />
            <Btn
              data-testid="email-search-btn"
              disabled={email.length === 0 || !valid || searchLoading}
              onClick={handleSearch}
              hasMarginLeft
            >
              Search
              {searchLoading && (
                <BtnSpinner>
                  <Spinner width="20px" height="20px" borderWidth="2px" />
                </BtnSpinner>
              )}
            </Btn>
          </Flex>
        </FormItem>
        {loading && (
          <SpinnerWrapper>
            <Spinner color="#0016D1" />
          </SpinnerWrapper>
        )}
        {!loading && config && (
          <>
            <Row className="mt-4">
              <Label className="col-4">Email</Label>
              <div className="col-8">{config.emailAddress}</div>
            </Row>
            {config.verification && (
              <>
                <Row className="mt-4">
                  <Label className="col-4">Created at</Label>
                  <div className="col-8">
                    {
                      getDateFormat({
                        time: config.verification?.createdAt,
                        showComma: false,
                        showFullYear: true,
                        showDaySuffix: false,
                      }).formatted
                    }
                  </div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Failed attempts</Label>
                  <div className="col-8">{config.verification.failedVerifAttempts}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last verification code</Label>
                  <div className="col-8">{config.verification.lastVerifCode}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last verification code generated at</Label>
                  <div className="col-8">
                    {
                      getDateFormat({
                        time: config.verification.lastVerifCodeGenAt,
                        showComma: false,
                        showFullYear: true,
                        showDaySuffix: false,
                      }).formatted
                    }
                  </div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last Failed verification at</Label>
                  <div className="col-8">
                    {config.verification.lastFailedVerifAt
                      ? getDateFormat({
                          time: config.verification.lastFailedVerifAt,
                          showComma: false,
                          showFullYear: true,
                          showDaySuffix: false,
                        }).formatted
                      : '-'}
                  </div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Is expired</Label>
                  <div className="col-8">{String(config.verification.isExpired)}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Is verified</Label>
                  <div className="col-8">{String(config.verification.isVerified)}</div>
                </Row>
              </>
            )}
          </>
        )}
        {config && (
          <Btn
            data-testid="email-reset-btn"
            disabled={resetLoading || config.verification === null}
            onClick={handleReset}
            hasMarginLeft={false}
            width="200px"
            className="mt-2"
          >
            Reset email verification
            {resetLoading && (
              <BtnSpinner>
                <Spinner width="20px" height="20px" borderWidth="2px" />
              </BtnSpinner>
            )}
          </Btn>
        )}
      </LpBox>
    </div>
  );
};

export default EmailReset;
