import './Companies.scss';

import EmptyList from 'Components/EmptyList/EmptyList';
import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import React, { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setMerchantDetails } from 'redux/Actions';
import { toCurrency } from 'utils/currency';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ListHeaders } from './Constants';
import { DetailsLinkBtn, OrderIdBtn } from './styles';
import { MerchantAPIResponseType } from './types';

export type CompaniesProps = {
  merchantsList: MerchantAPIResponseType[];
};

const Companies = ({ merchantsList }: CompaniesProps): ReactElement => {
  const { activeMerchantId } = useSelector((state: ReduxStateType) => ({
    activeMerchantId: state.activeMerchantId,
  }));

  const dispatch: Dispatch<any> = useDispatch();

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, merchantId: string): void => {
    e.preventDefault();
    dispatch(setMerchantDetails(false, merchantId, '', null, ''));
  };

  const handleClickOutside = () => {
    dispatch(setMerchantDetails(false, '', '', null, ''));
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'item-actions-list', 'dots-icon', 'link-btn']);

  const handleShowDetails = (e: MouseEvent<HTMLButtonElement>, merchant: MerchantAPIResponseType): void => {
    e.preventDefault();
    const { merchantId, businessName, stripeAccountId, brandName } = merchant;
    dispatch(setMerchantDetails(true, merchantId, businessName, stripeAccountId, brandName));
  };

  return (
    <div className="companies-page">
      <Content>
        <LpBox>
          <div className="card items">
            <ul className="item-list striped">
              <li className="item item-list-header">
                <div className="item-row">
                  {ListHeaders.map((header, index) => (
                    <div key={index} className={`item-col item-col-header ${header.className}`}>
                      <span>{header.text}</span>
                    </div>
                  ))}
                </div>
              </li>
              {merchantsList.length === 0 && <EmptyList />}
              {merchantsList.length > 0 &&
                merchantsList.map((merchant) => (
                  <li className="item active-item" key={merchant.merchantId}>
                    <div className="item-row item-row-reset-m">
                      <div className="item-col item-col-sales item-col-lgr">
                        <div className="media">
                          <div className="feature-icon wicons">
                            <UserIcon className="user-icon" />
                          </div>
                          <div className="order-id">
                            <OrderIdBtn
                              data-testid={merchant.businessName}
                              onClick={(e) => handleShowDetails(e, merchant)}
                            >
                              {!!merchant.brandName ? merchant.brandName : merchant.businessName}
                            </OrderIdBtn>
                          </div>
                        </div>
                      </div>
                      <div className="item-col item-col-sales custom-col-xsr">
                        <div className="item text-left mt-1 pl-m">
                          <div className="media">
                            <div className="custom-email-truncate">
                              <a
                                className="lp-color-grey custom-fz-link"
                                href={
                                  merchant.website?.includes('http') ? merchant.website : `https://${merchant.website}`
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {merchant.website}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-col item-col-sales custom-col-xsr">
                        <div className="item text-left mt-1 pl-m slug">{merchant.slug}</div>
                      </div>
                      <div className="item-col item-col-sales custom-col-xsr">
                        <div className="item text-left mt-1">
                          <div className="price">
                            <span className="hide-m-b">
                              {toCurrency(
                                merchant.payplanLimit.maxPayPlanAmountUnconfirmedCustomers,
                                merchant.payplanLimit.currency,
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="item-col item-col-sales custom-col-xsrr">
                        <div className="item text-left mt-1">
                          <div className="price">
                            <span className="hide-m-b">
                              {toCurrency(
                                merchant.payplanLimit.maxPayPlanAmountConfirmedCustomers,
                                merchant.payplanLimit.currency,
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="item-col fixed item-col-actions-dropdown">
                        <div className="item-actions-dropdown" onClick={(e) => toggleDropdown(e, merchant.merchantId)}>
                          <DotsIcon className="dots-icon" />
                        </div>
                        <div
                          className={`item-actions-block ${activeMerchantId === merchant.merchantId ? 'active' : ''}`}
                        >
                          <ul className="item-actions-list">
                            <li>
                              <DetailsLinkBtn className="link-btn" onClick={(e) => handleShowDetails(e, merchant)}>
                                View details
                              </DetailsLinkBtn>
                            </li>
                            <li>
                              <NavLink
                                className="link-btn"
                                to={`/admin-dashboard/transactions?merchantId=${merchant.merchantId}&merchantName=${merchant.businessName}`}
                              >
                                List transactions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="link-btn"
                                to={`/admin-dashboard/overdue?merchantId=${merchant.merchantId}&merchantName=${merchant.businessName}`}
                              >
                                Overdue plans
                              </NavLink>
                            </li>
                            <li>
                              <a
                                className="link-btn"
                                target="_blank"
                                rel="noreferrer"
                                href={`https://dashboard.stripe.com/connect/accounts/${merchant.stripeAccountId}`}
                              >
                                Go to Stripe account
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </LpBox>
      </Content>
    </div>
  );
};

export default Companies;
