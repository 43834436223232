import MerchantProfile from 'Components/Disputes/MerchantProfile';
import { Content, H2, Label, LpBox, Row } from 'Constants/styles';
import { ReactComponent as FailedIcon } from 'assets/svg/failed.svg';
import { ReactComponent as PassedIcon } from 'assets/svg/passed.svg';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';

import { Icon } from './PaymentPlansDetail.styles';
import * as s from './PaymentPlansList.styles';
import { CHARGE_ENTRIES_ITEM_ROWS_OBJ, LEDGER_ENTRIES_ITEM_ROWS_OBJ } from './paymentPlansItemRowsObj';
import { PaymentPlanAPIResponse } from './types';

type Props = {
  paymentPlan: Nullable<PaymentPlanAPIResponse>;
};

const PaymentPlanDetail = ({ paymentPlan }: Props): ReactElement => {
  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);
  return (
    <>
      {paymentPlan && (
        <>
          <MerchantProfile apiBaseUri={apiBaseUri} merchantId={paymentPlan.merchantId} />
          <Content>
            <LpBox>
              <H2>Customer Details</H2>
              <Row className="mt-4">
                <Label className="col-3">
                  <s.Strong>Customer ID</s.Strong>
                </Label>
                <div className="col-9">
                  <s.Strong>{paymentPlan.customerId}</s.Strong>
                </div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Pay Plan ID</Label>
                <div className="col-9">{paymentPlan.payPlanId}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Internal Order ID</Label>
                <div className="col-9">{paymentPlan.internalOrderId}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Customer Email</Label>
                <div className="col-9">{paymentPlan.customerEmail}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Paid Amount</Label>
                <div className="col-9">{toCurrency(paymentPlan.paidAmount, paymentPlan.currency)}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Plan Amount</Label>
                <div className="col-9">{toCurrency(paymentPlan.planAmount, paymentPlan.currency)}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">State</Label>
                <div className="col-9">{paymentPlan.state}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Created At</Label>
                <div className="col-9">{getDateFormat({ time: paymentPlan.createdAt }).date}</div>
              </Row>
              <Row className="mt-4">
                <Label className="col-3">Due At</Label>
                <div className="col-9">{getDateFormat({ time: paymentPlan.dueAt }).date}</div>
              </Row>
            </LpBox>

            <LpBox>
              <H2>Charge Entries</H2>
              <s.Card className="mt-4">
                <s.ItemList>
                  <s.Item>
                    <s.ItemRow>
                      {CHARGE_ENTRIES_ITEM_ROWS_OBJ.map((row, rowIndex) => (
                        <s.ItemCol key={rowIndex} className={`item-col-header item-col-title ${row.class}`}>
                          <s.SpanText>{row.text}</s.SpanText>
                        </s.ItemCol>
                      ))}
                    </s.ItemRow>
                  </s.Item>
                  {paymentPlan.charges.entries.map((chargeEntry, entryIndex) => (
                    <s.Item key={entryIndex}>
                      <s.ItemRow>
                        <s.ItemCol className="icon-status">
                          <div className="item text-left mt-1">
                            <Icon>{chargeEntry.isSuccess === true ? <PassedIcon /> : <FailedIcon />}</Icon>
                          </div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">
                            <s.Price>{toCurrency(chargeEntry.chargedAmount, paymentPlan.currency)}</s.Price>
                          </div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">
                            <s.Price>
                              {chargeEntry.debtCollectionCharge === true
                                ? `Debt Collection`
                                : chargeEntry.instalmentCharge === true
                                ? 'Instalment'
                                : ''}
                            </s.Price>
                          </div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">{chargeEntry.instalmentNumber}</div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">
                            {chargeEntry.lateFeeChargedAmount && (
                              <s.Price>{toCurrency(chargeEntry.lateFeeChargedAmount, paymentPlan.currency)}</s.Price>
                            )}
                          </div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-xsr">
                          <div className="item text-left mt-1">
                            <s.Price>{getDateFormat({ time: chargeEntry.createdAt }).date}</s.Price>
                          </div>
                        </s.ItemCol>
                      </s.ItemRow>
                    </s.Item>
                  ))}
                </s.ItemList>
              </s.Card>
            </LpBox>

            <LpBox>
              <H2>Ledger Entries</H2>
              <s.Card className="mt-4">
                <s.ItemList>
                  <s.Item>
                    <s.ItemRow>
                      {LEDGER_ENTRIES_ITEM_ROWS_OBJ.map((row, rowIndex) => (
                        <s.ItemCol key={rowIndex} className={`item-col-header item-col-title ${row.class}`}>
                          <s.SpanText>{row.text}</s.SpanText>
                        </s.ItemCol>
                      ))}
                    </s.ItemRow>
                  </s.Item>
                  {paymentPlan.ledger.entries.map((ledgerEntry, entryIndex) => (
                    <s.Item key={entryIndex}>
                      <s.ItemRow>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">
                            <s.Price>{toCurrency(ledgerEntry.amount, paymentPlan.currency)}</s.Price>
                          </div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">{ledgerEntry.entryType}</div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-amount">
                          <div className="item text-left mt-1">{ledgerEntry.reason}</div>
                        </s.ItemCol>
                        <s.ItemCol className="item-col-xsr">
                          <div className="item text-left mt-1">
                            <s.Price>{getDateFormat({ time: ledgerEntry.entryTime }).date}</s.Price>
                          </div>
                        </s.ItemCol>
                      </s.ItemRow>
                    </s.Item>
                  ))}
                </s.ItemList>
              </s.Card>
            </LpBox>
          </Content>
        </>
      )}
    </>
  );
};

export default PaymentPlanDetail;
