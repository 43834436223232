import Alert from 'Components/Alert';
import ConfirmationMessage from 'Components/ConfirmationMessage';
import EmptyList from 'Components/EmptyList/EmptyList';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import React, { MouseEvent, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { ListHeaders } from './Constants';
import { Card, FeatureIcon, Item, ItemCol, ItemList, ItemRow, Media, OrderId, OrderIdBtn, ResendBtn } from './styles';
import { MerchantAPIResponseType } from './types';

export type CompaniesProps = {
  merchantsList: MerchantAPIResponseType[];
};

const InactiveMerchants = ({ merchantsList }: CompaniesProps): ReactElement => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchantId, setMerchantId] = useState<string>('');

  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  const handleClick = async (e: MouseEvent<HTMLButtonElement>, mId: string): Promise<void> => {
    e.preventDefault();

    const options = await getFetchOptions('POST');
    const url = `${apiBaseUri}/onboarding/${mId}/send-email`;
    setIsLoading(true);
    setMerchantId(mId);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setErrorMsg('');
        setIsSuccess(true);
      })
      .catch((e) => {
        setErrorMsg(e?.message || 'Failed to send email');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isSuccess && errorMsg.length === 0 && <ConfirmationMessage message="Email is sent" success />}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      <Content>
        <LpBox>
          <Card>
            <ItemList>
              <Item>
                <ItemRow>
                  {ListHeaders.map((header, index) => (
                    <ItemCol key={index} className={`item-col-header ${header.className}`}>
                      <span>{header.text}</span>
                    </ItemCol>
                  ))}
                </ItemRow>
              </Item>
              {merchantsList.length === 0 && <EmptyList />}
              {merchantsList.length > 0 &&
                merchantsList.map((merchant) => (
                  <Item key={merchant.merchantId}>
                    <ItemRow>
                      <ItemCol className="item-col-sales item-col-lgr">
                        <Media>
                          <FeatureIcon>
                            <UserIcon className="user-icon" />
                          </FeatureIcon>
                          <OrderId>
                            <OrderIdBtn>{merchant.businessName || 'Guest'}</OrderIdBtn>
                          </OrderId>
                        </Media>
                      </ItemCol>
                      <ItemCol className="item-col-sales">
                        <Item className="text-left mt-1">
                          <ResendBtn
                            disabled={isLoading && merchantId === merchant.merchantId}
                            onClick={(e) => handleClick(e, merchant.merchantId)}
                          >
                            Resend onboarding email
                            {isLoading && merchantId === merchant.merchantId && (
                              <Spinner className="ml-1" height="20px" width="20px" borderWidth="2px" />
                            )}
                          </ResendBtn>
                        </Item>
                      </ItemCol>
                    </ItemRow>
                  </Item>
                ))}
            </ItemList>
          </Card>
        </LpBox>
      </Content>
    </>
  );
};

export default InactiveMerchants;
