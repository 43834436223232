import { Currency } from 'utils/currency';

export const defaultCardValue = {
  cardFunding: '',
  cardBrand: '',
  perc: '',
  fixed: '',
  time: 0,
};

export type CardType = {
  cardFunding: string;
  cardBrand: string;
  perc: number;
  fixed: number;
};

export type CardTimeType = {
  cardFunding: string;
  cardBrand: string;
  perc: string;
  fixed: string;
  time: number;
};

export type FeeCountryType = {
  fixed: number;
  perc: number;
  cards: CardType[];
};

type FeeStructType = {
  domestic: FeeCountryType;
  international: FeeCountryType;
};

export enum TaxCountryType {
  AU = 'AU',
  NZ = 'NZ',
}

export type PaymentType = 'payPlan' | 'payCard';
export type CountryType = 'domestic' | 'international';

export interface MerchantFeeAPIResponse {
  calculateTaxOnFee: boolean;
  taxCountry: TaxCountryType;
  feeStruct: {
    full: FeeStructType;
    split: FeeStructType;
  } | null;
}

export interface PaymentPlanLimitAPIResponse {
  maxPayPlanAmountConfirmedCustomers: number;
  maxPayPlanAmountUnconfirmedCustomers: number;
  minPlanLimit: number;
  currency: Currency;
}

export interface AccountSettingsAPIResponse {
  accountName: null | string;
  accountAddress: null | string;
}

export interface PublicInfoAPIResponse {
  businessName: string;
  brandName: string;
  website: string;
  cardStatementName: string;
  supportSite: string;
  contactEmail: string;
  supportEmail: string;
  phoneNo: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
}

export interface GeneralSettingsAPIResponse {
  merchantId?: Nullable<string>;
  hideLimepayLogoInTermsPage?: Nullable<boolean>;
  performKyc?: Nullable<boolean>;
  kycLimitWithoutDocument?: Nullable<number>;
  kycLimitWithDriversLicence?: Nullable<number>;
  kycLimitWithPassport?: Nullable<number>;
  kycLimitCustomerLinks?: Nullable<number>;
  isB2B?: Nullable<boolean>;
  alwaysRequest3DS?: Nullable<boolean>;
  limitUnconfirmedAllowedActivePayPlans?: Nullable<number>;
  limitConfirmedAllowedActivePayPlans?: Nullable<number>;
  hideInternalOrderIdFromCustomer?: Nullable<boolean>;
  displayCustomerSavedPaymentMethodsInCheckout?: Nullable<boolean>;
  payDeferralCheckoutMaxLimitOverride?: Nullable<number>;
  sourceVerificationEnabled?: Nullable<boolean>;
}
