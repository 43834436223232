import { Content, H2, LpBox } from 'Constants/styles';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { Currency, toCurrency } from 'utils/currency';

import { TableHr, TableRow, TableText } from './TransactionHistory.styles';
import { getObjFromCharges, getObjFromLedger } from './getTransactionObj';
import { ChargeEntriesType, EntryType, LedgerEntriesType, TransactionType } from './types';

type TransactionHistoryType = {
  ledgerEntries: LedgerEntriesType[];
  chargesEntries: ChargeEntriesType[];
  currency: Currency;
};

const TransactionHistory = ({ ledgerEntries, chargesEntries, currency }: TransactionHistoryType): ReactElement => {
  const [transactions, setTransactions] = useState<TransactionType[]>([]);

  useEffect(() => {
    const array: TransactionType[] = [];
    let i = 0;
    let j = 0;
    let totalBalance = 0;

    while (i < ledgerEntries.length && j < chargesEntries.length) {
      const ith = ledgerEntries[i];
      const jth = chargesEntries[j];

      if (ith.entryType === EntryType.Charge || ith.amount === 0) {
        ++i;
        continue;
      }

      if (jth.chargedAmount === 0) {
        ++j;
        continue;
      }

      const time1 = new Date(ith.entryTime).getTime();
      const time2 = new Date(jth.createdAt).getTime();
      if (time1 < time2) {
        const obj = getObjFromLedger(ith, totalBalance, currency);
        totalBalance = obj.balance;
        array.unshift({ ...obj });
        ++i;
      } else {
        const obj = getObjFromCharges(jth, totalBalance, currency);
        totalBalance = obj.balance;
        array.unshift({ ...obj });
        ++j;
      }
    }

    while (i < ledgerEntries.length) {
      const ith = ledgerEntries[i];
      if (ith.entryType === EntryType.Charge || ith.amount === 0) {
        ++i;
        continue;
      }
      const obj = getObjFromLedger(ith, totalBalance, currency);
      totalBalance = obj.balance;
      array.unshift({ ...obj });
      ++i;
    }

    while (j < chargesEntries.length) {
      const jth = chargesEntries[j];
      if (jth.chargedAmount === 0) {
        ++j;
        continue;
      }

      const obj = getObjFromCharges(jth, totalBalance, currency);
      totalBalance = obj.balance;
      array.unshift({ ...obj });
      ++j;
    }

    setTransactions(array);
  }, [ledgerEntries, chargesEntries, currency]);

  return (
    <Content className="mt-4">
      <LpBox>
        <H2>Transaction history</H2>
        <TableRow className="mt-4">
          <TableText width="56px">Date</TableText>
          <TableText left="25px" className="header-description">
            Description
          </TableText>
          <TableText width="80px" left="auto" align="right">
            Money out
          </TableText>
          <TableText width="80px" left="16px" align="right">
            Money in
          </TableText>
          <TableText width="80px" left="16px" align="right">
            Balance
          </TableText>
        </TableRow>

        {transactions.map((t, index) => (
          <Fragment key={index}>
            <TableHr />
            <TableRow className="mt-2 mt-md-0">
              <TableText width="56px" color="#191E33">
                {t.time}
              </TableText>

              <TableText left="25px" color="#191E33" dangerouslySetInnerHTML={{ __html: t.description }} />

              <TableText width="80px" left="auto" align="right" color="#FF6E44">
                {t.debitAmount !== null ? toCurrency(t.debitAmount, t.currency) : null}
              </TableText>

              <TableText width="80px" left="16px" align="right" color="#749FAA">
                {t.creditAmount !== null ? toCurrency(t.creditAmount, t.currency) : null}
              </TableText>

              <TableText width="80px" left="16px" align="right" color="#191E33" className="balance">
                {toCurrency(t.balance, t.currency)}
              </TableText>
            </TableRow>
          </Fragment>
        ))}
      </LpBox>
    </Content>
  );
};

export default TransactionHistory;
