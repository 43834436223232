import React, { MouseEvent, ReactElement } from 'react';

import { BackBtn, Wrapper } from './styles';

export type BackComponentType = {
  text?: string;
  handleClickBack?(e: MouseEvent<HTMLButtonElement>): void;
};

const BackComponent = ({ text = 'BackComponent', handleClickBack }: BackComponentType): ReactElement => (
  <Wrapper>
    <BackBtn onClick={handleClickBack}>{`< ${text}`}</BackBtn>
  </Wrapper>
);

export default BackComponent;
