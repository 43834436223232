import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, useState } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ITEM_ROWS_OBJ } from './Constants';
import * as s from './OrderList.styles';
import { APIResponseType } from './types';

type OrderListProps = {
  orders: APIResponseType[];
  setShowDetails(s: boolean): void;
  setActivePlan(p: APIResponseType | null): void;
};

const OrderList = ({ orders, setShowDetails, setActivePlan }: OrderListProps) => {
  const [activeTransactionId, setActiveTransactionId] = useState<string>('');

  const handleClickBtn = (e: MouseEvent<HTMLButtonElement>, transactionId: string): void => {
    e.preventDefault();
    setShowDetails(true);
    setActiveTransactionId(transactionId);
    const plan = orders.find((order) => order.transactionId === transactionId);
    setActivePlan(plan ?? null);
  };

  const handleClickOutside = () => {
    setActiveTransactionId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'item-actions-list-link', 'dots-icon']);

  return (
    <s.Card>
      <s.ItemList>
        <s.Item>
          <s.ItemRow>
            {ITEM_ROWS_OBJ.map((row) => (
              <s.ItemCol key={row.text}>
                <span className="header-title">{row.text}</span>
              </s.ItemCol>
            ))}
          </s.ItemRow>
        </s.Item>
        {orders.map((order, orderIndex) => (
          <s.Item key={orderIndex} className="active-item">
            <s.ItemRow>
              <s.ItemCol>
                <s.Media>
                  <s.FeatureIcon>
                    <OrderIdIcon className="order-id-icon" />
                  </s.FeatureIcon>

                  <s.OrderIdBtn onClick={(e) => handleClickBtn(e, order.transactionId)}>
                    {order.internalOrderId}
                  </s.OrderIdBtn>
                </s.Media>
              </s.ItemCol>
              <s.ItemCol>
                <s.Item className="text-left mt-1">{order.customerEmail}</s.Item>
              </s.ItemCol>
              <s.ItemCol>
                <s.Item className="text-left mt-1">
                  <s.Price>{toCurrency(order.planAmount, order.currency)}</s.Price>
                </s.Item>
              </s.ItemCol>
              <s.ItemCol>
                <s.Item className="text-left mt-1">
                  <s.Price>{toCurrency(order.paidAmount, order.currency)}</s.Price>
                </s.Item>
              </s.ItemCol>
              <s.ItemCol>
                <s.Item className="text-left mt-1">
                  <s.Price>{order.state}</s.Price>
                </s.Item>
              </s.ItemCol>
              <s.ItemCol>
                <s.Item className="text-left mt-1">
                  <s.Price>{getDateFormat({ time: order.createdAt }).formatted}</s.Price>
                </s.Item>
              </s.ItemCol>
              <s.ItemCol className="fixed">
                <s.ItemActionsDropdown>
                  <s.IconWrapper onClick={() => setActiveTransactionId(order.transactionId)}>
                    <DotsIcon />
                  </s.IconWrapper>
                  <s.ItemActionsBlock
                    active={activeTransactionId === order.transactionId}
                    className="item-actions-block"
                  >
                    <s.ItemActionsListLink
                      className="item-actions-list-link"
                      onClick={(e) => handleClickBtn(e, order.transactionId)}
                    >
                      View details
                    </s.ItemActionsListLink>
                  </s.ItemActionsBlock>
                </s.ItemActionsDropdown>
              </s.ItemCol>
            </s.ItemRow>
          </s.Item>
        ))}
      </s.ItemList>
    </s.Card>
  );
};

export default OrderList;
