import './App.scss';

import { firebaseApp } from 'APIs/firebase';
import Merchants from 'Components/Companies';
import Customers from 'Components/Customers';
import Disputes from 'Components/Disputes/Disputes';
import Login from 'Components/Login/Login';
import OTPResets from 'Components/OTPResets';
import Onboard from 'Components/OnboardMerchant';
import OverduePlans from 'Components/OverduePlans';
import Payouts from 'Components/Payouts';
import ResendEmail from 'Components/ResendEmail';
import Sidebar from 'Components/Sidebar/Sidebar';
import Transactions from 'Components/Transactions';
import { baseUri } from 'Constants/Constants';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setConfig, updateLoggedIn } from 'redux/Actions';
import getCurrentUser from 'utils/getCurrentUser';

import PrivateRoute from './PrivateRoute';

const App = (): ReactElement => {
  const { isLoggedIn, isLoginPending } = useSelector((state: ReduxStateType) => ({
    isLoggedIn: state.isLoggedIn,
    isLoginPending: state.isLoginPending,
  }));

  const dispatch: Dispatch<any> = useDispatch();
  const updateLoggedInCB = useCallback((is: boolean) => dispatch(updateLoggedIn(is)), [dispatch]);
  const setConfigCB = useCallback(
    (uri: string, id: string, mId: string) => dispatch(setConfig(uri, id, mId)),
    [dispatch],
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const { headers } = await fetch('/');
        const apiHost = headers.get('api-ingress-host') || '';
        const response = await fetch(`${apiHost}/config/admin`);
        if (!response.ok) {
          throw new Error('failed to fetch config');
        }
        const { authApiKey, authDomain, apiBaseUri, tenantId, marketnowMarketplaceId } = await response.json();
        setConfigCB(apiBaseUri, tenantId, marketnowMarketplaceId);
        firebaseApp
          .initializeApp({ apiKey: authApiKey, authDomain })
          .auth()
          .onAuthStateChanged(async () => {
            const currentUser = await getCurrentUser();
            updateLoggedInCB(!!currentUser);
          });
      } catch (error) {
        console.error(error);
      }
    };
    initialize();
  }, [setConfigCB, updateLoggedInCB]);

  return (
    <div className={`App ${!isLoggedIn || isLoginPending ? 'no-sidebar' : ''}`}>
      <BrowserRouter>
        {!isLoginPending && isLoggedIn && <Sidebar />}
        <Switch>
          <Route path={`${baseUri}/login`} component={Login} />
          {isLoggedIn !== null && (
            <>
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/merchants`} component={Merchants} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/onboard-merchant`} component={Onboard} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/resend-email`} component={ResendEmail} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/transactions`} component={Transactions} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/otp-resets`} component={OTPResets} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/overdue`} component={OverduePlans} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/disputes`} component={Disputes} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/customers`} component={Customers} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path={`${baseUri}/payouts`} component={Payouts} />
            </>
          )}
          <Route path="/:merchantName" exact render={(props) => <Redirect to={`${baseUri}/merchants`} {...props} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
