import { Input } from 'Constants/styles';

import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

interface VInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
}
export const VInput = styled(Input)<VInputProps>`
  width: 500px;
  border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #dce3eb')};
  &:focus {
    border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #505bcc')};
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  display: block;
  color: #6c7488;
`;

export const Flex = styled.div`
  display: flex;
`;

type BtnProps = {
  width?: string;
  hasMarginLeft?: boolean;
};

export const Btn = styled.button<BtnProps>`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: ${({ width }) => width ?? '150px'};
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 0 9px;
  cursor: pointer;
  outline: none;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '15px' : '0')};
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const BtnSpinner = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  padding-top: 1px;
`;

export const InvalidText = styled.div`
  font-size: 14px;
  color: red;
  position: absolute;
  right: 159px;
`;
