import { Input } from 'Constants/styles';

import styled from '@emotion/styled';

export const FormItem = styled.div`
  position: relative;
`;

interface VInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
}
export const VInput = styled(Input)<VInputProps>`
  width: 500px;
  border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #dce3eb')};
  &:focus {
    border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #505bcc')};
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  display: block;
  color: #6c7488;
`;

type BtnProps = {
  width?: string;
  hasMarginLeft?: boolean;
};

export const Btn = styled.button<BtnProps>`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: ${({ width }) => width ?? '150px'};
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 0 9px;
  cursor: pointer;
  outline: none;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '15px' : '0')};
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const BtnSpinner = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  padding-top: 1px;
`;

export const SpinnerWrapper = styled.div`
  height: 333px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type PhoneWrapperProps = {
  valid: boolean;
};
export const PhoneWrapper = styled.div<PhoneWrapperProps>`
  width: 500px;
  .PhoneInputInput {
    padding: 17px 17px 12px;
    max-height: 44px;
    outline: none;
    background: #fff;
    border: ${({ valid }) => (valid ? '1px solid #dce3eb' : '1px solid red')};
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  }
  .invalid-phone-no {
    .PhoneInputInput {
      border: 1px solid red;
    }
  }
  .PhoneInputCountry {
    background: #f8f9fb;
    width: 85px;
    margin: 0;
    padding: 0 12px;
    border: 1px solid #dce3eb;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .PhoneInputCountrySelectArrow {
    width: 6px;
    height: 6px;
    padding-left: 0px;
    position: absolute;
    right: 21px;
    opacity: 1;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }
`;
