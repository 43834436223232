import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { LpBox } from 'Constants/styles';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { MouseEvent, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import styled from '@emotion/styled';

const Content = styled.article`
  padding: 0 60px;
  display: block;
`;

const User = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  .user {
    margin-top: 4px;
    margin-left: 6px;
  }
`;

const Name = styled.h1`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin: 0 0 0 10px;
  color: #354052;
  padding-top: 6px;
`;

const Id = styled.h5`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  color: #3a3e46;
  font-weight: 600;
  line-height: 1;
`;

const Flex = styled.div`
  display: flex;
`;

const SpinnerWrapper = styled.div`
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type MerchantProfileType = {
  apiBaseUri: string;
  merchantId: string;
};

const MerchantProfile = ({ apiBaseUri, merchantId }: MerchantProfileType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchPublicInfo = async () => {
      setLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          const json = await res.json();
          setBusinessName(json.businessName);
        })
        .catch((e) => {
          setErrorMsg(e.message || 'Failed to get merchants business name');
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchPublicInfo();
  }, [apiBaseUri, merchantId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <Content>
      <LpBox>
        {errorMsg.length > 0 && <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />}
        {loading && (
          <SpinnerWrapper>
            <Spinner color="#0016D1" />
          </SpinnerWrapper>
        )}
        {!loading && (
          <>
            <Flex>
              <User>
                <UserIcon className="user" />
              </User>
              <Name>{businessName}</Name>
            </Flex>
            <Id>{merchantId}</Id>
          </>
        )}
      </LpBox>
    </Content>
  );
};

export default MerchantProfile;
