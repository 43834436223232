import { Input } from 'Constants/styles';

import styled from '@emotion/styled';

export const Flex = styled.div`
  display: flex;
`;

export const WaiveBtn = styled.button`
  padding: 0 20px;
  margin: 0;
  background-color: var(--lp-colors-medium-blue-800);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-800);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 56%);
  letter-spacing: 0.35px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  user-select: none;
  height: 40px;
  outline: none;
`;

export const ModalBody = styled.div`
  margin: 20px 0;
`;

export const FixedField = styled.span`
  position: relative;
  display: block;
  &:before {
    left: 1px;
    top: 1px;
    content: '$';
    position: absolute;
    line-height: 38px;
    border-right: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 40px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const FixedInput = styled(Input)`
  padding-left: 50px;
`;

export const ErrorMsg = styled.div`
  font-size: 14px;
  color: red;
  height: 14px;
  text-align: right;
`;

export const MessageBar = styled.div`
  padding: 0 60px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  max-width: 200px;
`;

export const DebtSpinner = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
