import BackComponent from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import OrderSearch, { SearchOptionType } from 'Components/OrderSearch/OrderSearch';
import { OrderSearchOptions } from 'Components/OrderSearch/OrderSearchOptions';
import { OrderSearchType } from 'Components/OrderSearch/OrderSearchOptions';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fromCurrency, toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';
import useDebounce from 'utils/useDebounce';

import { ListHeaders } from './Constants';
import DisputeDetails from './DisputeDetails';
import * as s from './Disputes.styes';
import { DisputeAPIResponse } from './types';

type SearchOptionObjType = {
  [key: string]: string;
};
const SearchOptionObj: SearchOptionObjType = {
  [OrderSearchType.order]: 'internalOrderId',
  [OrderSearchType.email]: 'customerEmail',
  [OrderSearchType.amount]: 'amount',
};

const Disputes = () => {
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [activeDisputeId, setActiveDisputeId] = useState<string>('');
  const [activeDispute, setActiveDispute] = useState<DisputeAPIResponse | null>(null);
  const [disputesList, setDisputesList] = useState<DisputeAPIResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showDetailsView, setShowDetailsView] = useState<boolean>(false);

  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[1]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { apiBaseUri } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri) {
      return;
    }

    const fetchDisputes = async () => {
      try {
        setLoading(true);
        let url = `${apiBaseUri}/admin/reports/disputes?sort=-createdAt&page=${activePage}`;
        if (debouncedSearch.length > 0) {
          let tempSearch: number | string = debouncedSearch;
          if (selectedSearchOption.value === OrderSearchType.amount) {
            tempSearch = fromCurrency(tempSearch.replace(',', ''));
          }
          url += `&${SearchOptionObj[selectedSearchOption.value]}=${encodeURIComponent(tempSearch)}`;
        }
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        setPageCount(Number(res.headers.get('limepay-page-count')));
        const response = await res.json();
        setDisputesList(response);
      } catch (e) {
        setErrorMsg(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDisputes();
  }, [activePage, apiBaseUri, debouncedSearch, selectedSearchOption.value]);

  const handleClickOutside = () => {
    setActiveDisputeId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, ['item-actions-block', 'item-actions-link-btn', 'dots-icon']);

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, disputeId: string): void => {
    e.preventDefault();
    setActiveDisputeId(disputeId);
  };

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  const toggleDetailsView = (e: MouseEvent<HTMLButtonElement>, dispute: DisputeAPIResponse): void => {
    e.preventDefault();
    setActiveDispute(dispute);
    setShowDetailsView(true);
  };

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowDetailsView(false);
  };

  return (
    <>
      {!showDetailsView && (
        <>
          <s.ItemsSearchWrapper>
            <HeaderTitle title="Disputes" />
            <OrderSearch
              selectedSearchOption={selectedSearchOption}
              setSelectedSearchOption={setSelectedSearchOption}
              selectedSearch={selectedSearch}
              handleSearch={handleSearch}
              ignore={['amount']}
            />
          </s.ItemsSearchWrapper>
          <Content>
            <LpBox>
              <s.Card>
                <s.ItemList>
                  <s.Item className="item-list-header">
                    <s.ItemRow className="item-row">
                      {ListHeaders.map((header, index) => (
                        <s.ItemCol key={index} className="item-col-header">
                          <span>{header.text}</span>
                        </s.ItemCol>
                      ))}
                    </s.ItemRow>
                  </s.Item>
                  {loading && (
                    <s.SpinnerWrapper>
                      <Spinner color="#0016D1" />
                    </s.SpinnerWrapper>
                  )}
                  {!loading && errorMsg.length > 0 && <Message success={false} description={errorMsg} />}
                  {!loading && disputesList.length === 0 && <EmptyList />}
                  {!loading &&
                    disputesList.length > 0 &&
                    disputesList.map((dispute) => (
                      <s.Item className="active-item" key={dispute.disputeId}>
                        <s.ItemRow>
                          <s.ItemCol>
                            <s.Media>
                              <s.FeatureIcon>
                                <UserIcon className="user-icon" />
                              </s.FeatureIcon>
                              <div>
                                <s.OrderIdBtn onClick={(e) => toggleDetailsView(e, dispute)}>
                                  {dispute.internalOrderId}
                                </s.OrderIdBtn>
                              </div>
                            </s.Media>
                          </s.ItemCol>
                          <s.ItemCol>
                            <s.Item className="text-left mt-1">
                              <s.Price>{dispute.customerEmail}</s.Price>
                            </s.Item>
                          </s.ItemCol>
                          <s.ItemCol>
                            <s.Item className="text-left mt-1">
                              <s.Price>{dispute.payType}</s.Price>
                            </s.Item>
                          </s.ItemCol>
                          <s.ItemCol>
                            <s.ItemRow className="text-left mt-1">
                              <s.Price>{toCurrency(dispute.amount, dispute.currency)}</s.Price>
                            </s.ItemRow>
                          </s.ItemCol>
                          <s.ItemCol>
                            <s.Item className="text-left mt-1">
                              <s.Price className="price">
                                {
                                  getDateFormat({
                                    time: dispute.createdAt,
                                    showComma: false,
                                    showFullYear: true,
                                    showDaySuffix: false,
                                  }).formatted
                                }
                              </s.Price>
                            </s.Item>
                          </s.ItemCol>
                          <s.ItemCol className="fixed">
                            <s.ItemActionsDropdown
                              className="item-actions-dropdown"
                              onClick={(e) => toggleDropdown(e, dispute.disputeId)}
                            >
                              <DotsIcon className="dots-icon" />
                            </s.ItemActionsDropdown>
                            <s.ItemActionsBlock
                              active={activeDisputeId === dispute.disputeId}
                              className="item-actions-block"
                            >
                              <s.ItemActionsListLink
                                className="item-actions-link-btn"
                                onClick={(e) => toggleDetailsView(e, dispute)}
                              >
                                View details
                              </s.ItemActionsListLink>
                            </s.ItemActionsBlock>
                          </s.ItemCol>
                        </s.ItemRow>
                      </s.Item>
                    ))}
                </s.ItemList>
              </s.Card>
            </LpBox>
          </Content>
          <Pagination
            pageCount={pageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
      {showDetailsView && (
        <>
          <HeaderTitle title="Dispute plan details" />
          <BackComponent text="Back to disputes" handleClickBack={handleClickBack} />
          <DisputeDetails
            apiBaseUri={apiBaseUri}
            merchantId={activeDispute?.merchantId ?? ''}
            txId={activeDispute?.transactionId ?? ''}
          />
        </>
      )}
    </>
  );
};

export default Disputes;
