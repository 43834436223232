import 'react-phone-number-input/style.css';

import Alert from 'Components/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails, P, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { getInitialPublicInfoValues } from './getInitialValues';
import { EditBtn, InvalidText, PhoneNoWrapper, SpinnerWrapper } from './styles';
import { PublicInfoAPIResponse } from './types';

const PublicInformation = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [values, setValues] = useState<PublicInfoAPIResponse>(() => getInitialPublicInfoValues());
  const [localValues, setLocalValues] = useState<PublicInfoAPIResponse>(() => getInitialPublicInfoValues());
  const [timestamp, setTimestamp] = useState<number>(0);
  const [validPhoneNo, setValidPhoneNo] = useState<boolean>(true);

  const { apiBaseUri, merchantId } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.activeMerchantId,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchPublicInfo = async () => {
      setIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((res) => {
          setValues(res);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchants public information');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchPublicInfo();
  }, [apiBaseUri, merchantId, timestamp]);

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('hidden');
    setShowModal(true);
    setLocalValues(values);
    setModalErrorMsg('');
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowModal(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const phoneNo = parsePhoneNumber(localValues.phoneNo ?? '');
    if (!phoneNo || !phoneNo.country) {
      setValidPhoneNo(false);
      return;
    }

    setModalIsLoading(true);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
    const options = await getFetchOptions('POST', JSON.stringify(localValues));
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setModalErrorMsg('');
        setBodyOverflow('auto');
        setShowModal(false);
        setTimestamp(Date.now());
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to update merchants public information');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    console.log('name:', e.target.name, ', value:', e.target.value);
    setLocalValues((prev: PublicInfoAPIResponse) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePhoneNo = (value: string = ''): void => {
    const isValid = isValidPhoneNumber(value);
    setValidPhoneNo(isValid);
    setLocalValues((prev: PublicInfoAPIResponse) => ({
      ...prev,
      phoneNo: value,
    }));
  };

  return (
    <LpBox className="lp-box public-info">
      <Row>
        <div className="col-sm-7">
          <H2 className="mb-0">Public information</H2>
          <P className="mb-0 mb-md-3">This information is visible to your customers.</P>
        </div>
        <div className="col-sm-5 text-right">
          <EditBtn onClick={toggleModal}>Edit public information</EditBtn>
        </div>
      </Row>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {!isLoading && errorMsg.length === 0 && (
        <LpDetails>
          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Business name</Label>
            </div>
            <div className="col-sm-8">{values.businessName}</div>
          </Row>
          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Trading/brand name</Label>
            </div>
            <div className="col-sm-8">{values.brandName}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Website</Label>
            </div>
            <div className="col-sm-8">{values.website}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Card statement name</Label>
            </div>
            <div className="col-sm-8">{values.cardStatementName}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Support site</Label>
            </div>
            <div className="col-sm-8">{values.supportSite}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Contact email</Label>
            </div>
            <div className="col-sm-8">{values.contactEmail}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Support email</Label>
            </div>
            <div className="col-sm-8">{values.supportEmail}</div>
          </Row>

          <Row className="row pt-4">
            <div className="col-sm-4">
              <Label>Phone number</Label>
            </div>
            <div className="col-sm-8">{values.phoneNo}</div>
          </Row>

          <Row className="pt-4">
            <div className="col-sm-4">
              <Label>Address</Label>
            </div>
            <div className="col-sm-8">
              <div>{values.addressLine1}</div>
              <div>{values.addressLine2}</div>
              <span className="mr-2">{values.city}</span>
              <span className="mr-2">{values.state}</span>
              <span>{values.postalCode}</span>
            </div>
          </Row>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit public information"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
        >
          {modalIsLoading && (
            <div className="text-center">
              <Spinner color="#0016D1" />
            </div>
          )}
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          <LpDetails className="pt-2 pb-0">
            <div className="form-item">
              <div className="form-field">
                <div className="pt-2">
                  <Label>Business name</Label>
                  <Input
                    type="text"
                    name="businessName"
                    value={localValues.businessName ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Trading/brand name</Label>
                  <Input
                    type="text"
                    name="brandName"
                    value={localValues.brandName ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Website</Label>
                  <Input
                    type="text"
                    name="website"
                    value={localValues.website ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Card statement name</Label>
                  <Input
                    type="text"
                    name="cardStatementName"
                    value={localValues.cardStatementName ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Support size</Label>
                  <Input
                    type="text"
                    name="supportSite"
                    value={localValues.supportSite ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Contact email</Label>
                  <Input
                    type="email"
                    name="contactEmail"
                    value={localValues.contactEmail ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Support email</Label>
                  <Input
                    type="email"
                    name="supportEmail"
                    value={localValues.supportEmail ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2">
                  <Label>Phone number</Label>
                  <PhoneNoWrapper>
                    <PhoneInput defaultCountry="AU" value={localValues.phoneNo ?? ''} onChange={handleChangePhoneNo} />
                  </PhoneNoWrapper>
                  {!validPhoneNo && <InvalidText>Please select country and input correct phone number</InvalidText>}
                </div>
                <div className="pt-2">
                  <Label>Address</Label>

                  <Input
                    type="text"
                    name="addressLine1"
                    placeholder="Address line 1"
                    value={localValues.addressLine1 ?? ''}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    name="addressLine2"
                    className="mt-1"
                    placeholder="Address line 2"
                    value={localValues.addressLine2 ?? ''}
                    onChange={handleChange}
                  />
                  <Row className="mt-1 mb-4">
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={localValues.city ?? ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={localValues.state ?? ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        name="postalCode"
                        placeholder="Post Code"
                        value={localValues.postalCode ?? ''}
                        onChange={handleChange}
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </LpDetails>
        </Modal>
      )}
    </LpBox>
  );
};

export default PublicInformation;
