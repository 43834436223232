import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { Row } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fromCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import validateEmail from 'utils/validateEmail';

import AddedCard from './AddedCard';
import PercentageAndFixed from './PercentageAndFixed';
import getFees from './getFees';
import { getCardValueByProfile, getPayPlanLimitByProfile } from './getValuesByProfile';
import {
  AddCardBtn,
  BtnWrapper,
  ClearBtn,
  Container,
  ContainerTitle,
  DashHr,
  FieldBlob,
  FixedInput,
  FormControl,
  FormItem,
  HeavyText,
  Hr,
  Label,
  LimitField,
  LpBoxSignUp,
  MessageBar,
  RadioCircle,
  RadioGroup,
  RadioInput,
  RadioLabel,
  SignUpBtn,
  SpinnerWrapper,
  SubTitle,
  Title,
  VInput,
} from './styles';
import {
  CalculateTaxOnFeeType,
  CardType,
  CountryType,
  PayPlanLimitType,
  PaymentProfileEnum,
  PaymentType,
  ProfileType,
  TaxCountryType,
  defaultCardValue,
} from './types';

const OnboardMerchant = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [validSlug, setValidSlug] = useState<boolean>(true);
  const [validBusinessName, setValidBusinessName] = useState<boolean>(true);
  const [validContactEmail, setValidContactEmail] = useState<boolean>(true);

  const [calculateTaxOnFee, setCalculateTaxOnFee] = useState<CalculateTaxOnFeeType>(CalculateTaxOnFeeType.TRUE);
  const [taxCountry, setTaxCountry] = useState<TaxCountryType>(TaxCountryType.AU);
  const [tradingCountry, setTradingCountry] = useState<TaxCountryType | null>(null);
  const [paymentProfileType, setPaymentProfileType] = useState<PaymentProfileEnum | null>(null);

  const [profile, setProfile] = useState<ProfileType>(ProfileType.standard);
  const [slug, setSlug] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');

  const [payPlanLimit, setPayPlanLimit] = useState<PayPlanLimitType>(() =>
    getPayPlanLimitByProfile(ProfileType.standard),
  );

  const [paymentType, setPaymentType] = useState<PaymentType>('payPlan');
  const [countryType, setCountryType] = useState<CountryType>('domestic');

  const [payPlanDom, setPayPlanDom] = useState<CardType[]>([]);
  const [payPlanInt, setPayPlanInt] = useState<CardType[]>([]);
  const [payCardDom, setPayCardDom] = useState<CardType[]>([]);
  const [payCardInt, setPayCardInt] = useState<CardType[]>([]);

  const [payPlanDomPercent, setPayPlanDomPercent] = useState<string>('');
  const [payPlanIntPercent, setPayPlanIntPercent] = useState<string>('');
  const [payCardDomPercent, setPayCardDomPercent] = useState<string>('');
  const [payCardIntPercent, setPayCardIntPercent] = useState<string>('');

  const [payPlanDomFix, setPayPlanDomFix] = useState<string>('');
  const [payPlanIntFix, setPayPlanIntFix] = useState<string>('');
  const [payCardDomFix, setPayCardDomFix] = useState<string>('');
  const [payCardIntFix, setPayCardIntFix] = useState<string>('');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [sendOnboardingEmail, setSendOnboardingEmail] = useState<string>('YES');

  const { apiBaseUri, marketPlaceId } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    marketPlaceId: state.marketPlaceId,
  }));

  useEffect(() => {
    document.title = 'Onboard Merchant - Limepay';
  }, []);

  const handleConcat = (prevCards: CardType[], array: CardType[]): CardType[] => {
    const index = prevCards.findIndex((card) => card.cardBrand === 'American Express' && card.cardFunding === 'credit');
    if (index === -1) {
      return prevCards.concat(array);
    } else {
      const tempCards = [...prevCards.slice(0, index), ...prevCards.slice(index + 1)];
      return tempCards.concat(array);
    }
  };

  useEffect(() => {
    setPayPlanLimit(getPayPlanLimitByProfile(profile));
    switch (profile) {
      case ProfileType.standard:
        setCalculateTaxOnFee(CalculateTaxOnFeeType.TRUE);
        const array = getCardValueByProfile(ProfileType.standard);
        setPayPlanDom([]);
        setPayPlanInt([]);
        setPayCardDom((prevCards) => handleConcat(prevCards, array));
        setPayCardInt([]);

        setPayPlanDomPercent('4');
        setPayPlanIntPercent('4');
        setPayCardDomPercent('1.5');
        setPayCardIntPercent('2.9');
        setPayPlanDomFix('0.3');
        setPayPlanIntFix('0.3');
        setPayCardDomFix('0.3');
        setPayCardIntFix('0.3');
        break;
      case ProfileType.MarketNowZeroFees:
        setCalculateTaxOnFee(CalculateTaxOnFeeType.TRUE);
        setPayPlanDom([]);
        setPayPlanInt([]);
        setPayCardDom([]);
        setPayCardInt([]);

        setPayPlanDomPercent('0');
        setPayPlanIntPercent('0');
        setPayCardDomPercent('0');
        setPayCardIntPercent('0');
        setPayPlanDomFix('0');
        setPayPlanIntFix('0');
        setPayCardDomFix('0');
        setPayCardIntFix('0');
        break;

      case ProfileType.MarketNowNewFees:
        setCalculateTaxOnFee(CalculateTaxOnFeeType.FALSE);

        const cardArray = getCardValueByProfile(ProfileType.MarketNowNewFees);
        setPayPlanDom([]);
        setPayPlanInt([]);
        setPayCardDom(cardArray);
        setPayCardInt(cardArray);

        setPayPlanDomPercent('0');
        setPayPlanIntPercent('0');
        setPayCardDomPercent('0.79');
        setPayCardIntPercent('0.79');
        setPayPlanDomFix('0');
        setPayPlanIntFix('0');
        setPayCardDomFix('0');
        setPayCardIntFix('0');
        break;
    }
  }, [profile]);

  const currentTradingCountry: TaxCountryType = tradingCountry === null ? taxCountry : tradingCountry;

  useEffect(() => {
    setPaymentProfileType(null);
  }, [currentTradingCountry]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleConfirm = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleAddCard = (e: MouseEvent<HTMLButtonElement>, payment: PaymentType, country: CountryType): void => {
    e.preventDefault();

    const array = [{ ...defaultCardValue, time: Date.now() }];
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        setPayPlanDom((prev) => prev.concat(array));
      } else {
        setPayPlanInt((prev) => prev.concat(array));
      }
    } else {
      if (country === 'domestic') {
        setPayCardDom((prev) => prev.concat(array));
      } else {
        setPayCardInt((prev) => prev.concat(array));
      }
    }

    setShowModal(true);
    setPaymentType(payment);
    setCountryType(country);
    setBodyOverflow('hidden');
  };

  const handleDeleteCard = (
    e: MouseEvent<HTMLButtonElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    e.preventDefault();
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = payPlanDom.filter((c) => c.time !== time);
        setPayPlanDom(cards);
      } else {
        const cards = payPlanInt.filter((c) => c.time !== time);
        setPayPlanInt(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = payCardDom.filter((c) => c.time !== time);
        setPayCardDom(cards);
      } else {
        const cards = payCardInt.filter((c) => c.time !== time);
        setPayCardInt(cards);
      }
    }
  };

  const handleChangeCardFunding = (
    e: ChangeEvent<HTMLSelectElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayPlanDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayPlanInt(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayCardDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardFunding = value;
          }
        });
        setPayCardInt(cards);
      }
    }
  };

  const handleChangeCardBrand = (
    e: ChangeEvent<HTMLSelectElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayPlanDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayPlanInt(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayCardDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.cardBrand = value;
          }
        });
        setPayCardInt(cards);
      }
    }
  };

  const handleChangeCardPercent = (
    e: ChangeEvent<HTMLInputElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayPlanDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayPlanInt(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayCardDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.perc = value;
          }
        });
        setPayCardInt(cards);
      }
    }
  };

  const handleChangeCardFix = (
    e: ChangeEvent<HTMLInputElement>,
    payment: PaymentType,
    country: CountryType,
    time: number,
  ) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payPlanDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayPlanDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payPlanInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayPlanInt(cards);
      }
    } else {
      if (country === 'domestic') {
        const cards = JSON.parse(JSON.stringify(payCardDom));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayCardDom(cards);
      } else {
        const cards = JSON.parse(JSON.stringify(payCardInt));
        cards.forEach((c: CardType) => {
          if (c.time === time) {
            c.fixed = value;
          }
        });
        setPayCardInt(cards);
      }
    }
  };

  const handleChangePercent = (e: ChangeEvent<HTMLInputElement>, payment: PaymentType, country: CountryType) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        setPayPlanDomPercent(value);
      } else {
        setPayPlanIntPercent(value);
      }
    } else {
      if (country === 'domestic') {
        setPayCardDomPercent(value);
      } else {
        setPayCardIntPercent(value);
      }
    }
  };

  const handleChangeFix = (e: ChangeEvent<HTMLInputElement>, payment: PaymentType, country: CountryType) => {
    const { value } = e.target;
    if (payment === 'payPlan') {
      if (country === 'domestic') {
        setPayPlanDomFix(value);
      } else {
        setPayPlanIntFix(value);
      }
    } else {
      if (country === 'domestic') {
        setPayCardDomFix(value);
      } else {
        setPayCardIntFix(value);
      }
    }
  };

  const clearAllData = () => {
    setErrorMsg('');
    setIsLoading(false);

    setValidSlug(true);
    setValidContactEmail(true);
    setValidBusinessName(true);
    setSlug('');
    setBusinessName('');
    setContactEmail('');
    setProfile(ProfileType.standard);

    setPayPlanDomPercent('4');
    setPayPlanIntPercent('4');
    setPayCardDomPercent('1.5');
    setPayCardIntPercent('2.9');
    setPayPlanDomFix('0.3');
    setPayPlanIntFix('0.3');
    setPayCardDomFix('0.3');
    setPayCardIntFix('0.3');

    setTaxCountry(TaxCountryType.AU);
    setTradingCountry(null);
    setPaymentProfileType(null);

    const array = getCardValueByProfile(ProfileType.standard);
    setPayPlanDom([]);
    setPayPlanInt([]);
    setPayCardDom(array);
    setPayCardInt([]);
  };

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSlug(value);
    setValidSlug(value.length >= 4 && value.length <= 255 && /^[a-z0-9]+$/.test(value));
  };

  const handleChangeBusinessName = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setBusinessName(value);
    setValidBusinessName(value.length > 0);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setContactEmail(value);
    setValidContactEmail(validateEmail(value));
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setErrorMsg('');

    let isReady = true;
    if (slug.length < 4 || slug.length > 256 || !/^[a-z0-9]+$/.test(slug)) {
      setValidSlug(false);
      isReady = false;
    }

    if (businessName.length === 0) {
      setValidBusinessName(false);
      isReady = false;
    }

    if (!validateEmail(contactEmail)) {
      setValidContactEmail(false);
      isReady = false;
    }

    if (!paymentProfileType) {
      setErrorMsg('Payment profile type required');
      isReady = false;
    }

    if (!isReady) {
      return;
    }

    const remoteAuthorityCode = profile === ProfileType.standard ? null : 'realpay';
    const { maxPayPlanAmountUnconfirmedCustomers, maxPayPlanAmountConfirmedCustomers, minPlanLimit } = payPlanLimit;
    const body = {
      slug,
      contactEmail,
      businessName,
      remoteAuthorityCode,
      feeSettings: {
        calculateTaxOnFee: calculateTaxOnFee === CalculateTaxOnFeeType.TRUE,
        taxCountry,
        feeStruct: {
          split: {
            domestic: getFees(payPlanDomPercent, payPlanDomFix, payPlanDom),
            international: getFees(payPlanIntPercent, payPlanIntFix, payPlanInt),
          },
          full: {
            domestic: getFees(payCardDomPercent, payCardDomFix, payCardDom),
            international: getFees(payCardIntPercent, payCardIntFix, payCardInt),
          },
        },
      },
      payPlanLimit: {
        maxPayPlanAmountUnconfirmedCustomers: fromCurrency(String(maxPayPlanAmountUnconfirmedCustomers)),
        maxPayPlanAmountConfirmedCustomers: fromCurrency(String(maxPayPlanAmountConfirmedCustomers)),
        minPlanLimit: fromCurrency(String(minPlanLimit)),
      },
      marketplaceId: profile === ProfileType.standard ? null : marketPlaceId,
      onboardingProfile: {
        paymentProfileType,
      },
      sendOnboardingEmail: sendOnboardingEmail === 'YES',
      tradingCountry,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));
    setIsLoading(true);
    const url = `${apiBaseUri}/onboarding/merchants/create`;
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setHasSuccess(true);
        clearAllData();
      })
      .catch((e) => {
        setErrorMsg(e.message || 'Failed to create account');
        setHasSuccess(false);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  };

  const handleChangePayPlanLimit = (e: ChangeEvent<HTMLInputElement>): void => {
    setPayPlanLimit((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTaxCalculation = (e: ChangeEvent<HTMLInputElement>): void => {
    setCalculateTaxOnFee(e.target.value as CalculateTaxOnFeeType);
  };

  const handleClearData = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    clearAllData();
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
    setHasSuccess(false);
  };

  return (
    <>
      <Title>Onboard Merchant</Title>
      <SubTitle>Please complete the following details to create a new merchant account.</SubTitle>
      {errorMsg.length > 0 && (
        <MessageBar>
          <Message
            data-testid="merchant-onboard-error-msg"
            success={false}
            description={errorMsg}
            handleClose={handleCloseMsg}
          />
        </MessageBar>
      )}
      {hasSuccess && (
        <MessageBar>
          <Message
            data-testid="merchant-onboard-success-msg"
            success
            description="Create account successfully!"
            handleClose={handleCloseMsg}
          />
        </MessageBar>
      )}
      <Container>
        <LpBoxSignUp>
          <ContainerTitle>Merchant details</ContainerTitle>
          <form>
            <FormItem>
              <Label>
                Unique URL (e.g. limepay.com.au/<HeavyText>uniqueurl</HeavyText>)
              </Label>
              <FieldBlob>
                Must be 4-255 characters, lowercase, alphanumeric without any special characters such as ! @ # & ( ) – [{' '}
                {} ] : ; ‘, ? /. <br />
                Please ensure the correct unique URL is provided as this cannot be changed.
              </FieldBlob>
              <VInput
                isValid={validSlug}
                type="text"
                required
                data-testid="slug"
                value={slug}
                onChange={handleChangeSlug}
              />
            </FormItem>
            <FormItem>
              <Label>Business name (as linked to ABN/ACN or NZBN)</Label>
              <VInput
                type="text"
                isValid={validBusinessName}
                required
                data-testid="businessName"
                value={businessName}
                onChange={handleChangeBusinessName}
              />
            </FormItem>
            <FormItem>
              <Label>Login email</Label>
              <VInput
                type="email"
                isValid={validContactEmail}
                required
                data-testid="contactEmail"
                value={contactEmail}
                onChange={handleChangeEmail}
              />
            </FormItem>
            <FormItem>
              <Label>Merchant profile</Label>
              <FormControl
                data-testid="remoteAuthorityCode"
                value={profile}
                onChange={(e) => setProfile(e.target.value as ProfileType)}
              >
                <option data-testid="remoteAuthorityCodeOption1" value={ProfileType.standard}>
                  Limepay Core - Standard
                </option>
                <option data-testid="remoteAuthorityCodeOption2" value={ProfileType.MarketNowZeroFees}>
                  MarketNow - Zero fees
                </option>
                <option data-testid="remoteAuthorityCodeOption3" value={ProfileType.MarketNowNewFees}>
                  MarketNow - New fees
                </option>
              </FormControl>
            </FormItem>
            <FormItem>
              <Label>Send onboarding email</Label>
              <FormControl
                data-testid="sendOnboardingEmail"
                value={sendOnboardingEmail}
                onChange={(e) => setSendOnboardingEmail(e.target.value)}
              >
                <option data-testid="sendOnboardingEmailOption1" value="YES">
                  Yes
                </option>
                <option data-testid="sendOnboardingEmailOption2" value="NO">
                  No
                </option>
              </FormControl>
            </FormItem>
            <Hr />
            <FormItem>
              <ContainerTitle>Payment fees</ContainerTitle>
              <Row>
                <div className="col-6">
                  <Label>Split payment: Domestic</Label>
                  <PercentageAndFixed
                    payment="payPlan"
                    country="domestic"
                    percent={payPlanDomPercent}
                    fixed={payPlanDomFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                  />
                  {payPlanDom.map((card, index) => (
                    <AddedCard
                      key={card.time}
                      index={index}
                      card={card}
                      payment="payPlan"
                      country="domestic"
                      handleDeleteCard={handleDeleteCard}
                      handleChangeCardFunding={handleChangeCardFunding}
                      handleChangeCardBrand={handleChangeCardBrand}
                      handleChangeCardPercent={handleChangeCardPercent}
                      handleChangeCardFix={handleChangeCardFix}
                      showModal={showModal && paymentType === 'payPlan' && countryType === 'domestic'}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                    />
                  ))}
                  <AddCardBtn
                    data-testid="payPlan-domestic-addCard"
                    onClick={(e) => handleAddCard(e, 'payPlan', 'domestic')}
                  >
                    Add new card rate
                  </AddCardBtn>
                </div>

                <div className="col-6">
                  <Label>Split payment: International</Label>
                  <PercentageAndFixed
                    payment="payPlan"
                    country="international"
                    percent={payPlanIntPercent}
                    fixed={payPlanIntFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                  />
                  {payPlanInt.map((card, index) => (
                    <AddedCard
                      key={card.time}
                      index={index}
                      card={card}
                      payment="payPlan"
                      country="international"
                      handleDeleteCard={handleDeleteCard}
                      handleChangeCardFunding={handleChangeCardFunding}
                      handleChangeCardBrand={handleChangeCardBrand}
                      handleChangeCardPercent={handleChangeCardPercent}
                      handleChangeCardFix={handleChangeCardFix}
                      showModal={showModal && paymentType === 'payPlan' && countryType === 'international'}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                    />
                  ))}
                  <AddCardBtn
                    data-testid="payPlan-international-addCard"
                    onClick={(e) => handleAddCard(e, 'payPlan', 'international')}
                  >
                    Add new card rate
                  </AddCardBtn>
                </div>
              </Row>
              <DashHr />
              <Row>
                <div className="col-6">
                  <Label>Full payment: Domestic</Label>
                  <PercentageAndFixed
                    payment="payCard"
                    country="domestic"
                    percent={payCardDomPercent}
                    fixed={payCardDomFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                  />
                  {payCardDom.map((card, index) => (
                    <AddedCard
                      key={card.time}
                      index={index}
                      card={card}
                      payment="payCard"
                      country="domestic"
                      handleDeleteCard={handleDeleteCard}
                      handleChangeCardFunding={handleChangeCardFunding}
                      handleChangeCardBrand={handleChangeCardBrand}
                      handleChangeCardPercent={handleChangeCardPercent}
                      handleChangeCardFix={handleChangeCardFix}
                      showModal={showModal && paymentType === 'payCard' && countryType === 'domestic'}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                    />
                  ))}
                  <AddCardBtn
                    data-testid="payCard-domestic-addCard"
                    onClick={(e) => handleAddCard(e, 'payCard', 'domestic')}
                  >
                    Add new card rate
                  </AddCardBtn>
                </div>
                <div className="col-6">
                  <Label>Full payment: International</Label>
                  <PercentageAndFixed
                    payment="payCard"
                    country="international"
                    percent={payCardIntPercent}
                    fixed={payCardIntFix}
                    handleChangePercent={handleChangePercent}
                    handleChangeFix={handleChangeFix}
                  />
                  {payCardInt.map((card, index) => (
                    <AddedCard
                      key={card.time}
                      index={index}
                      card={card}
                      payment="payCard"
                      country="international"
                      handleDeleteCard={handleDeleteCard}
                      handleChangeCardFunding={handleChangeCardFunding}
                      handleChangeCardBrand={handleChangeCardBrand}
                      handleChangeCardPercent={handleChangeCardPercent}
                      handleChangeCardFix={handleChangeCardFix}
                      showModal={showModal && paymentType === 'payCard' && countryType === 'international'}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                    />
                  ))}
                  <AddCardBtn
                    data-testid="payCard-international-addCard"
                    onClick={(e) => handleAddCard(e, 'payCard', 'international')}
                  >
                    Add new card rate
                  </AddCardBtn>
                </div>
              </Row>
            </FormItem>
            <Hr />
            <FormItem>
              <ContainerTitle>Tax</ContainerTitle>
              <Row>
                <div className="col-6">
                  <Label>Tax country</Label>
                  <FormControl
                    data-testid="taxCountry"
                    value={taxCountry}
                    onChange={(e) => setTaxCountry(e.target.value as TaxCountryType)}
                  >
                    <option value={TaxCountryType.AU}>Australia</option>
                    <option value={TaxCountryType.NZ}>New Zealand</option>
                  </FormControl>
                  <div className="mt-4">
                    <Label>Trading country</Label>
                    <FormControl
                      data-testid="tradingCountry"
                      value={tradingCountry ?? ''}
                      onChange={(e) => setTradingCountry((e.target.value as TaxCountryType) || null)}
                    >
                      <option value="">Same as tax country</option>
                      <option value={TaxCountryType.AU}>Australia</option>
                      <option value={TaxCountryType.NZ}>New Zealand</option>
                    </FormControl>
                  </div>
                </div>
                <div className="col-6">
                  <Label>Do the payment fees listed above include tax?</Label>
                  <RadioGroup>
                    <RadioLabel>
                      <RadioCircle isSelected={calculateTaxOnFee === CalculateTaxOnFeeType.FALSE} />
                      <RadioInput
                        type="radio"
                        value={CalculateTaxOnFeeType.FALSE}
                        name="calculateTax"
                        onChange={handleChangeTaxCalculation}
                      />
                      Included
                    </RadioLabel>
                    <RadioLabel>
                      <RadioCircle isSelected={calculateTaxOnFee === CalculateTaxOnFeeType.TRUE} />
                      <RadioInput
                        type="radio"
                        value={CalculateTaxOnFeeType.TRUE}
                        name="calculateTax"
                        onChange={handleChangeTaxCalculation}
                      />
                      Excluded
                    </RadioLabel>
                  </RadioGroup>
                </div>
                <div className="col-6 mt-4">
                  <Label>Payment profile type</Label>
                  <FormControl
                    data-testid="paymentProfileType"
                    value={paymentProfileType ?? ''}
                    onChange={(e) => setPaymentProfileType(e.target.value as PaymentProfileEnum)}
                  >
                    <option value="">Select profile</option>
                    {currentTradingCountry === TaxCountryType.AU && (
                      <>
                        <option value={PaymentProfileEnum.abacusWorldpayAu}>Worldpay AU</option>
                        <option value={PaymentProfileEnum.abacusWorldpayStripeCompositeAu}>Worldpay + Stripe AU</option>
                        <option value={PaymentProfileEnum.stripeConnectAuAbacus}>Stripe AU</option>
                      </>
                    )}
                    {currentTradingCountry === TaxCountryType.NZ && (
                      <>
                        <option value={PaymentProfileEnum.abacusWorldpayNz}>Worldpay NZ</option>
                        <option value={PaymentProfileEnum.abacusWorldpayStripeCompositeNz}>Worldpay + Stripe NZ</option>
                        <option value={PaymentProfileEnum.stripeConnectNzAbacus}>Stripe NZ</option>
                      </>
                    )}
                    <option value={PaymentProfileEnum.abacusCheckout}>Checkout</option>
                    <option value={PaymentProfileEnum.abacusCheckoutStripeComposite}>Checkout + Stripe</option>
                  </FormControl>
                </div>
              </Row>
            </FormItem>
            <Hr />
            <FormItem>
              <ContainerTitle>Pay plan limit</ContainerTitle>
              <Row>
                <div className="col-6">
                  <Label>Maximum limit: Unconfirmed customer</Label>
                  <LimitField>
                    <FixedInput
                      name="maxPayPlanAmountUnconfirmedCustomers"
                      type="text"
                      data-testid="maxPayPlanAmountUnconfirmedCustomers"
                      required
                      onChange={handleChangePayPlanLimit}
                      value={payPlanLimit.maxPayPlanAmountUnconfirmedCustomers}
                    />
                  </LimitField>
                </div>
                <div className="col-6">
                  <Label>Maximum limit: Confirmed customer</Label>
                  <LimitField>
                    <FixedInput
                      name="maxPayPlanAmountConfirmedCustomers"
                      type="text"
                      data-testid="maxPayPlanAmountConfirmedCustomers"
                      required
                      onChange={handleChangePayPlanLimit}
                      value={payPlanLimit.maxPayPlanAmountConfirmedCustomers}
                    />
                  </LimitField>
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-6">
                  <Label>Minimum purchase: All customers</Label>
                  <LimitField>
                    <FixedInput
                      name="minPlanLimit"
                      type="text"
                      data-testid="minPlanLimit"
                      required
                      onChange={handleChangePayPlanLimit}
                      value={payPlanLimit.minPlanLimit}
                    />
                  </LimitField>
                </div>
              </Row>
            </FormItem>
            <Hr />
            <BtnWrapper>
              <ClearBtn onClick={handleClearData}>Reset all data</ClearBtn>
              <SignUpBtn onClick={handleSubmit} disabled={isLoading}>
                Sign Up Merchant
                {isLoading && (
                  <SpinnerWrapper>
                    <Spinner height="20px" width="20px" borderWidth="2px" />
                  </SpinnerWrapper>
                )}
              </SignUpBtn>
            </BtnWrapper>
          </form>
        </LpBoxSignUp>
      </Container>
    </>
  );
};

export default OnboardMerchant;
