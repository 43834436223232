import React, { ReactElement } from 'react';

import { TitleText, Wrapper } from './styles';

export type HeaderTitleType = {
  title: string;
  id?: string;
};

const HeaderTitle = ({ title, id }: HeaderTitleType): ReactElement => (
  <Wrapper className="lp-dashboard-top header-title">
    <div className="title-block">
      <TitleText>
        {title} {id}
      </TitleText>
    </div>
  </Wrapper>
);

export default HeaderTitle;
