import Back from 'Components/BackComponent/BackComponent';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Content } from 'Constants/styles';
import qs from 'query-string';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import List from './List';
import MerchantProfile from './MerchantProfile';
import PayoutDetails from './PayoutDetails';
import * as s from './Payouts.styles';
import { PayoutAPIResponse, PayoutsType } from './types';

const Payouts = ({ setShowPayouts }: PayoutsType) => {
  const [detailsView, setDetailsView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [orders, setOrders] = useState<PayoutAPIResponse[]>([]);
  const [activeOrder, setActiveOrder] = useState<PayoutAPIResponse | null>(null);

  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);

  const history = useHistory();
  const { merchantId, merchantName } = qs.parse(window.location.search);
  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }
    const fetchPayouts = async () => {
      try {
        setIsLoading(true);
        const options = await getFetchOptions();
        const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payouts/query?sort=-createdAt&page=${activePage}`;
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        setPageCount(Number(res.headers.get('limepay-page-count')));
        setOrders(await res.json());
        setErrorMsg('');
      } catch (e) {
        setErrorMsg(e.message || 'Failed to fetch payouts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayouts();
  }, [activePage, apiBaseUri, merchantId]);

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    history.push('/admin-dashboard/payouts');
    setShowPayouts(false);
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <>
      {!detailsView && (
        <>
          <HeaderTitle title="Payouts" />
          <Back text="Back to merchants list" handleClickBack={handleClickBack} />

          <MerchantProfile merchantId={(merchantId as string) ?? ''} name={(merchantName as string) ?? ''} />
          {isLoading && (
            <s.SpinnerWrapper>
              <Spinner color="#0016D1" />
            </s.SpinnerWrapper>
          )}
          {errorMsg.length > 0 && (
            <Content>
              <Message success={false} handleClose={handleCloseMsg} description={errorMsg} />
            </Content>
          )}
          {!isLoading && (
            <>
              {orders.length > 0 && (
                <List
                  orders={orders}
                  setDetailsView={setDetailsView}
                  setActiveOrder={setActiveOrder}
                  apiBaseUri={apiBaseUri}
                  merchantId={(merchantId as string) ?? ''}
                />
              )}
              {orders.length === 0 && <EmptyList />}

              <Pagination
                pageCount={pageCount}
                activePage={activePage}
                setActivePage={setActivePage}
                pageStartIndex={pageStartIndex}
                setPageStartIndex={setPageStartIndex}
              />
            </>
          )}
        </>
      )}
      {detailsView && activeOrder && (
        <PayoutDetails
          setDetailsView={setDetailsView}
          apiBaseUri={apiBaseUri}
          merchantId={(merchantId as string) ?? ''}
          activeOrder={activeOrder}
        />
      )}
    </>
  );
};

export default Payouts;
