export const ListHeaders = [
  {
    className: 'item-col-lgr',
    text: 'Business name',
  },
  {
    className: 'custom-col-xsr',
    text: 'Website',
  },
  {
    className: 'custom-col-xsr',
    text: 'Slug',
  },
];
