import './OrderFilter.scss';

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import React, { ChangeEvent, MouseEvent, ReactElement, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import { filterStatusArray } from './Constants';

type Props = {
  isCheckedArray: boolean[];
  handleStatusOptionClick(e: ChangeEvent<HTMLInputElement>, index: number): void;
};

const OrderFilter = ({ isCheckedArray, handleStatusOptionClick }: Props): ReactElement => {
  const [showStatusOptions, setShowStatusOptions] = useState<boolean>(false);

  const statusRef = useRef<HTMLUListElement>(null);

  const handleClickOutsideStatusBtn = () => {
    setShowStatusOptions(false);
  };

  useClickOutside(statusRef, handleClickOutsideStatusBtn, ['outer-btn-status', 'tclick-status', 'arrow-down-status']);

  const handleStatusClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowStatusOptions((prev) => !prev);
  };

  return (
    <div className="lp-nav lp-filers merchants-order-filter">
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <li className="nav-item">Filter by:</li>
        <li className="nav-item">
          <button className="outer-btn out-btn-status" onClick={handleStatusClick}>
            <div className="payout-schedule tclick tclick-status">Status</div>
            <ArrowDown className={`arrow-down arrow-down-status ${showStatusOptions ? 'up' : ''}`} />
          </button>
          {showStatusOptions && (
            <ul className="payout-schedule-menu" ref={statusRef}>
              {filterStatusArray.map((filter, index) => (
                <li key={filter.text}>
                  <label className="lp-option">
                    {filter.text}
                    <input
                      type="checkbox"
                      checked={isCheckedArray[index]}
                      onChange={(event) => handleStatusOptionClick(event, index)}
                    />
                    <span className={`check-mark ${isCheckedArray[index] ? 'checked' : ''}`} />
                  </label>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default OrderFilter;
