import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import { Content } from 'Constants/styles';

import EmailReset from './EmailReset';
import PhoneReset from './PhoneReset';

const OTPResets = () => (
  <div className="mb-4">
    <HeaderTitle title="OTP Resets" />
    <Content>
      <EmailReset />
      <PhoneReset />
    </Content>
  </div>
);

export default OTPResets;
