import React, { ReactElement } from 'react';

import { Message, Wrapper } from './styles';

export type AlertProps = {
  message: string;
};

const Alert = ({ message }: AlertProps): ReactElement => (
  <Wrapper>
    <Message>{message}</Message>
  </Wrapper>
);

export default Alert;
