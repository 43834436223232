import * as Actions from 'redux/Constants/actionTypes';

export const updateLoggedIn = (isLoggedIn: boolean): ActionType => ({
  type: Actions.UPDATE_LOGGED_IN,
  payload: {
    isLoggedIn,
  },
});

export const setLoginPending = (isLoginPending: boolean): ActionType => ({
  type: Actions.SET_LOGIN_PENDING,
  payload: {
    isLoginPending,
  },
});

export const setConfig = (apiBaseUri: string, tenantId: string, marketPlaceId: string): ActionType => ({
  type: Actions.SET_CONFIG,
  payload: {
    apiBaseUri,
    tenantId,
    marketPlaceId,
  },
});

export const setMerchantDetails = (
  showMerchantDetailsView: boolean,
  activeMerchantId: string,
  businessName: string,
  stripeAccountId: string | null,
  brandName: string,
): ActionType => ({
  type: Actions.SET_MERCHANT_DETAILS,
  payload: {
    showMerchantDetailsView,
    activeMerchantId,
    businessName,
    stripeAccountId,
    brandName,
  },
});
