import Modal from 'Components/Modal/Modal';
import { Input, Row } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useState } from 'react';

import {
  CardWrapper,
  DeleteCardBtn,
  ErrorMsg,
  FieldWrapper,
  FixedField,
  FixedInput,
  FormControl,
  Label,
  PercentageField,
  Plus,
  Red,
} from './styles';
import { CardType, CountryType, PaymentType } from './types';

type AddedCardType = {
  index: number;
  card: CardType;
  payment: PaymentType;
  country: CountryType;
  handleDeleteCard(e: MouseEvent<HTMLButtonElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardFunding(e: ChangeEvent<HTMLSelectElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardBrand(e: ChangeEvent<HTMLSelectElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardPercent(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType, t: number): void;
  handleChangeCardFix(e: ChangeEvent<HTMLInputElement>, p: PaymentType, c: CountryType, t: number): void;
  showModal: boolean;
  handleCancel(e: MouseEvent<HTMLButtonElement>): void;
  handleConfirm(e: FormEvent<HTMLFormElement>): void;
};

const PaymentTitleObj = {
  payPlan: 'Split payment',
  payCard: 'Full payment',
};

const CountryTitleObj = {
  domestic: 'Domestic',
  international: 'International',
};

const getTitle = (payment: PaymentType, country: CountryType): string =>
  `${PaymentTitleObj[payment]}: ${CountryTitleObj[country]}`;

const getCardFunding = (cardFunding: string): string => {
  if (!cardFunding) {
    return '';
  }

  return cardFunding.charAt(0).toUpperCase() + cardFunding.slice(1);
};

const AddedCard = ({
  index,
  card,
  payment,
  country,
  handleDeleteCard,
  handleChangeCardFunding,
  handleChangeCardBrand,
  handleChangeCardPercent,
  handleChangeCardFix,
  showModal,
  handleCancel,
  handleConfirm,
}: AddedCardType): ReactElement => {
  const [errorWithFunding, setErrorWithFunding] = useState<boolean>(false);
  const [errorWithPerc, setErrorWithPerc] = useState<boolean>(false);
  const [errorWithFixed, setErrorWithFixed] = useState<boolean>(false);

  const handleCancelToDelete = (e: MouseEvent<HTMLButtonElement>, p: PaymentType, c: CountryType, t: number): void => {
    e.preventDefault();
    handleDeleteCard(e, p, c, t);
    handleCancel(e);
  };

  const handleConfirmWithFunding = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    let isReady = true;
    if (card.cardFunding === '') {
      setErrorWithFunding(true);
      isReady = false;
    }

    if (card.perc === '') {
      setErrorWithPerc(true);
      isReady = false;
    }

    if (card.fixed === '') {
      setErrorWithFixed(true);
      isReady = false;
    }

    if (!isReady) {
      return;
    }

    handleConfirm(e);
  };

  return (
    <CardWrapper>
      <Label>
        {getTitle(payment, country)} {getCardFunding(card.cardFunding)} {card.cardBrand}
      </Label>
      <FieldWrapper className="mt-2">
        <PercentageField>
          <Input
            type="text"
            required
            data-testid={`${payment}-${country}-card${index + 1}-percent`}
            value={card.perc}
            onChange={(e) => handleChangeCardPercent(e, payment, country, card.time)}
          />
        </PercentageField>
        <Plus>+</Plus>
        <FixedField>
          <FixedInput
            type="text"
            required
            data-testid={`${payment}-${country}-card${index + 1}-fixed`}
            value={card.fixed}
            onChange={(e) => handleChangeCardFix(e, payment, country, card.time)}
          />
        </FixedField>
        <DeleteCardBtn onClick={(e) => handleDeleteCard(e, payment, country, card.time)}>Delete card</DeleteCardBtn>
      </FieldWrapper>
      {showModal && (
        <Modal
          title={`Add new card rate for ${payment === 'payPlan' ? 'split payment' : 'full payment'} ${country}`}
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={(e) => handleCancelToDelete(e, payment, country, card.time)}
          handleSubmit={handleConfirmWithFunding}
        >
          <Label className="mt-3">
            Funding (<Red>*</Red>)
          </Label>
          <FormControl
            data-testid={`${payment}-${country}-card${index + 1}-funding`}
            value={card.cardFunding}
            onChange={(e) => handleChangeCardFunding(e, payment, country, card.time)}
          >
            <option data-testid={`${payment}-${country}-card${index + 1}-funding-credit`} value="credit">
              Credit
            </option>
            <option data-testid={`${payment}-${country}-card${index + 1}-funding-debit`} value="debit">
              Debit
            </option>
          </FormControl>
          {errorWithFunding && <ErrorMsg>Card funding cannot be empty</ErrorMsg>}
          <Label className="mt-3">Branding</Label>
          <FormControl
            data-testid={`${payment}-${country}-card${index + 1}-branding`}
            value={card.cardBrand}
            onChange={(e) => handleChangeCardBrand(e, payment, country, card.time)}
          >
            <option data-testid={`${payment}-${country}-card${index + 1}-branding-option1`} value="UndefinedBrand">
              -
            </option>
            <option data-testid={`${payment}-${country}-card${index + 1}-branding-option2`} value="MasterCard">
              MasterCard
            </option>
            <option data-testid={`${payment}-${country}-card${index + 1}-branding-option3`} value="Visa">
              Visa
            </option>
            <option data-testid={`${payment}-${country}-card${index + 1}-branding-option4`} value="American Express">
              American Express
            </option>
          </FormControl>
          <Row>
            <div className="col-6">
              <Label className="mt-3">
                percentage(<Red>*</Red>)
              </Label>
              <PercentageField style={{ width: 'auto' }}>
                <Input
                  type="text"
                  value={card.perc}
                  data-testid={`${payment}-${country}-card${index + 1}-percent`}
                  onChange={(e) => handleChangeCardPercent(e, payment, country, card.time)}
                />
              </PercentageField>
              {errorWithPerc && <ErrorMsg>Card percentage cannot be empty</ErrorMsg>}
            </div>
            <div className="col-6">
              <Label className="mt-3">
                fixed(<Red>*</Red>)
              </Label>
              <FixedField style={{ width: 'auto' }}>
                <FixedInput
                  type="text"
                  value={card.fixed}
                  data-testid={`${payment}-${country}-card${index + 1}-fixed`}
                  onChange={(e) => handleChangeCardFix(e, payment, country, card.time)}
                />
              </FixedField>
              {errorWithFixed && <ErrorMsg>Card fixed cannot be empty</ErrorMsg>}
            </div>
          </Row>
        </Modal>
      )}
    </CardWrapper>
  );
};

export default AddedCard;
