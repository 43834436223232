import Alert from 'Components/Alert';
import CompanyDetail from 'Components/CompanyDetail/CompanyDetail';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { Input } from 'Constants/styles';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import Companies from './Companies';
import OrderFilter from './OrderFilter';
import { SearchWrapper, SpinnerWrapper, Wrapper } from './styles';
import { MerchantAPIResponseType } from './types';

const Merchants = (): ReactElement => {
  const [merchantsList, setMerchantsList] = useState<MerchantAPIResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [businessName, setBusinessName] = useState<string>('');
  const [isCheckedArray, setIsCheckedArray] = useState<boolean[]>([false, false, false]);

  const { apiBaseUri, showMerchantDetailsView } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    showMerchantDetailsView: state.showMerchantDetailsView,
  }));

  const debouncedSearch = useDebounce(businessName);

  useEffect(() => {
    document.title = 'Merchants - Limepay';
  }, []);

  useEffect(() => {
    if (!apiBaseUri || debouncedSearch !== businessName) {
      return;
    }

    const queryMerchants = async () => {
      let url = `${apiBaseUri}/merchants/query?sort=-createdAt&page=${activePage}`;
      if (debouncedSearch.length > 0) {
        url += `&businessName=${encodeURIComponent(debouncedSearch)}`;
      }
      if (isCheckedArray[1] && !isCheckedArray[2]) {
        url += `&isActivated=true`;
      }
      if (!isCheckedArray[1] && isCheckedArray[2]) {
        url += `&isActivated=false`;
      }

      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('limepay-page-count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setPageCount(pages);
          setMerchantsList(response);
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchants list');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    queryMerchants();
  }, [activePage, apiBaseUri, businessName, debouncedSearch, isCheckedArray]);

  const handleStatusOptionClick = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    if (index === 0) {
      setIsCheckedArray([checked, checked, checked]);
      return;
    }

    if (index === 1) {
      setIsCheckedArray((prev) => {
        if (prev[2] === checked) {
          return [checked, checked, checked];
        } else {
          return [false, checked, prev[2]];
        }
      });
    }

    if (index === 2) {
      setIsCheckedArray((prev) => {
        if (prev[1] === checked) {
          return [checked, checked, checked];
        } else {
          return [false, prev[1], checked];
        }
      });
    }
  };

  const handleChangeBusinessName = (e: ChangeEvent<HTMLInputElement>): void => {
    setBusinessName(e.target.value);
    setActivePage(1);
  };

  if (showMerchantDetailsView) {
    return <CompanyDetail />;
  }

  return (
    <Wrapper>
      <HeaderTitle title="Merchants" />
      <SearchWrapper>
        <Input type="text" placeholder="Search business name" onChange={handleChangeBusinessName} />
      </SearchWrapper>
      <OrderFilter isCheckedArray={isCheckedArray} handleStatusOptionClick={handleStatusOptionClick} />
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <>
          <Companies merchantsList={merchantsList} />
          <Pagination
            pageCount={pageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
    </Wrapper>
  );
};

export default Merchants;
