import styled from '@emotion/styled';

export const Aside = styled.aside`
  background-color: var(--lp-colors-medium-blue-600);
  border-right: 1px #e7e7e7 solid;
  width: 240px;
  padding-bottom: 60px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 3333;
`;

export const SidebarHeader = styled.div`
  position: relative;
  z-index: 100;
`;

export const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 51px;
  width: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Brand = styled.div`
  padding: 35px 0 9px 33px;
`;

export const SidebarMenu = styled.ul`
  font-size: 17px;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 23px;
`;

export const MenuLi = styled.li`
  padding: 10px 0;
  position: relative;
`;

type SidebarFooterProps = {
  showAccordion: boolean;
};

export const SidebarFooter = styled.footer<SidebarFooterProps>`
  position: fixed;
  bottom: 0;
  width: 240px;
  padding: 21px 0 30px 29px;
  background-color: ${({ showAccordion }) => (showAccordion ? 'var(--lp-colors-medium-blue-800)' : null)};
`;

export const UserImg = styled.div`
  padding-bottom: 8px;
`;

export const ImgText = styled.div`
  padding: 15px 0 13px 1px;
  background-color: var(--lp-colors-turquoise-600);
  color: var(--lp-colors-medium-blue-600);
  font-size: 14px;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0;
  border: 0;
`;

export const NavLinkBtn = styled.button`
  width: 100%;
  display: flex;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  font-weight: 300;
  font-size: 17px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
`;

export const BusinessName = styled.div`
  color: #fff;
`;

export const ProfileDropdownMenu = styled.div`
  padding-top: 15px;
`;

export const DropdownItemBtn = styled.button`
  padding: 12px 0;
  width: 100%;
  color: #fff;
  display: block;
  text-decoration: none;
  text-align: left;
  background: transparent;
  border: 0;
  font-size: 16px;
  cursor: pointer;
`;

type CollapseProps = {
  showAccordion: boolean;
};

export const Collapse = styled.div<CollapseProps>`
  height: ${({ showAccordion }) => (showAccordion ? '60px' : '0')};
  opacity: ${({ showAccordion }) => (showAccordion ? 1 : 0)};
  transition: height 0.3s ease-in-out 0s;
`;

export const Wrapper = styled.div`
  .menu-link {
    padding: 0 20px 0 30px;
    color: #fff;
    text-decoration: none;
    display: block;
    position: relative;

    &.selected:before {
      top: 0px;
      left: 5px;
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: #27cbcc;
    }
  }

  .arrow-down {
    width: 20px;
    fill: #fff;
    transition: transform 0.3s ease-in-out 0s;
    margin-left: auto;
    margin-right: 30px;

    &.up {
      transform: rotate(180deg);
    }
  }
`;
