import { LpBox } from 'Constants/styles';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';

import styled from '@emotion/styled';

const Content = styled.article`
  padding: 18px 60px 0;
  display: block;
`;

const User = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  .user {
    margin-top: 4px;
    margin-left: 6px;
  }
`;

const Name = styled.h1`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin: 0 0 0 10px;
  color: #354052;
  padding-top: 6px;
`;

const Id = styled.h5`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  color: #3a3e46;
  font-weight: 600;
  line-height: 1;
`;

const Flex = styled.div`
  display: flex;
`;

type MerchantProfileType = {
  merchantId: string | string[];
  name: string | string[];
};

const MerchantProfile = ({ merchantId, name }: MerchantProfileType) => (
  <Content>
    <LpBox>
      <Flex>
        <User>
          <UserIcon className="user" />
        </User>
        <Name>{name}</Name>
      </Flex>
      <Id>{merchantId}</Id>
    </LpBox>
  </Content>
);

export default MerchantProfile;
