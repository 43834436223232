import { Currency } from 'utils/currency';

export enum EntryType {
  Charge = 'Charge',
  PlanRefund = 'PlanRefund',
  Waiver = 'Waiver',
  Credit = 'Credit',
  LatePaymentFee = 'LatePaymentFee',
  ChargeRefund = 'ChargeRefund',
}

export type LedgerEntriesType = {
  amount: number;
  entryType: EntryType;
  reason: string;
  entryTime: string;
};

export type LedgerType = {
  entries: LedgerEntriesType[];
};

export type ChargeEntriesType = {
  cardPaymentSourceId: string;
  chargeId: string;
  chargedAmount: number;
  createdAt: string;
  instalmentNumber: number | null;
  isSuccess: boolean;
  updatedAt: string;
};

export type ChargesType = {
  entries: ChargeEntriesType[];
};

export type APIResponseType = {
  createdAt: string;
  customerEmail: string;
  customerId: string;
  dueAt: string;
  internalOrderId: string;
  merchantId: string;
  merchantOrderId: string;
  paidAmount: number;
  ledger: LedgerType;
  charges: ChargesType;
  payPlanId: string;
  planAmount: number;
  currency: Currency;
  state: string;
  transactionId: string;
};

export type TransactionType = {
  time: string;
  balance: number;
  description: string;
  creditAmount: number | null;
  debitAmount: number | null;
  currency: Currency;
};

export interface CustomerAPIResponse {
  customerId: string;
  email: string;
  firstName: string;
  lastName: string;
  stripeCustomerId: string;
  phoneNumber: string;
  isEmailVerified: boolean;
}
