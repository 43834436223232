import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { LpBox, Row } from 'Constants/styles';
import { MouseEvent, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { Btn, BtnSpinner, Flex, FormItem, Label, PhoneWrapper, SpinnerWrapper } from './styles';
import { PhoneAPIResponse } from './types';

const PhoneReset = () => {
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [config, setConfig] = useState<PhoneAPIResponse | null>(null);

  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setMsg('');
  };

  const getVerification = async (isReset: boolean, phoneNo: string) => {
    try {
      !isReset && setSearchLoading(true);
      setLoading(true);
      const url = `${apiBaseUri}/admin/phone/${encodeURIComponent(phoneNo)}`;
      const options = await getFetchOptions();
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const json = await res.json();
      setConfig(json);
      setSuccess(true);
      setMsg(isReset ? 'Reset phone verification successfully.' : '');
    } catch (e) {
      setSuccess(false);
      setMsg(e.message || 'Failed to search by phone');
    } finally {
      setSearchLoading(false);
      setLoading(false);
    }
  };

  const handleSearch = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    getVerification(false, phone);
  };

  const handleReset = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setResetLoading(true);

      const url = `${apiBaseUri}/admin/phone/${encodeURIComponent(phone)}/reset`;
      const options = await getFetchOptions('POST');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      await getVerification(true, config?.phoneNumber ?? '');
    } catch (e) {
      setMsg(e.message || 'Failed to reset email verification');
      setSuccess(false);
    } finally {
      setResetLoading(false);
    }
  };

  const handleChange = (value: string = ''): void => {
    const isValid = isPossiblePhoneNumber(value);
    setValid(isValid);
    setPhone(value);
  };

  return (
    <div>
      <Message success={success} description={msg} handleClose={handleCloseMsg} />
      <LpBox>
        <FormItem>
          <Label>Search by phone</Label>
          <Flex>
            <PhoneWrapper valid={valid}>
              <PhoneInput international defaultCountry="AU" value={phone} onChange={handleChange} />
            </PhoneWrapper>
            <Btn hasMarginLeft disabled={phone.length === 0 || !valid || searchLoading} onClick={handleSearch}>
              Search
              {searchLoading && (
                <BtnSpinner>
                  <Spinner width="20px" height="20px" borderWidth="2px" />
                </BtnSpinner>
              )}
            </Btn>
          </Flex>
        </FormItem>
        {loading && (
          <SpinnerWrapper>
            <Spinner color="#0016D1" />
          </SpinnerWrapper>
        )}
        {!loading && config && (
          <>
            <Row className="mt-4">
              <Label className="col-4">Phone number</Label>
              <div className="col-8">{config.phoneNumber}</div>
            </Row>

            {config.verification && (
              <>
                <Row className="mt-4">
                  <Label className="col-4">Created at</Label>
                  <div className="col-8">
                    {
                      getDateFormat({
                        time: config.verification.createdAt,
                        showComma: false,
                        showFullYear: true,
                        showDaySuffix: false,
                      }).formatted
                    }
                  </div>
                </Row>

                <Row className="mt-4">
                  <Label className="col-4">Failed attempts</Label>
                  <div className="col-8">{config.verification.failedVerifAttempts}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last verification code</Label>
                  <div className="col-8">{config.verification.lastVerifCode}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last verification code generated at</Label>
                  <div className="col-8">
                    {
                      getDateFormat({
                        time: config.verification.lastVerifCodeGenAt,
                        showComma: false,
                        showFullYear: true,
                        showDaySuffix: false,
                      }).formatted
                    }
                  </div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Last Failed verification at</Label>
                  <div className="col-8">
                    {config.verification.lastFailedVerifAt
                      ? getDateFormat({
                          time: config.verification.lastFailedVerifAt,
                          showComma: false,
                          showFullYear: true,
                          showDaySuffix: false,
                        }).formatted
                      : '-'}
                  </div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Is expired</Label>
                  <div className="col-8">{String(config.verification.isExpired)}</div>
                </Row>
                <Row className="mt-4">
                  <Label className="col-4">Is verified</Label>
                  <div className="col-8">{String(config.verification.isVerified)}</div>
                </Row>
              </>
            )}
          </>
        )}
        {config && (
          <Btn
            data-testid="phone-reset-btn"
            disabled={resetLoading || config.verification === null}
            onClick={handleReset}
            hasMarginLeft={false}
            width="200px"
            className="mt-2"
          >
            Reset phone verification
            {resetLoading && (
              <BtnSpinner>
                <Spinner width="20px" height="20px" borderWidth="2px" />
              </BtnSpinner>
            )}
          </Btn>
        )}
      </LpBox>
    </div>
  );
};

export default PhoneReset;
