import styled from '@emotion/styled';

export const SearchWrapper = styled.label`
  display: flex;
  height: 36px;
  width: 258px;
  flex: 1 0 0;
  padding: 0;
  position: relative;
  margin: 0;
  min-width: 0;
  max-width: 258px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  &.has-input {
    .search-label {
      transform: scale(0.83333) translateY(-12px);
    }
    .search-input {
      font-size: 12px;
      padding: 14px 0 2px 8px !important;
    }
  }
`;

export const SearchLabel = styled.span`
  color: #8e8e8e;
  font-size: 12px;
  height: 36px;
  left: 8px;
  line-height: 36px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 2px;
  text-overflow: ellipsis;
  transform-origin: left;
  transition: transform ease-out 0.1s;
  user-select: none;
  white-space: nowrap;
`;

export const Input = styled.input`
  background: #fafafa;
  border: 0;
  flex: 1 0 auto;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 9px 0 7px 8px;
  text-overflow: ellipsis;
  font-size: 16px;
  &:focus {
    border: 1px #505bcc solid;
    box-shadow: none;
  }
`;

export const SearchBtn = styled.button`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 120px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 0 9px;
  cursor: pointer;
  outline: none;
  margin-left: 15px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const BtnSpinner = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  padding-top: 1px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
`;

export const ItemList = styled.div`
  list-style: none;
  margin: 0;
  line-height: 22px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  &.active-list {
    border-top: 1px solid #e6eaee;
  }
  &.email {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 400px;
    display: block;
    overflow: hidden;
    font-size: 15px;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 100%;
  position: relative;
`;

export const OrderIdBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--lp-colors-medium-blue-600);
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
`;

export const DetailsLinkBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: #6c7488;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FeatureIcon = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

export const OrderId = styled.div`
  font-size: 13px;
  padding-top: 10px;
  font-weight: bold;
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 0px;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: 0;
  min-width: 0;
  &.item-col-header {
    font-size: 15px;
    color: #6c7488;
  }
  &.fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    position: absolute;
    right: 0;
    padding-right: 0;
    width: 30px;
    height: 56px;
    cursor: pointer;
    justify-content: center;
  }
`;

export const ItemActionsBlock = styled.div<{ active: boolean }>`
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 24px;
  width: 160px;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  transition: all 0.15s ease-in-out;
  z-index: 1;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

export const SpinnerWrapper = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageBar = styled.div`
  padding: 0 60px;
`;
