import styled from '@emotion/styled';

export const BackBtn = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  color: #6c7488;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: var(--lp-colors-medium-blue-800);
  }
  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  padding: 0 60px 30px;
  position: relative;
`;
